const state = {
  active: [],
  all: [],
};

const getters = {
  active: (state) => state.active,
  all: (state) => state.all,
};

const mutations = {
  setActive: (state, adSlots) => {
    state.active = adSlots;
  },

  setAll: (state, adSlots) => {
    state.all = adSlots;
  },
};

const actions = {
  create: async ({ commit, rootGetters, state }, newAdSlot) => {
    const skyos = rootGetters['app/skyOSService'];

    const createdAdSlot = await skyos.adSlots.create(newAdSlot);

    const updatedAll = [...state.all, createdAdSlot];
    const updatedActive = [...state.active, createdAdSlot];

    commit('setAll', updatedAll);
    commit('setActive', updatedActive);
  },

  delete: async ({ commit, rootGetters, state }, adSlotId) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.adSlots.delete(adSlotId);

    const all = state.all.filter((adSlot) => adSlot.id !== adSlotId);
    const active = state.active.filter((adSlot) => adSlot.id !== adSlotId);

    commit('setAll', all);
    commit('setActive', active);
  },

  edit: async ({ commit, rootGetters, state }, updatedAdSlot) => {
    const skyos = rootGetters['app/skyOSService'];

    const updated = await skyos.adSlots.update(updatedAdSlot);

    const all = state.all.filter((adSlot) => adSlot.id !== updatedAdSlot.id);
    const active = state.active.filter((adSlot) => adSlot.id !== updatedAdSlot.id);

    all.push(updated);
    active.push(updated);

    commit('setAll', all);
    commit('setActive', active);
  },

  fetchActive: async ({ commit, rootGetters, state }) => {
    const screens = rootGetters['content/screens/active'];
    const skyos = rootGetters['app/skyOSService'];

    let allAdSlots = state.all;

    if (allAdSlots.length === 0) {
      allAdSlots = await skyos.adSlots.list();
      commit('setAll', allAdSlots);
    }

    const activeScreenIds = screens.map((screen) => screen.id);
    const activeAdSlots = allAdSlots.filter((adSlot) => activeScreenIds.includes(adSlot.screen_id));

    commit('setActive', activeAdSlots);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
