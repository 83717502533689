import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class SynonymsResource extends BaseResource {
  async create(newSynonym) {
    const res = await this.http.post(`v2/venues/${newSynonym.venue_id}/synonyms`, newSynonym);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/synonyms/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id, venueId) {
    const endpoint = `v2/venues/${venueId}/synonyms/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/synonyms`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedSynonym) {
    if (!updatedSynonym || !updatedSynonym.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update synonym payload'));
    }
    const endpoint = `v2/venues/${updatedSynonym.venue_id}/synonyms/${updatedSynonym.id}`;
    const res = await this.http.put(endpoint, updatedSynonym);

    return Promise.resolve(res.data.data);
  }
}
