export default {
  setApiKeys: (state, selfies) => {
    state.apiKeys = selfies;
  },
  setActiveOrganization: (state, organization) => {
    state.activeOrganization = organization;
  },
  setOrganizations: (state, organizations) => {
    state.organizations = organizations;
  },
  setMembers: (state, members) => {
    state.members = members;
  },
};
