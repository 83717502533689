import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class PromotionsResource extends BaseResource {
  async create(newPromotion) {
    const res = await this.http.post(`v1/venues/${newPromotion.venue_id}/coupons`, newPromotion);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v1/venues/${venueId}/coupons/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id, venueId) {
    const endpoint = `v1/venues/${venueId}/coupons/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v1/venues/${venueId}/coupons`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedPromotion) {
    if (!updatedPromotion || !updatedPromotion.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update promotion payload'));
    }
    const endpoint = `v1/venues/${updatedPromotion.venue_id}/coupons/${updatedPromotion.id}`;
    const res = await this.http.put(endpoint, updatedPromotion);

    return Promise.resolve(res.data.data);
  }
}
