import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class AlertsResource extends BaseResource {
  async create(newAlert) {
    const res = await this.http.post('v1/alerts', newAlert);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/alerts/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/alerts/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/alerts');

    return Promise.resolve(res.data.data);
  }

  async update(updatedAlert) {
    if (!updatedAlert || !updatedAlert.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update alert payload'));
    }
    const endpoint = `v1/alerts/${updatedAlert.id}`;
    const res = await this.http.put(endpoint, updatedAlert);

    return Promise.resolve(res.data.data);
  }
}
