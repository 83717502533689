import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class TemplatesResource extends BaseResource {
  async create(newTemplate) {
    const res = await this.http.post('v1/templates', newTemplate);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/templates/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/templates/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/templates');

    return Promise.resolve(res.data.data);
  }

  async update(updatedTemplate) {
    if (!updatedTemplate || !updatedTemplate.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update template payload'));
    }
    const endpoint = `v1/templates/${updatedTemplate.id}`;
    const res = await this.http.put(endpoint, updatedTemplate);

    return Promise.resolve(res.data.data);
  }
}
