import BaseResource from './base';

export default class MembersResource extends BaseResource {
  async create(newMember) {
    const res = await this.http.post(`v1/organizations/${newMember.organization_id}/members`, newMember);

    return Promise.resolve(res.data.data);
  }

  async delete(organizationId, memberId) {
    const endpoint = `v1/organizations/${organizationId}/members/${memberId}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(organizationId, memberId) {
    const endpoint = `v1/organizations/${organizationId}/members/${memberId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(organizationId) {
    const res = await this.http.get(`v1/organizations/${organizationId}/members`);

    return Promise.resolve(res.data.data);
  }

  async update(organizationId, member) {
    const res = await this.http.put(`v1/organizations/${organizationId}/members/${member.id}`, member);

    return Promise.resolve(res.data.data);
  }
}
