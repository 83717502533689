import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class AmenitiesResource extends BaseResource {
  async create(newAmenity) {
    const res = await this.http.post('v1/amenities', newAmenity);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/amenities/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/amenities/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(params = {}) {
    const res = await this.http.get('v1/amenities', { params });

    return Promise.resolve(res.data.data);
  }

  async update(updatedAmenity) {
    if (!updatedAmenity || !updatedAmenity.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update amenity payload'));
    }
    const endpoint = `v1/amenities/${updatedAmenity.id}`;
    const res = await this.http.put(endpoint, updatedAmenity);

    return Promise.resolve(res.data.data);
  }

  async upload(formData) {
    return this.http.post('v1/uploads', formData);
  }
}
