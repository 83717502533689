import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class ObjectsResource extends BaseResource {
  async create(newObject) {
    const res = await this.http.post(`v1/venues/${newObject.venue_id}/customObjects`, newObject);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v1/venues/${venueId}/customObjects/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id, venueId) {
    const endpoint = `v1/venues/${venueId}/customObjects/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v1/venues/${venueId}/customObjects`);
    return Promise.resolve(res.data.data);
  }

  async update(updateObject) {
    if (!updateObject || !updateObject.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update object payload'));
    }
    const endpoint = `v1/venues/${updateObject.venue_id}/customObjects/${updateObject.id}`;
    const res = await this.http.put(endpoint, updateObject);

    return Promise.resolve(res.data.data);
  }
}
