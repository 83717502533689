import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class LightsResource extends BaseResource {
  async create(newLight, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/lights`, newLight);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/lights/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(floorId, venueId) {
    const endpoint = `v2/venues/${venueId}/lights/${floorId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/lights`);

    return Promise.resolve(res.data.data);
  }

  async update(updatedLight, venueId) {
    if (!updatedLight || !updatedLight.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update light payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/lights/${updatedLight.id}`;
    const res = await this.http.put(endpoint, updatedLight);

    return Promise.resolve(res.data.data);
  }
}
