import { v4 as uuidv4 } from 'uuid';
import SkyOSService from '../../services/skyos';
import AuthToken from '../../services/skyos/token';

const signinBypass = async({ commit, dispatch, rootGetters }, payload) => {
  const skyos = new SkyOSService();
  let clientId = window.localStorage.getItem('clientId');

  if (!clientId) {
    clientId = uuidv4();
    window.localStorage.setItem('clientId', clientId);
  }

  commit('setClientId', clientId);

  const res = await skyos.auth.signinBypass({...payload});
  if (res.token && res.token.length > 0) {
    commit('setIsAuthenticated', true);
    const token = new AuthToken(res.token);

    commit('setToken', token);
    await dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
    await dispatch('getUserFromToken');
    await dispatch('content/fetchAllUserContent', undefined, { root: true });
    let initialVenueId = localStorage.getItem('activeVenueId');

    if (!initialVenueId) {
      // eslint-disable-next-line prefer-destructuring
      const firstVenue = rootGetters['content/venues/all'][0];

      if (firstVenue && firstVenue.id) {
        initialVenueId = firstVenue.id;
      }
    }

    await dispatch('content/venues/updateActive', initialVenueId, { root: true });
    await dispatch('content/updateActiveContent', undefined, { root: true });
    await dispatch('content/updateApplicationIsLoading', false, { root: true });
  }
};

const signin = async ({ commit }, payload) => {
  const skyos = new SkyOSService();

  let clientId = window.localStorage.getItem('clientId');

  if (!clientId) {
    clientId = uuidv4();
    window.localStorage.setItem('clientId', clientId);
  }

  commit('setClientId', clientId);

  const response = await skyos.auth.signin({...payload, client_id: clientId});
  if (response.success) {
    commit('setRememberDays', response.remember_days);
  }

  return Promise.resolve(response);
};

const getUserFromToken = async ({ commit, state }) => {
  if (state.token) {
    const skyos = new SkyOSService(state.token);
    const user = await skyos.users.getById(state.token.getUserId());
    commit('setUser', user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  return Promise.resolve();
};

const fetchAllUserPermissions = async ({ commit, rootGetters, state }) => {
  const skyos = rootGetters['app/skyOSService'];
  const userPermissions = await skyos.permissions.fetchByUserId(state.user?.id);
  commit('setUserPermissions', userPermissions);
};

const fetchDataForReturningUser = async ({ commit, dispatch, rootGetters }) => {
  try {
    await dispatch('content/updateApplicationIsLoading', true, { root: true });
    const jwtToken = localStorage.getItem('token');
    const token = new AuthToken(jwtToken);

    if (token.isExpired() || !token.getUserId()) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      return Promise.reject(new Error('Invalid token'));
    }

    commit('setIsAuthenticated', true);
    commit('setToken', token);
    await dispatch('getUserFromToken');
    await dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
    await dispatch('fetchAllUserPermissions');
    await dispatch('content/fetchAllUserContent', {}, { root: true });
    let initialVenueId = localStorage.getItem('activeVenueId');

    if (!initialVenueId) {
      // eslint-disable-next-line prefer-destructuring
      const firstVenue = rootGetters['content/venues/all'][0];

      if (firstVenue && firstVenue.id) {
        initialVenueId = firstVenue.id;
      }
    }

    await dispatch('content/venues/updateActive', initialVenueId, { root: true });
    await dispatch('content/updateActiveContent', {}, { root: true });
    await dispatch('content/updateApplicationIsLoading', false, { root: true });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

const logout = ({ commit }) => {
  commit('setIsAuthenticated', false);
  commit('setMimicEnabled', false);
  commit('setMimicToken', undefined);
  commit('setMimicUser', undefined);

  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('lastUrl');
};

const mimicUser = async ({
  commit, dispatch, rootGetters, state,
}, payload) => {
  const originalToken = localStorage.getItem('token');
  try {
    await dispatch('content/updateApplicationIsLoading', true, { root: true });
    const skyos = rootGetters['app/skyOSService'];
    const res = await skyos.auth.mimic(payload);
    if (res.data.data.token) {
      const token = new AuthToken(res.data.data.token);
      // store old data in mimic area so we can pull it back
      commit('setMimicEnabled', true);
      commit('setMimicToken', state.token);
      commit('setMimicUser', state.user);

      // use token for mimic user and update all data
      commit('setToken', token);
      localStorage.setItem('token', token.jwt());
      dispatch('getUserFromToken');
      dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
      await dispatch('content/fetchAllUserContent', {}, { root: true });

      // eslint-disable-next-line prefer-destructuring
      const venues = rootGetters['content/venues/all'];
      if (venues.length === 0) {
        throw new Error('User does not have access to any venues. Cannot mimic.');
      }
      const initialVenueId = (venues && venues[0] && venues[0].id) ? venues[0].id : 0;

      await dispatch('content/venues/updateActive', initialVenueId, { root: true });
      await dispatch('content/updateActiveContent', {}, { root: true });
      await dispatch('content/updateApplicationIsLoading', false, { root: true });
    } else {
      throw new Error('Response missing token.');
    }

    return Promise.resolve(true);
  } catch (e) {
    localStorage.setItem('token', originalToken);
    const token = new AuthToken(originalToken);
    commit('setToken', token);
    commit('setMimicEnabled', false);
    commit('setMimicToken', undefined);
    commit('setMimicUser', undefined);
    dispatch('getUserFromToken');
    dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
    await dispatch('content/fetchAllUserContent', {}, { root: true });

    await dispatch('content/updateApplicationIsLoading', false, { root: true });

    await dispatch('app/showError', { message: 'Unable to mimic user: ', error: e }, { root: true });
    return Promise.resolve(false);
  }
};

const endMimic = async ({
  commit, dispatch, state, rootGetters,
}) => {
  await dispatch('content/updateApplicationIsLoading', true, { root: true });

  const token = state.mimicToken;

  commit('setToken', token);
  localStorage.setItem('token', token.jwt());
  await dispatch('getUserFromToken');
  await dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
  await dispatch('content/fetchAllUserContent', {}, { root: true });
  let initialVenueId = localStorage.getItem('activeVenueId');

  if (!initialVenueId) {
    // eslint-disable-next-line prefer-destructuring
    const firstVenue = rootGetters['content/venues'][0];

    if (firstVenue && firstVenue.id) {
      initialVenueId = firstVenue.id;
    }
  }

  await dispatch('content/venues/updateActive', initialVenueId, { root: true });
  await dispatch('content/updateActiveContent', {}, { root: true });

  commit('setMimicEnabled', false);
  commit('setMimicToken', undefined);
  commit('setMimicUser', undefined);

  await dispatch('content/updateApplicationIsLoading', false, { root: true });

  return Promise.resolve();
};

const register = async ({ commit, dispatch, rootGetters }, payload) => {
  const skyos = rootGetters['app/skyOSService'];

  try {
    const resToken = await skyos.auth.register(payload);

    if (resToken && resToken.length > 0) {
      commit('setIsAuthenticated', true);
      const token = new AuthToken(resToken);

      commit('setToken', token);
      localStorage.setItem('token', token.jwt());
      await dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
      await dispatch('getUserFromToken');
      await dispatch('fetchAllUserPermissions');
      await dispatch('content/fetchAllUserContent', undefined, { root: true });
      let initialVenueId = localStorage.getItem('activeVenueId');

      if (!initialVenueId) {
        // eslint-disable-next-line prefer-destructuring
        const firstVenue = rootGetters['content/venues/all'][0];

        if (firstVenue && firstVenue.id) {
          initialVenueId = firstVenue.id;
        }
      }

      await dispatch('content/venues/updateActive', initialVenueId, { root: true });
      await dispatch('content/updateActiveContent', undefined, { root: true });
      await dispatch('content/updateApplicationIsLoading', false, { root: true });
    }

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateMfaEmail = ({ commit }, email) => {
  commit('setMfaEmail', email);
};

const submitMfaCode = async ({ commit, dispatch, rootGetters }, payload) => {
  await dispatch('content/updateApplicationIsLoading', true, { root: true });
  const skyos = new SkyOSService(undefined);

  const res = await skyos.auth.verify(payload);

  if (res.token && res.token.length > 0) {
    commit('setIsAuthenticated', true);
    const token = new AuthToken(res.token);

    commit('setToken', token);
    await dispatch('app/updateSkyOSService', new SkyOSService(token), { root: true });
    await dispatch('getUserFromToken');
    await dispatch('content/fetchAllUserContent', undefined, { root: true });
    let initialVenueId = localStorage.getItem('activeVenueId');

    if (!initialVenueId) {
      // eslint-disable-next-line prefer-destructuring
      const firstVenue = rootGetters['content/venues/all'][0];

      if (firstVenue && firstVenue.id) {
        initialVenueId = firstVenue.id;
      }
    }

    await dispatch('content/venues/updateActive', initialVenueId, { root: true });
    await dispatch('content/updateActiveContent', undefined, { root: true });
    await dispatch('content/updateApplicationIsLoading', false, { root: true });
  }

  return Promise.resolve();
};

export default {
  signin,
  signinBypass,
  getUserFromToken,
  fetchDataForReturningUser,
  logout,
  mimicUser,
  endMimic,
  register,
  updateMfaEmail,
  submitMfaCode,
  fetchAllUserPermissions,
};
