import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class FeedsResource extends BaseResource {
  async create(newFeed) {
    const res = await this.http.post(`v1/venues/${newFeed.venue_id}/feeds`, newFeed);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v1/venues/${venueId}/feeds/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/feeds/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async getFeedDataSample(feed) {
    const endpoint = `v1/venues/${feed.venue_id}/feeds/dryrun/auth`;
    const res = await this.http.post(endpoint, feed);
    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v1/venues/${venueId}/feeds`);

    return Promise.resolve(res.data.data);
  }

  async update(updatedFeed) {
    if (!updatedFeed || !updatedFeed.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update alert payload'));
    }
    const endpoint = `v1/venues/${updatedFeed.venue_id}/feeds/${updatedFeed.id}`;
    const res = await this.http.put(endpoint, updatedFeed);

    return Promise.resolve(res.data.data);
  }
}
