export default {
  common: {
    add: 'Agregar',
    cancel: 'Cancelar',
    description: 'Descripción',
    delete: 'Eliminar',
    title: 'Título',
    save: 'Guardar',
    faq: 'Preguntas frecuentes',
    contact: 'Contáctenos',
    tos: 'Condiciones de servicio',
    policy: 'Política de privacidad',
  },
  board: {
    titlePlaceholder: 'Ingresa un título para esta tarjeta',
    deleteDescription: '¿Estás seguro de que deseas eliminar esta tarjeta?',
    editCard: 'Editar tarjeta',
    deleteCard: 'Eliminar tarjeta',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'PRUEBAS',
      DONE: 'HECHO',
    },
  },
  chat: {
    online: 'Usuarios en línea ({count})',
    addChannel: 'Agregar canal',
    channel: 'Canal | Canales',
    message: 'Mensaje',
  },
  email: {
    compose: 'Redactar correo electrónico',
    send: 'Enviar',
    subject: 'Asunto',
    labels: 'Etiquetas',
    emptyList: 'Lista de correo electrónico vacía',
    inbox: 'Bandeja de entrada',
    sent: 'Enviado',
    drafts: 'Borradores',
    starred: 'Destacados',
    trash: 'Basura',
    work: 'Trabajo',
    invoice: 'Factura',
  },
  todo: {
    addTask: 'Agregar tarea',
    tasks: 'Tareas',
    completed: 'Completado',
    labels: 'Etiquetas',
  },
  dashboard: {
    activity: 'Actividad',
    weeklySales: 'Ventas semanales',
    sales: 'Ventas',
    recentOrders: 'Pedidos recientes',
    sources: 'Fuentes de tráfico',
    lastweek: 'frente a la semana pasada',
    orders: 'Órdenes',
    customers: 'Clientes',
    tickets: 'Tickets de soporte',
    viewReport: 'Ver informe',
  },
  usermenu: {
    profile: 'Perfil',
    signin: 'Iniciar sesión',
    dashboard: 'Panel de control',
    signout: 'Cerrar sesión',
  },
  error: {
    notfound: 'Página no encontrada',
    other: 'Ocurrió un error',
  },
  check: {
    title: 'Establecer nueva contraseña',
    backtosign: 'Volver a iniciar sesión',
    newpassword: 'Nueva contraseña',
    button: 'Establezca una nueva contraseña e inicie sesión',
    error: 'El enlace de acción no es válido',
    verifylink: 'Verificando enlace ...',
    verifyemail: 'Verificando la dirección de correo electrónico ...',
    emailverified: '¡Correo verificado! Redirigiendo ...',
  },
  forgot: {
    title: '¿Se te olvidó tu contraseña?',
    subtitle: 'Ingrese la dirección de correo electrónico de su cuenta y le enviaremos un enlace para restablecer su contraseña.',
    email: 'Correo electrónico',
    button: 'Solicitar restablecimiento de contraseña',
    backtosign: 'Volver a iniciar sesión',
  },
  login: {
    title: 'Iniciar sesión',
    email: 'Correo electrónico',
    password: 'Contraseña',
    button: 'Iniciar sesión',
    orsign: 'O inicia sesión con',
    forgot: '¿Se te olvidó tu contraseña?',
    noaccount: '¿No tienes una cuenta?',
    create: 'Crea uno aquí',
    error: 'La combinación de correo electrónico / contraseña no es válida',
  },
  register: {
    title: 'Crear cuenta',
    name: 'Nombre completo',
    email: 'Correo electrónico',
    password: 'Contraseña',
    button: 'Crear cuenta',
    orsign: 'O regístrate con',
    agree: 'Al registrarse, acepta las',
    account: '¿Ya tienes una cuenta?',
    signin: 'Iniciar sesión',
  },
  utility: {
    maintenance: 'En mantenimiento',
  },
  faq: {
    call: '¿Tiene otras preguntas? Comuníquese con',
  },
  menu: {
    search: 'Buscar (presione \'ctrl + /\' para enfocar)',
    dashboard: 'Panel de control',
    logout: 'Cerrar sesión',
    profile: 'Perfil',
    blank: 'Página en blanco',
    pages: 'Páginas',
    others: 'Otros',
    email: 'Correo electrónico',
    chat: 'Chat',
    todo: 'Todo',
    board: 'Tablero de tareas',
    users: 'Usuarios',
    usersList: 'Lista',
    usersEdit: 'Editar',
    auth: 'Páginas de autenticación',
    authLogin: 'Iniciar sesión / Iniciar sesión',
    authRegister: 'Registrarse / Registrarse',
    authVerify: 'Verificar correo electrónico',
    authForgot: 'Olvidé mi contraseña',
    authReset: 'Restablecer contraseña',
    errorPages: 'Páginas de error',
    errorNotFound: 'No encontrado / 404',
    errorUnexpected: 'Inesperado / 500',
    utilityPages: 'Páginas de utilidades',
    utilityMaintenance: 'Mantenimiento',
    utilitySoon: 'Próximamente',
    utilityHelp: 'Preguntas frecuentes / Ayuda',
    levels: 'Niveles de menú',
    disabled: 'Menú deshabilitado',
    docs: 'Documentos',
    feedback: 'Comentarios',
    support: 'Soporte',
  },
  $vuetify: {
    badge: 'Insignia',
    close: 'Cerrar',
    dataIterator: {
      noResultsText: 'No se encontraron registros coincidentes',
      loadingText: 'Cargando elementos ...',
    },
    dataTable: {
      itemsPerPageText: 'Filas por página:',
      ariaLabel: {
        sortDescending: 'Ordenados descendente.',
        sortAscending: 'Ordenado ascendente.',
        sortNone: 'Sin clasificar.',
        activateNone: 'Activar para eliminar la clasificación.',
        activateDescending: 'Activar para ordenar de forma descendente.',
        activateAscending: 'Activar para ordenar de forma ascendente.',
      },
      sortBy: 'Ordenar por',
    },
    dataFooter: {
      itemsPerPageText: 'Elementos por página:',
      itemsPerPageAll: 'Todo',
      nextPage: 'Página siguiente',
      prevPage: 'Página anterior',
      firstPage: 'Primera página',
      lastPage: 'Última página',
      pageText: '{0} - {1} de {2}',
    },
    datePicker: {
      itemsSelected: '{0} seleccionado',
      nextMonthAriaLabel: 'El mes que viene',
      nextYearAriaLabel: 'El año que viene',
      prevMonthAriaLabel: 'Mes anterior',
      prevYearAriaLabel: 'Año anterior',
    },
    noDataText: 'No hay datos disponibles',
    carousel: {
      prev: 'Visual anterior',
      next: 'Siguiente visual',
      ariaLabel: {
        delimiter: 'Diapositiva de carrusel {0} de {1}',
      },
    },
    calendar: {
      moreEvents: '{0} más',
    },
    fileInput: {
      counter: '{0} archivos',
      counterSize: '{0} archivos ({1} en total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Navegación de paginación',
        next: 'Página siguiente',
        previous: 'Página anterior',
        page: 'Ir a la página {0}',
        currentPage: 'Página actual, página {0}',
      },
    },
  },
};
