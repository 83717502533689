import _ from 'lodash';
import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class KiosksResource extends BaseResource {
  async create(newKiosk) {
    const res = await this.http.post('v1/kiosks', newKiosk);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/kiosks/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/kiosks/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async invalidRoutes(id) {
    const endpoint = `v1/kiosks/${id}/routes`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/kiosks');

    return Promise.resolve(res.data.data);
  }

  async update(updatedKiosk) {
    if (!updatedKiosk || !updatedKiosk.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update kiosk payload'));
    }

    const newKiosk = _.cloneDeep(updatedKiosk);
    if (newKiosk.screens) {
      delete newKiosk.screens;
    }
    if (newKiosk.venue) {
      delete newKiosk.venue;
    }

    const endpoint = `v1/kiosks/${newKiosk.id}`;
    const res = await this.http.put(endpoint, newKiosk);

    return Promise.resolve(res.data.data);
  }

  async categories(data) {
    if (!data.kiosk || !data.kiosk.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update kiosk categories'));
    }
    const endpoint = `v2/kiosks/${data.kiosk.id}/categories`;
    const res = await this.http.post(endpoint, {
      category_ids: data.selectedCategoryIds,
    });

    return Promise.resolve(res.data.data);
  }
}
