import BaseResource from './base';

export default class SelfiesResource extends BaseResource {
  async list() {
    try {
      const res = await this.http.get('v1/overlays');

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async create(payload) {
    try {
      const res = await this.http.post('v1/overlays', payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async update(payload) {
    try {
      const res = await this.http.put(`v1/overlays/${payload.id}`, payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async delete(payload) {
    try {
      const res = await this.http.delete(`v1/overlays/${payload}`);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchEffects(venueId) {
    try {
      const res = await this.http.get('v1/effects', {
        params: {
          venue_id: venueId,
        },
      });

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createEffect(payload) {
    try {
      const res = await this.http.post('v1/effects', payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateEffect(updatedEffect) {
    try {
      const endpoint = `v1/effects/${updatedEffect.id}`;
      const res = await this.http.put(endpoint, updatedEffect);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async deleteEffect(effectId) {
    try {
      const endpoint = `v1/effects/${effectId}`;
      const res = await this.http.delete(endpoint);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
