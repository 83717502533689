import BaseResource from './base';

export default class VenuesResource extends BaseResource {
  async create(newVenue) {
    const res = await this.http.post('v1/venues', newVenue);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/venues/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/venues/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/venues');

    return Promise.resolve(res.data.data);
  }

  async update(venue) {
    const res = await this.http.put(`v1/venues/${venue.id}`, venue);

    return Promise.resolve(res.data.data);
  }

  async getLanguages(id) {
    const endpoint = `v1/venues/${id}/languages`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async addLanguage(venueId, languageId) {
    const endpoint = `v1/venues/${venueId}/languages/${languageId}`;
    const res = await this.http.post(endpoint);

    return Promise.resolve(res.data.data);
  }

  async deleteLanguage(venueId, venueLanguageId) {
    const endpoint = `v1/venues/${venueId}/languages/${venueLanguageId}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async invalidRoutes(id) {
    const endpoint = `v1/venues/${id}/routes`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async migrateMap(id) {
    const endpoint = `v2/venues/${id}/map-migrate`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }
}
