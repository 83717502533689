const state = {
  servers: [],
};

const getters = {
  servers: (state) => state.servers,
};

const mutations = {
  setServers: (state, servers) => {
    state.servers = servers;
  },
};

const actions = {
  create: async ({ commit, rootGetters, state }, newServer) => {
    const skyos = rootGetters['app/skyOSService'];

    const createdServer = await skyos.proxy_servers.create(newServer);

    const updatedAll = [...state.servers, createdServer];

    commit('setServers', updatedAll);
  },

  delete: async ({ commit, rootGetters, state }, server) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.proxy_servers.delete(server.id, server.venue_id);

    const all = state.servers.filter((s) => s.id !== server.id);

    commit('setServers', all);
  },

  edit: async ({ commit, rootGetters, state }, updatedServer) => {
    const skyos = rootGetters['app/skyOSService'];

    const updated = await skyos.proxy_servers.update(updatedServer);

    const all = state.all.filter((server) => server.id !== updatedServer.id);

    all.push(updated);

    commit('setServers', all);
  },

  fetch: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const allServers = await skyos.proxy_servers.list(venueId);
    commit('setServers', allServers);
  },

  fetchCommands: async ({ rootGetters }, serverId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const commands = await skyos.proxy_servers.list_commands(serverId, venueId);
    return Promise.resolve(commands);
  },

  saveCommand: async ({ rootGetters }, command) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const response = await skyos.proxy_servers.create_command(command, venueId);
    return Promise.resolve(response);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
