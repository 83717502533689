const state = {};

const getters = {};

const mutations = {};

const actions = {
  post: async ({ rootGetters }, formData) => {
    const skyos = rootGetters['app/skyOSService'];
    let res;

    if (formData.get('upload_type') === 'amenity') {
      res = await skyos.amenities.upload(formData);
    }
    if (formData.get('upload_type') === 'events') {
      res = await skyos.events.upload(formData);
    }
    if (formData.get('upload_type') === 'locations') {
      res = await skyos.locations.upload(formData);
    }

    return Promise.resolve(res);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
