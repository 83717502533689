/* eslint-disable max-classes-per-file,import/prefer-default-export */
class ClientError extends Error {
  constructor(message) {
    super(message);
    this.category = 'ClientError';
    this.userFriendlyMessage = 'Something is wrong with your request.';
  }
}

class ServerError extends Error {
  constructor(message) {
    super(message);
    this.category = 'ServerError';
    this.userFriendlyMessage = 'Oops! Something went wrong on our side.';
  }
}

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
    this.userFriendlyMessage = 'Something is invalid in the form.';
  }
}

export class BadRequestError extends ClientError {
  constructor(message) {
    super(message);
    this.statusCode = 400;
    this.name = 'BadRequestError';
  }
}

export class UnauthorizedError extends ClientError {
  constructor(message) {
    super(message);
    this.statusCode = 401;
    this.name = 'UnauthorizedError';
  }
}

export class ForbiddenError extends ClientError {
  constructor(message) {
    super(message);
    this.statusCode = 403;
    this.name = 'ForbiddenError';
  }
}

export class NotFoundError extends ClientError {
  constructor(message) {
    super(message);
    this.statusCode = 404;
    this.name = 'NotFoundError';
  }
}

export class InternalServerError extends ServerError {
  constructor(message) {
    super(message);
    this.statusCode = 500;
    this.name = 'InternalServerError';
  }
}

export class BadGatewayError extends ServerError {
  constructor(message) {
    super(message);
    this.statusCode = 502;
    this.name = 'BadGatewayError';
  }
}

export class ServiceUnavailableError extends ServerError {
  constructor(message) {
    super(message);
    this.statusCode = 503;
    this.name = 'ServiceUnavailableError';
  }
}

export const parseErrorFromResponse = (res) => {
  if (!res) {
    throw new Error('no response given');
  }
  if (!res.status) {
    throw new Error('no status response given');
  }
  if (res.status < 400) {
    throw new Error('unable to parse error from OK response');
  }
  let message = 'Something went wrong.';
  if (res.data.errors && res.data.errors.length > 0) {
    message = res.data.errors[0];
  }

  switch (res.status) {
    case 400: {
      return new BadGatewayError(message);
    }
    case 401: {
      return new UnauthorizedError(message);
    }
    case 403: {
      return new ForbiddenError(message);
    }
    case 404: {
      return new NotFoundError(message);
    }
    case 500: {
      return new InternalServerError(message);
    }
    case 502: {
      return new BadGatewayError(message);
    }
    case 503: {
      return new ServiceUnavailableError(message);
    }
    default: {
      return new Error(message);
    }
  }
};
