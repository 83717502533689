export default {
  common: {
    add: 'أضف',
    cancel: 'إلغاء',
    description: 'الوصف',
    delete: 'حذف',
    title: 'العنوان',
    save: 'حفظ',
    faq: 'التعليمات',
    contact: 'اتصل بنا',
    tos: 'شروط الخدمة',
    policy: 'نهج الخصوصية',
  },
  board: {
    titlePlaceholder: 'أدخل عنواناً لهذه البطاقة',
    deleteDescription: 'هل أنت متأكد أنك تريد حذف هذه البطاقة؟',
    editCard: 'تحرير البطاقة',
    deleteCard: 'حذف البطاقة',
    state: {
      TODO: 'تودو',
      INPROGRESS: 'التقدم',
      TESTING: 'اختبار',
      DONE: 'تم',
    },
  },
  chat: {
    online: 'المستخدمون المتصلون ({عدد})',
    addChannel: 'أضف قناة',
    channel: 'القناة | القنوات',
    message: 'الرسالة',
  },
  email: {
    compose: 'إنشاء بريد إلكتروني',
    send: 'إرسال',
    subject: 'الموضوع',
    labels: 'ملصقات',
    emptyList: 'قائمة البريد الإلكتروني الفارغة',
    inbox: 'علبة الوارد',
    sent: 'مرسل',
    drafts: 'المسودات',
    starred: 'مميّز بنجمة',
    trash: 'سلة المهملات',
    work: 'العمل',
    invoice: 'فاتورة',
  },
  todo: {
    addTask: 'إضافة مهمة',
    tasks: 'المهام',
    completed: 'اكتمل',
    labels: 'ملصقات',
  },
  dashboard: {
    activity: 'نشاط',
    weeklySales: 'المبيعات الأسبوعية',
    sales: 'المبيعات',
    recentOrders: 'الطلبات الأخيرة',
    sources: 'مصادر الحركة',
    lastweek: 'مقابل الأسبوع الماضي',
    orders: 'الطلبات',
    customers: 'العملاء',
    tickets: 'تذاكر الدعم',
    viewReport: 'مشاهدة التقرير',
  },
  usermenu: {
    profile: 'الملف الشخصي',
    signin: 'تسجيل الدخول',
    dashboard: 'لوحة القيادة',
    signout: 'الخروج',
  },
  error: {
    notfound: 'الصفحة غير موجودة',
    other: 'حدث خطأ',
  },
  check: {
    title: 'تعيين كلمة مرور جديدة',
    backtosign: 'العودة الى تسجيل الدخول',
    newpassword: 'كلمة مرور جديدة',
    button: 'تعيين كلمة مرور جديدة وتسجيل الدخول',
    error: 'ارتباط الإجراء غير صالح',
    verifylink: 'رابط التحقق ...',
    verifyemail: 'التحقق من عنوان البريد الإلكتروني ...',
    emailverified: 'تم التحقق من البريد الإلكتروني! إعادة التوجيه ...',
  },
  forgot: {
    title: 'هل نسيت كلمة المرور؟',
    subtitle: 'أدخل عنوان البريد الإلكتروني لحسابك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.',
    email: 'البريد الإلكتروني',
    button: 'طلب إعادة تعيين كلمة المرور',
    backtosign: 'العودة الى تسجيل الدخول',
  },
  login: {
    title: 'تسجيل الدخول',
    email: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    button: 'تسجيل الدخول',
    orsign: 'أو سجل الدخول باستخدام',
    forgot: 'هل نسيت كلمة المرور؟',
    noaccount: 'ليس لديك حساب؟',
    create: 'أنشئ واحدة هنا',
    error: 'تركيبة البريد الإلكتروني / كلمة المرور غير صالحة',
  },
  register: {
    title: 'انشاء حساب',
    name: 'الاسم الكامل',
    email: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    button: 'انشاء حساب',
    orsign: 'أو اشترك مع',
    agree: 'بالتسجيل ، فإنك توافق على',
    account: 'هل لديك حساب؟',
    signin: 'تسجيل الدخول',
  },
  utility: {
    maintenance: 'في الصيانة',
  },
  faq: {
    call: 'هل لديك أسئلة أخرى؟ الرجاء التواصل',
  },
  menu: {
    search: 'بحث (اضغط على &quot;ctrl + /&quot; للتركيز)',
    dashboard: 'لوحة القيادة',
    logout: 'خروج',
    profile: 'الملف الشخصي',
    blank: 'صفحة فارغة',
    pages: 'الصفحات',
    others: 'أخرى',
    email: 'البريد الإلكتروني',
    chat: 'الدردشة',
    todo: 'تودو',
    board: 'لوحة المهام',
    users: 'المستخدمون',
    usersList: 'قائمة',
    usersEdit: 'تحرير',
    auth: 'صفحات المؤلف',
    authLogin: 'تسجيل الدخول / تسجيل الدخول',
    authRegister: 'التسجيل / التسجيل',
    authVerify: 'تحقق من البريد الإلكتروني',
    authForgot: 'نسيت كلمة المرور',
    authReset: 'إعادة تعيين كلمة المرور',
    errorPages: 'صفحات الخطأ',
    errorNotFound: 'غير موجود / 404',
    errorUnexpected: 'غير متوقع / 500',
    utilityPages: 'صفحات المرافق',
    utilityMaintenance: 'صيانة',
    utilitySoon: 'قريباً',
    utilityHelp: 'أسئلة وأجوبة / مساعدة',
    levels: 'مستويات القائمة',
    disabled: 'القائمة معطلة',
    docs: 'المستندات',
    feedback: 'ردود الفعل',
    support: 'دعم',
  },
  $vuetify: {
    badge: 'شارة',
    close: 'اغلاق',
    dataIterator: {
      noResultsText: 'لم يتم العثور على سجلات مطابقة',
      loadingText: 'تحميل العناصر ...',
    },
    dataTable: {
      itemsPerPageText: 'عدد الصفوف في الصفحة:',
      ariaLabel: {
        sortDescending: 'تم الترتيب تنازليًا.',
        sortAscending: 'تم الفرز تصاعديًا.',
        sortNone: 'لم يتم الفرز.',
        activateNone: 'تنشيط لإزالة الفرز.',
        activateDescending: 'التفعيل للترتيب تنازليًا.',
        activateAscending: 'قم بالتفعيل للترتيب تصاعديًا.',
      },
      sortBy: 'الترتيب حسب',
    },
    dataFooter: {
      itemsPerPageText: 'عدد العناصر في الصفحة:',
      itemsPerPageAll: 'الكل',
      nextPage: 'الصفحة التالية',
      prevPage: 'الصفحة السابقة',
      firstPage: 'الصفحة الأولى',
      lastPage: 'الصفحة الأخيرة',
      pageText: '{0} - {1} من {2}',
    },
    datePicker: {
      itemsSelected: '{0} محدد',
      nextMonthAriaLabel: 'الشهر القادم',
      nextYearAriaLabel: 'العام القادم',
      prevMonthAriaLabel: 'الشهر السابق',
      prevYearAriaLabel: 'العام السابق',
    },
    noDataText: 'لا توجد بيانات متوفرة',
    carousel: {
      prev: 'الصورة السابقة',
      next: 'الصورة التالية',
      ariaLabel: {
        delimiter: 'شريحة الرف الدائري {0} من {1}',
      },
    },
    calendar: {
      moreEvents: '{0} المزيد',
    },
    fileInput: {
      counter: '{0} ملفات',
      counterSize: '{0} ملف (إجمالي {1})',
    },
    timePicker: {
      am: 'صباحا',
      pm: 'م',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'التنقل بين الصفحات',
        next: 'الصفحة التالية',
        previous: 'الصفحة السابقة',
        page: 'الانتقال إلى الصفحة {0}',
        currentPage: 'الصفحة الحالية ، الصفحة {0}',
      },
    },
  },
};
