import Vue from 'vue';
import Vuex from 'vuex';

// Global vuex
import AdminModule from './admin';
import AdsModule from './ads';
import AppModule from './app';
import AuthModule from './auth';
import ContentModule from './content';
import DashboardModule from './dashboard';
import SettingsModule from './settings';
import ReportsResource from './reports';

Vue.use(Vuex);

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    admin: AdminModule,
    ads: AdsModule,
    app: AppModule,
    auth: AuthModule,
    content: ContentModule,
    dashboard: DashboardModule,
    settings: SettingsModule,
    reports: ReportsResource,
  },
});

export default store;
