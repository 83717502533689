import BaseResource from './base';

export default class DsThemesResource extends BaseResource {
  async list(venueId) {
    const endpoint = 'v1/ds/themes';

    const res = await this.http.get(endpoint, {
      query: {
        venue_id: venueId,
      },
    });

    return Promise.resolve(res.data.data);
  }
}
