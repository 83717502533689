import BaseResource from './base';

export default class SearchResource extends BaseResource {
  async post(searchString) {
    const res = await this.http.post('v1/search', {
      query: searchString,
    });

    return Promise.resolve(res.data.data);
  }
}
