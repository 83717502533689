const state = {
  allEvents: [],
  activeEvents: [],
};

const getters = {
  all: (state) => state.allEvents,
  active: (state) => state.activeEvents,
};

const mutations = {
  setAll: (state, events) => {
    state.allEvents = events;
  },

  setActive: (state, activeEvents) => {
    state.activeEvents = activeEvents;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const events = await skyos.events.list();

    commit('setAll', events);
  },

  updateActive: ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    const filtered = state.allEvents.filter((event) => event.venue_id === venueId);

    commit('setActive', filtered);
  },

  create: async ({ dispatch, rootGetters }, newEvent) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.events.create(newEvent);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve(response);
  },

  edit: async ({ dispatch, rootGetters }, editedEvent) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.events.update(editedEvent);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.events.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
