import axios from 'axios';
import { parseErrorFromResponse } from '../../utils/errors';

export default class BaseResource {
  constructor(token) {
    this.baseURL = 'https://api.roveiq.com';

    if (import.meta.env.VITE_USE_API) {
      this.baseURL = import.meta.env.VITE_USE_API;
    }

    this.jwt = undefined;
    const headers = {
      'X-Rove-Request': Math.floor(Math.random() * 10000)
    };

    if (token && token.jwt) {
      this.jwt = token.jwt();
      headers.auth_token = this.jwt;
    }
    this.http = axios.create({
      baseURL: this.baseURL,
      timeout: 60000,
      headers,
    });

    this.http.interceptors.request.use(
      (request) => {
        if (!request.url.includes('/auth')) {
          if (token.isExpired()) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            document.location.reload();
          }
        }
        return request;
      },
      (error) => {
        const parsedError = parseErrorFromResponse(error.response);
        return Promise.reject(parsedError);
      },
    );

    this.errorApiErrorInterceptor = this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const parsedError = parseErrorFromResponse(error.response);
          return Promise.reject(parsedError);
        } else {
          console.error(error);
          return Promise.reject(error.message);
        }
      },
    );

    this.apiToken = token;
  }
}
