const state = {
  datasets: [],
};

const getters = {
  datasets: (state) => state.datasets,
  routingDatasets: (state) => state.datasets.filter((dataset) => dataset.type === 'geojson:routing'),
  structureDatasets: (state) => state.datasets.filter((dataset) => dataset.type === 'geojson:structures'),
  styleDatasets: (state) => state.datasets.filter((dataset) => dataset.type === 'geojson:styles'),
};

const mutations = {
  setDatasets: (state, datasets) => {
    state.datasets = datasets;
  },
};

const actions = {
  fetchDatasets: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const datasets = await skyos.datasets.list(venueId);

    commit('setDatasets', datasets);
  },

  create: async ({ rootGetters }, newDataset) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.datasets.create(newDataset, venueId);
  },

  edit: async ({ rootGetters }, editedObject) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.datasets.update(editedObject, venueId);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.datasets.delete(id, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
