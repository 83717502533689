import _ from 'lodash';

const state = {
  allFeeds: [],
};

const getters = {
  all: (state) => state.allFeeds,
  activeVenueFeeds: (state, getters, rootState, rootGetters) => {
    const activeVenueId = rootGetters['content/venues/activeVenueId'];
    return state.allFeeds.filter((feed) => feed.venue_id === activeVenueId);
  },
};

const mutations = {
  setAll: (state, feeds) => {
    state.allFeeds = feeds;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const feeds = await skyos.feeds.list(venueId);

    commit('setAll', feeds);
  },

  create: async ({ dispatch, rootGetters }, newFeed) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.feeds.create(newFeed);

    await dispatch('fetchAll');
    return Promise.resolve();
  },

  edit: async ({ commit, state, rootGetters }, editedFeed) => {
    const skyos = rootGetters['app/skyOSService'];

    const updated = await skyos.feeds.update(editedFeed);

    const index = state.feeds.findIndex((item) => item.id === updated.id);

    const feedsCopy = _.clone(state.feeds);

    feedsCopy[index] = updated;

    commit('setAll', feedsCopy);
    return Promise.resolve(feedsCopy);
  },

  delete: async ({ commit, state, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];

    await skyos.feeds.delete(id, venueId);

    const index = state.allFeeds.findIndex((item) => item.id === id);

    const feedsCopy = _.clone(state.allFeeds);

    feedsCopy.splice(index, 1);

    commit('setAll', feedsCopy);
    return Promise.resolve(feedsCopy);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
