import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class DatasetsResource extends BaseResource {
  async create(newDataset, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/datasets`, newDataset);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/datasets/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id, venueId) {
    const endpoint = `v2/venues/${venueId}/datasets/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async invalidRoutes(datasetId, venueId) {
    const endpoint = `v2/venues/${venueId}/datasets/${datasetId}/routes`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/datasets`);

    return Promise.resolve(res.data.data);
  }

  async update(updatedDataset, venueId) {
    if (!updatedDataset || !updatedDataset.get('id')) {
      return Promise.reject(new ValidationError('missing required field "id" in update dataset payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/datasets/${updatedDataset.get('id')}`;
    const res = await this.http.put(endpoint, updatedDataset);

    return Promise.resolve(res.data.data);
  }
}
