import Vue from 'vue';
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  LineChart,
  PieChart,
  RadarChart
} from 'echarts/charts'
import {
  LegendComponent,
  TooltipComponent,
  PolarComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  LegendComponent,
  TooltipComponent,
  PolarComponent
]);

// register globally (or you can do it locally)
Vue.component('ECharts', ECharts)

// import VueECharts from 'vue-echarts';
//
// /**
//  * Vue ECharts
//  * https://github.com/ecomfe/vue-echarts
//  *
//  */
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/legend';
// import 'echarts/lib/component/polar';
// import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/chart/pie';
// import 'echarts/lib/chart/radar';
//
// Vue.component('ECharts', VueECharts);
