const state = {
  schedules: [],
  dateRangeAvailable: {
    start: '',
    end: '',
    screenIds: [],
    available: true,
  },
};

const getters = {
  // TODO
};

const mutations = {
  setSchedules: (state, schedules) => {
    state.schedults = schedules;
  },

  setDateRangeAvailable: (state, obj) => {
    state.dateRangeAvailable = obj;
  },
};

const actions = {
  updateSchedules: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const schedules = await skyos.schedules.list();

    commit('setSchedules', schedules);
  },

  create: async ({ rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    const schedules = await skyos.schedules.create(payload);

    return Promise.resolve(schedules);
  },

  checkAvailability: async ({ commit, rootGetters }, config) => {
    const skyos = rootGetters['app/skyOSService'];
    const isAvailable = await skyos.schedules.available(config);

    commit('setDateRangeAvailable', { ...config, isAvailable });

    return Promise.resolve(isAvailable);
  },

  delete: async ({ rootGetters }, scheduleId) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.schedules.delete(scheduleId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
