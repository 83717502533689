export default {
  common: {
    add: 'Adicionar',
    cancel: 'Cancelar',
    description: 'Descrição',
    delete: 'Excluir',
    title: 'Título',
    save: 'Salvar',
    faq: 'FAQ',
    contact: 'Contate-nos',
    tos: 'Termos do serviço',
    policy: 'Política de privacidade',
  },
  board: {
    titlePlaceholder: 'Insira um título para este cartão',
    deleteDescription: 'Tem certeza de que deseja excluir este cartão?',
    editCard: 'Editar cartão',
    deleteCard: 'Excluir cartão',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTE',
      DONE: 'FEITO',
    },
  },
  chat: {
    online: 'Usuários online ({count})',
    addChannel: 'Adicionar canal',
    channel: 'Canal | Canais',
    message: 'Mensagem',
  },
  email: {
    compose: 'Compor e-mail',
    send: 'Enviar',
    subject: 'Assunto',
    labels: 'Etiquetas',
    emptyList: 'Lista de e-mail vazia',
    inbox: 'Caixa de entrada',
    sent: 'Enviado',
    drafts: 'Rascunhos',
    starred: 'Com estrela',
    trash: 'Lixo',
    work: 'Trabalho',
    invoice: 'Fatura',
  },
  todo: {
    addTask: 'Adicionar tarefa',
    tasks: 'Tarefas',
    completed: 'Concluída',
    labels: 'Etiquetas',
  },
  dashboard: {
    activity: 'Atividade',
    weeklySales: 'Vendas semanais',
    sales: 'Vendas',
    recentOrders: 'Pedidos recentes',
    sources: 'Fontes de tráfego',
    lastweek: 'vs semana passada',
    orders: 'Pedidos',
    customers: 'Clientes',
    tickets: 'Ingressos de suporte',
    viewReport: 'Ver relatório',
  },
  usermenu: {
    profile: 'Perfil',
    signin: 'Entrar',
    dashboard: 'Dashboard',
    signout: 'Sair',
  },
  error: {
    notfound: 'Página não encontrada',
    other: 'Ocorreu um erro',
  },
  check: {
    title: 'Definir nova senha',
    backtosign: 'Voltar para Entrar',
    newpassword: 'Nova senha',
    button: 'Defina uma nova senha e faça login',
    error: 'O link de ação é inválido',
    verifylink: 'Verificando link ...',
    verifyemail: 'Verificando endereço de e-mail ...',
    emailverified: 'Email verificado! Redirecionando ...',
  },
  forgot: {
    title: 'Esqueceu a senha?',
    subtitle: 'Insira o endereço de e-mail da sua conta e enviaremos um link para redefinir sua senha.',
    email: 'Email',
    button: 'Solicitar redefinição de senha',
    backtosign: 'Voltar para Entrar',
  },
  login: {
    title: 'Entrar',
    email: 'Email',
    password: 'Senha',
    button: 'Entrar',
    orsign: 'Ou faça login com',
    forgot: 'Esqueceu a senha?',
    noaccount: 'Não tem conta?',
    create: 'Crie um aqui',
    error: 'A combinação de e-mail / senha é inválida',
  },
  register: {
    title: 'Criar conta',
    name: 'Nome completo',
    email: 'Email',
    password: 'Senha',
    button: 'Criar conta',
    orsign: 'Ou inscreva-se com',
    agree: 'Ao se inscrever, você concorda com',
    account: 'já tem uma conta?',
    signin: 'Entrar',
  },
  utility: {
    maintenance: 'Em manutenção',
  },
  faq: {
    call: 'Tem outras perguntas? Por favor, entre em contato',
  },
  menu: {
    search: 'Pesquisar (pressione \'ctrl + /\' para focar)',
    dashboard: 'Dashboard',
    logout: 'Logout',
    profile: 'Perfil',
    blank: 'Página em branco',
    pages: 'Páginas',
    others: 'Outros',
    email: 'Email',
    chat: 'Bate-papo',
    todo: 'Todo',
    board: 'Quadro de Tarefas',
    users: 'Usuários',
    usersList: 'Lista',
    usersEdit: 'Editar',
    auth: 'Páginas de autenticação',
    authLogin: 'Signin / Login',
    authRegister: 'Inscrição / Registro',
    authVerify: 'Verificar e-mail',
    authForgot: 'Esqueci a senha',
    authReset: 'Redefinir senha',
    errorPages: 'Páginas de erro',
    errorNotFound: 'Não encontrado / 404',
    errorUnexpected: 'Inesperado / 500',
    utilityPages: 'Páginas utilitárias',
    utilityMaintenance: 'Manutenção',
    utilitySoon: 'Em breve',
    utilityHelp: 'FAQs / Ajuda',
    levels: 'Níveis de menu',
    disabled: 'Menu desativado',
    docs: 'Docs',
    feedback: 'Feedback',
    support: 'Suporte',
  },
  $vuetify: {
    badge: 'Distintivo',
    close: 'Fechar',
    dataIterator: {
      noResultsText: 'Nenhum registro correspondente encontrado',
      loadingText: 'Carregando itens ...',
    },
    dataTable: {
      itemsPerPageText: 'Linhas por página:',
      ariaLabel: {
        sortDescending: 'Ordenado em ordem decrescente.',
        sortAscending: 'Ordenado em ordem crescente.',
        sortNone: 'Não classificado.',
        activateNone: 'Ative para remover a classificação.',
        activateDescending: 'Ative para classificar em ordem decrescente.',
        activateAscending: 'Ative para classificar em ordem crescente.',
      },
      sortBy: 'Classificar por',
    },
    dataFooter: {
      itemsPerPageText: 'Itens por página:',
      itemsPerPageAll: 'Tudo',
      nextPage: 'Próxima página',
      prevPage: 'Página anterior',
      firstPage: 'Primeira página',
      lastPage: 'Última página',
      pageText: '{0} - {1} de {2}',
    },
    datePicker: {
      itemsSelected: '{0} selecionado',
      nextMonthAriaLabel: 'Mês que vem',
      nextYearAriaLabel: 'Ano que vem',
      prevMonthAriaLabel: 'Mês anterior',
      prevYearAriaLabel: 'Ano anterior',
    },
    noDataText: 'Sem dados disponíveis',
    carousel: {
      prev: 'Visual anterior',
      next: 'Próximo visual',
      ariaLabel: {
        delimiter: 'Diapositivo do carrossel {0} de {1}',
      },
    },
    calendar: {
      moreEvents: '{0} mais',
    },
    fileInput: {
      counter: '{0} arquivos',
      counterSize: '{0} arquivos ({1} no total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Navegação da paginação',
        next: 'Próxima página',
        previous: 'Página anterior',
        page: 'Ir para a página {0}',
        currentPage: 'Página atual, página {0}',
      },
    },
  },
};
