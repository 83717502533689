const state = {
  label: '',
  large: false,
  needsRefreshed: false,
  screenId: 0,
  show: false,
  url: '',
};

const getters = {
  label: (state) => state.label,
  large: (state) => state.large,
  needsRefreshed: (state) => state.needsRefreshed,
  screenId: (state) => state.screenId,
  show: (state) => state.show,
  url: (state) => {
    const randomNumber = Math.round(Math.random() * 10000000);
    return `${state.url}&rand=${randomNumber}`;
  },
};

const mutations = {
  setLabel: (state, label) => {
    state.label = label;
  },

  setLarge: (state, large) => {
    state.large = large;
  },

  setNeedsRefreshed: (state, needsRefreshed) => {
    state.needsRefreshed = needsRefreshed;
  },

  setScreenId: (state, screenId) => {
    state.screenId = screenId;
  },

  setShow: (state, show) => {
    state.show = show;
  },

  setUrl: (state, url) => {
    state.url = url;
  },
};

const actions = {
  doneRefreshing: ({ commit }) => {
    commit('setNeedsRefreshed', false);
  },

  startPreviewer: ({
    commit,
    rootGetters
  }, screenId) => {
    commit('setScreenId', screenId);
    const activeVenue = rootGetters['content/venues/active'];

    // Get Screen
    const screen = rootGetters['content/screens/all'].find((s) => s.id === screenId);

    commit('setLabel', '');
    if (screen) {
      const kiosk = rootGetters['content/kiosks/all'].find((k) => k.id === screen.kiosk_id);

      if (kiosk) {
        commit('setLabel', `${activeVenue.name} | ${kiosk.name} | ${screen.name}`);
      }
    }

    const { templateId } = activeVenue.settings;
    const url = `https://smartlink-template-${templateId}.s3.us-east-2.amazonaws.com/index.html?screenId=${screenId}&screenMode=web`;

    commit('setUrl', url);
    commit('setShow', true);
  },

  stopPreviewer: ({ commit }) => {
    commit('setShow', false);
    commit('setUrl', '');
  },

  updateLargePreviewer: ({ commit }, shouldBeLarge) => {
    commit('setLarge', shouldBeLarge);
  },

  refreshContent: ({
    commit,
    state
  }) => {
    if (state.show) {
      commit('setNeedsRefreshed', true);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
