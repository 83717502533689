import BaseResource from './base';

export default class OrganizationsResource extends BaseResource {
  async create(newOrganization) {
    const res = await this.http.post('v1/organizations', newOrganization);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/organizations/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/organizations/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/organizations');

    return Promise.resolve(res.data.data);
  }

  async update(organization) {
    const res = await this.http.put(`v1/organizations/${organization.id}`, organization);

    return Promise.resolve(res.data.data);
  }
}
