const state = {
  allScreens: [],
  activeScreens: [],
};

const getters = {
  all: (state) => state.allScreens,
  active: (state) => state.activeScreens,
};

const mutations = {
  setAllScreens: (state, allScreens) => {
    state.allScreens = allScreens;
  },
  setActiveScreens: (state, activeScreens) => {
    state.activeScreens = activeScreens;
  },
};

const actions = {
  fetchAll: async ({
    commit,
    rootGetters,
  }) => {
    const skyos = rootGetters['app/skyOSService'];
    const screens = await skyos.screens.list();

    commit('setAllScreens', screens);
  },

  updateActive: async ({
    commit,
    state,
    rootGetters,
  }) => {
    const activeKioskIds = rootGetters['content/kiosks/activeKioskIds'];

    if (!state.allScreens) {
      commit('setActiveScreens', []);

      return;
    }
    const filtered = state.allScreens.filter((screen) => activeKioskIds.includes(screen.kiosk_id));

    commit('setActiveScreens', filtered);
  },

  create: async ({ rootGetters }, newScreen) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.screens.create(newScreen);
  },

  edit: async ({
    dispatch,
    rootGetters,
  }, editedScreen) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.screens.update(editedScreen);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  categories: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    await skyos.screens.categories(data);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.screens.delete(id);
  },

  screenHealth: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.screens.screenHealth(id);
  },

  screensHealthV2: async ({ rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.screens.healthsV2();
  },

  screensCvHealth: async ({ rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.screens.healthsCv();
  },

  dataUsage: async ({ rootGetters }, params) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.screens.dataUsage(params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
