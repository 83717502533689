import cloneDeep from 'lodash/cloneDeep';

const state = {
  isChangingVenues: false,
  allVenues: [],
  activeVenue: undefined,
  defaultOverlayId: null,
  enableRoveMaps: false,
};

const getters = {
  isChangingVenues: (state) => state.isChangingVenues,
  all: (state) => state.allVenues,
  active: (state) => state.activeVenue,
  activeVenueId: (state) => {
    if (state.activeVenue) {
      return state.activeVenue.id;
    }
    return undefined;
  },
  mapboxFloors: (state) => {
    if (state?.activeVenue?.settings?.mapboxSettings?.floors) {
      const floors = state.activeVenue.settings.mapboxSettings.floors.map((floor) => {
        if (floor.dataset_id) {
          return {
            name: floor.name,
            id: `roveiq-${floor.dataset_id}`,
          };
        }
        return floor;
      });
      return floors;
    }

    return [];
  },
  defaultOverlayId: (state) => {
    if (state?.activeVenue?.settings?.selfie?.defaultOverlayId) {
      return state.activeVenue.settings.selfie.defaultOverlayId;
    }

    return null;
  },
  enableRoveMaps: (state) => {
    const migrated = state?.activeVenue?.settings?.roveMap?.converted || false;
    const mapType = state?.activeVenue?.settings?.mapType || 'mapbox';

    return (migrated || mapType === 'rove');
  },
};

const mutations = {
  setIsChangingVenues: (state, isChangingVenues) => {
    state.isChangingVenues = isChangingVenues;
  },

  setAllVenues: (state, venues) => {
    state.allVenues = venues;
  },

  setActiveVenue: (state, activeVenue) => {
    state.activeVenue = activeVenue;
  },
};

const actions = {
  updateIsChangingVenues: ({ commit }, isChangingVenues) => {
    commit('setIsChangingVenues', isChangingVenues);
  },

  fetchAll: async ({
    commit,
    rootGetters,
  }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venues = await skyos.venues.list();

    commit('setAllVenues', venues);
  },

  updateActive: async ({
    commit,
    dispatch,
    state,
  }, venueId) => {
    const activeVenueId = parseInt(venueId, 10);
    const found = state.allVenues.find((venue) => venue.id === activeVenueId);
    if (found) {
      commit('setActiveVenue', found);
    } else if (state.allVenues.length > 0) {
      commit('setActiveVenue', state.allVenues[0]);
    } else {
      return Promise.reject(new Error('unable to update active venue'));
    }

    return dispatch('app/previewer/stopPreviewer', null, { root: true });
  },

  create: async ({ rootGetters }, newVenue) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.venues.create(newVenue);
  },

  edit: async ({
    commit,
    dispatch,
    rootGetters,
    state,
  }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    const updatedVenue = await skyos.venues.update(payload);
    const updatedVenues = [...state.allVenues];
    const foundIndex = state.allVenues.findIndex((venue) => venue.id === updatedVenue.id);
    if (foundIndex !== -1) {
      updatedVenues[foundIndex] = updatedVenue;
      commit('setAllVenues', updatedVenues);
    }
    if (updatedVenue.id === state.activeVenue.id) {
      commit('setActiveVenue', updatedVenue);
    }
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({ commit, rootGetters, state }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    await skyos.venues.delete(id);

    const foundIndex = state.allVenues.findIndex((venue) => venue.id === id);
    const updatedVenues = [...state.allVenues];
    if (foundIndex !== -1) {
      updatedVenues.splice(foundIndex, 1);
      commit('setAllVenues', updatedVenues);
    }
    if (id === state.activeVenue.id) {
      commit('setActiveVenue', updatedVenues[0]);
    }
  },

  enableLanguages: async ({
    dispatch,
    rootGetters,
  }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return skyos.venues.getLanguages(id);
  },

  addLanguage: async ({ rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.venues.addLanguage(payload.venueId, payload.languageId);
  },

  removeLanguage: async ({ rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.venues.deleteLanguage(payload.venueId, payload.venueLanguageId);
  },

  fetchInvalidRoutes: async ({ rootGetters, state, commit }) => {
    const skyos = rootGetters['app/skyOSService'];
    const kiosks = rootGetters['content/kiosks/active'];

    const results = await skyos.venues.invalidRoutes(state.activeVenue.id);

    const updated = kiosks.map((kiosk) => {
      kiosk.invalidRoutes = results.find((result) => result.kiosk.id === kiosk.id);
      return kiosk;
    });

    commit('setActiveKiosks', updated);
  },

  migrateMap: async ({ rootGetters, state }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = state.activeVenue.id;

    return skyos.venues.migrateMap(venueId);
  },

  handleNewVenueRoveMaps({ dispatch, getters }) {
    const workingCopy = cloneDeep(getters.active);

    if (!workingCopy) {
      throw new Error('no active venue selected');
    }

    if (workingCopy && workingCopy.settings && workingCopy.roveMap) {
      workingCopy.settings.roveMap.converted = true;
    } else {
      workingCopy.settings.roveMap = {
        converted: true,
      };
    }

    dispatch('edit', workingCopy);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
