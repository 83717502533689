import ValidationError from '@/errors/validation';

const create = async ({ commit, getters, rootGetters }, newAd) => {
  const skyos = rootGetters['app/skyOSService'];
  const ad = await skyos.ads.create(newAd.campaign_id, newAd);

  const prevAds = getters.ads || [];

  prevAds.push(ad);
  commit('setAds', prevAds);

  return Promise.resolve(ad);
};

const deleteAd = async ({ commit, getters, rootGetters }, opts) => {
  const skyos = rootGetters['app/skyOSService'];

  if (!opts.campaignId || !opts.adId) {
    return Promise.reject(new ValidationError('missing required param'));
  }

  await skyos.ads.delete(opts.campaignId, opts.adId);

  const prevAds = getters.ads;

  const foundIndex = prevAds.findIndex((ad) => ad.id === opts.adId);

  prevAds.splice(foundIndex, 1);
  commit('setAds', prevAds);

  return Promise.resolve();
};

const update = async ({ commit, getters, rootGetters }, updatedAd) => {
  const skyos = rootGetters['app/skyOSService'];
  const updated = await skyos.ads.update(updatedAd.campaign_id, updatedAd);

  const prevAds = getters.ads;

  const foundIndex = prevAds.findIndex((ad) => ad.id === updated.id);

  prevAds.splice(foundIndex, 1);
  commit('setAds', prevAds);

  return Promise.resolve(updated);
};

const fetchActiveAdSlots = async ({ commit, rootGetters }) => {
  const screens = rootGetters['content/screens/active'];

  const skyos = rootGetters['app/skyOSService'];

  const allAdSlots = await skyos.adSlots.list();

  const activeScreenIds = screens.map((screen) => screen.id);
  const activeAdSlots = allAdSlots.filter((adSlot) => activeScreenIds.includes(adSlot.screen_id));

  commit('setActiveAdSlots', activeAdSlots);
};

const fetchActive = async ({ rootGetters }, params) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.schedules.active(params);
};

const fetchAllCampaigns = async ({ rootGetters }) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.campaigns.list();
};

const fetchAdInventory = async ({ rootGetters }, config) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.ads.inventory(config.screen_id, config.start, config.end);
};

const fetchCampaignAds = async ({ rootGetters }, campaignId) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.ads.fetchByCampaignId(campaignId);
};

export default {
  create,
  deleteAd,
  update,
  fetchActiveAdSlots,
  fetchActive,
  fetchAllCampaigns,
  fetchAdInventory,
  fetchCampaignAds,
};
