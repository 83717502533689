import adminPages from './menus/admin.menu';
import menuPages from './menus/pages.menu';

export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: menuPages,
    },
  ],

  admin: [
    {
      text: '',
      key: '',
      items: adminPages,
    },
  ],

  // footer links
  /*
  footer: [{
    text: 'Docs',
    key: 'menu.docs',
    href: 'https://vuetifyjs.com',
    target: '_blank'
  }]
  */
};
