export default {
  common: {
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    description: 'Beschreibung',
    delete: 'Löschen',
    title: 'Titel',
    save: 'Speichern',
    faq: 'FAQ',
    contact: 'Kontaktieren Sie uns',
    tos: 'Nutzungsbedingungen',
    policy: 'Datenschutzerklärung',
  },
  board: {
    titlePlaceholder: 'Geben Sie einen Titel für diese Karte ein',
    deleteDescription: 'Möchten Sie diese Karte wirklich löschen?',
    editCard: 'Karte bearbeiten',
    deleteCard: 'Karte löschen',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'PRÜFUNG',
      DONE: 'FERTIG',
    },
  },
  chat: {
    online: 'Benutzer online ({count})',
    addChannel: 'Kanal hinzufügen',
    channel: 'Kanal | Kanäle',
    message: 'Nachricht',
  },
  email: {
    compose: 'E-Mail verfassen',
    send: 'Senden',
    subject: 'Betreff',
    labels: 'Etiketten',
    emptyList: 'Leere E-Mail-Liste',
    inbox: 'Posteingang',
    sent: 'Gesendet',
    drafts: 'Entwürfe',
    starred: 'Markiert',
    trash: 'Müll',
    work: 'Arbeit',
    invoice: 'Rechnung',
  },
  todo: {
    addTask: 'Aufgabe hinzufügen',
    tasks: 'Aufgaben',
    completed: 'Abgeschlossen',
    labels: 'Etiketten',
  },
  dashboard: {
    activity: 'Aktivität',
    weeklySales: 'Wochenverkäufe',
    sales: 'Vertrieb',
    recentOrders: 'Letzte Bestellungen',
    sources: 'Verkehrsquellen',
    lastweek: 'vs letzte Woche',
    orders: 'Bestellungen',
    customers: 'Kunden',
    tickets: 'Support Tickets',
    viewReport: 'Bericht anzeigen',
  },
  usermenu: {
    profile: 'Profil',
    signin: 'Anmelden',
    dashboard: 'Dashboard',
    signout: 'Abmelden',
  },
  error: {
    notfound: 'Seite nicht gefunden',
    other: 'Ein Fehler ist aufgetreten',
  },
  check: {
    title: 'Neues Passwort festlegen',
    backtosign: 'Zurück zur Anmeldung',
    newpassword: 'Neues Passwort',
    button: 'Legen Sie ein neues Passwort fest und melden Sie sich an',
    error: 'Der Aktionslink ist ungültig',
    verifylink: 'Link überprüfen ...',
    verifyemail: 'E-Mail-Adresse überprüfen ...',
    emailverified: 'Email überprüft! Umleiten ...',
  },
  forgot: {
    title: 'Passwort vergessen?',
    subtitle: 'Geben Sie die E-Mail-Adresse Ihres Kontos ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
    email: 'E-Mail',
    button: 'Passwort zurücksetzen',
    backtosign: 'Zurück zur Anmeldung',
  },
  login: {
    title: 'Anmelden',
    email: 'E-Mail',
    password: 'Passwort',
    button: 'Anmelden',
    orsign: 'Oder melden Sie sich mit',
    forgot: 'an Passwort vergessen?',
    noaccount: 'Sie haben noch keinen Account?',
    create: 'Erstellen Sie hier eine',
    error: 'Die E-Mail / Passwort-Kombination ist ungültig',
  },
  register: {
    title: 'Konto erstellen',
    name: 'Vollständiger Name',
    email: 'E-Mail',
    password: 'Passwort',
    button: 'Konto erstellen',
    orsign: 'Oder melden Sie sich mit',
    agree: 'an Mit Ihrer Anmeldung stimmen Sie dem',
    account: 'zu Sie haben bereits ein Konto?',
    signin: 'Anmelden',
  },
  utility: {
    maintenance: 'In der Wartung',
  },
  faq: {
    call: 'Haben Sie weitere Fragen? Bitte erreichen Sie',
  },
  menu: {
    search: 'Suchen (drücken Sie \'Strg + /\', um zu fokussieren)',
    dashboard: 'Dashboard',
    logout: 'Abmelden',
    profile: 'Profil',
    blank: 'Leere Seite',
    pages: 'Seiten',
    others: 'Andere',
    email: 'E-Mail',
    chat: 'Chat',
    todo: 'Todo',
    board: 'Task Board',
    users: 'Benutzer',
    usersList: 'Liste',
    usersEdit: 'Bearbeiten',
    auth: 'Auth-Seiten',
    authLogin: 'Anmelden / Anmelden',
    authRegister: 'Anmeldung / Registrierung',
    authVerify: 'E-Mail überprüfen',
    authForgot: 'Passwort vergessen',
    authReset: 'Passwort zurücksetzen',
    errorPages: 'Fehlerseiten',
    errorNotFound: 'Nicht gefunden / 404',
    errorUnexpected: 'Unerwartet / 500',
    utilityPages: 'Hilfsseiten',
    utilityMaintenance: 'Wartung',
    utilitySoon: 'Bald erhältlich',
    utilityHelp: 'FAQs / Hilfe',
    levels: 'Menüebenen',
    disabled: 'Menü deaktiviert',
    docs: 'Docs',
    feedback: 'Feedback',
    support: 'Unterstützung',
  },
  $vuetify: {
    badge: 'Abzeichen',
    close: 'Schließen',
    dataIterator: {
      noResultsText: 'Keine übereinstimmenden Datensätze gefunden',
      loadingText: 'Elemente laden ...',
    },
    dataTable: {
      itemsPerPageText: 'Zeilen pro Seite:',
      ariaLabel: {
        sortDescending: 'Absteigend sortiert.',
        sortAscending: 'Aufsteigend sortiert.',
        sortNone: 'Nicht sortiert.',
        activateNone: 'Aktivieren Sie diese Option, um die Sortierung zu entfernen.',
        activateDescending: 'Aktivieren Sie diese Option, um absteigend zu sortieren.',
        activateAscending: 'Aktivieren Sie diese Option, um aufsteigend zu sortieren.',
      },
      sortBy: 'Sortieren nach',
    },
    dataFooter: {
      itemsPerPageText: 'Artikel pro Seite:',
      itemsPerPageAll: 'Alle',
      nextPage: 'Nächste Seite',
      prevPage: 'Vorherige Seite',
      firstPage: 'Erste Seite',
      lastPage: 'Letzte Seite',
      pageText: '{0} - {1} von {2}',
    },
    datePicker: {
      itemsSelected: '{0} ausgewählt',
      nextMonthAriaLabel: 'Nächsten Monat',
      nextYearAriaLabel: 'Nächstes Jahr',
      prevMonthAriaLabel: 'Vorheriger Monat',
      prevYearAriaLabel: 'Vorjahr',
    },
    noDataText: 'Keine Daten verfügbar',
    carousel: {
      prev: 'Vorheriges Bild',
      next: 'Nächstes Bild',
      ariaLabel: {
        delimiter: 'Karussell Folie {0} von {1}',
      },
    },
    calendar: {
      moreEvents: '{0} mehr',
    },
    fileInput: {
      counter: '{0} Dateien',
      counterSize: '{0} Dateien ({1} insgesamt)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Paginierungsnavigation',
        next: 'Nächste Seite',
        previous: 'Vorherige Seite',
        page: 'Gehe zu Seite {0}',
        currentPage: 'Aktuelle Seite, Seite {0}',
      },
    },
  },
};
