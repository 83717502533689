import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class UsersResource extends BaseResource {
  async delete(id) {
    const res = await this.http.delete(`v1/users/${id}`);

    return Promise.resolve(res.data.data);
  }

  async invite(payload) {
    const res = await this.http.post('v1/users/invite', payload);

    return Promise.resolve(res.data.data);
  }

  async getById(id) {
    const res = await this.http.get(`v1/users/${id}`);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/users');

    return Promise.resolve(res.data.data);
  }

  async mimic(userId) {
    const res = await this.http.get(`users/${userId}/mimic`);

    return Promise.resolve(res.data.data);
  }

  async update(updatedUser) {
    if (!updatedUser || !updatedUser.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update user payload'));
    }
    const endpoint = `v1/users/${updatedUser.id}`;
    const res = await this.http.put(endpoint, updatedUser);

    return Promise.resolve(res.data.data);
  }
}
