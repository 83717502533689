const state = {
  selfies: [],
  activeSelfies: [],
  allEffects: [],
};

const getters = {
  selfies: (state) => state.selfies,
  activeSelfies: (state) => state.activeSelfies,
  allEffects: (state) => state.allEffects,
};

const mutations = {
  setSelfies: (state, selfies) => {
    state.selfies = selfies;
  },
  setActiveSelfies: (state, activeSelfies) => {
    state.activeSelfies = activeSelfies;
  },
  setAllEffects: (state, effects) => {
    state.allEffects = effects;
  },
};

const actions = {
  saveSelfie: async ({ rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    if (payload.id > 0) {
      return skyos.selfies.update(payload);
    }
    return skyos.selfies.create(payload);
  },
  deleteSelfie: async ({ rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.selfies.delete(payload);
  },
  fetchSelfies: async ({ commit, rootGetters }) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const selfies = await skyos.selfies.list();

      commit('setSelfies', selfies);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  updateActiveSelfies: ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];
    const filtered = state.selfies.filter((selfie) => selfie.venue_id === venueId);

    commit('setActiveSelfies', filtered);

    return Promise.resolve();
  },
  fetchEffects: async ({ commit, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];
    const skyos = rootGetters['app/skyOSService'];

    const effects = await skyos.selfies.fetchEffects(venueId);

    commit('setAllEffects', effects);

    return Promise.resolve();
  },
  createEffect: async ({ commit, state, rootGetters }, payload) => {
    const skyos = rootGetters['app/skyOSService'];

    const newEffect = await skyos.selfies.createEffect(payload);

    const effects = [...state.allEffects];
    effects.push(newEffect);
    commit('setAllEffects', effects);
    return Promise.resolve(newEffect);
  },
  updateEffect: async ({ commit, state, rootGetters }, updatedEffect) => {
    const skyos = rootGetters['app/skyOSService'];

    const updated = await skyos.selfies.updateEffect(updatedEffect);

    const effects = [...state.allEffects];
    const index = effects.findIndex((effect) => effect.id === updated.id);
    effects.splice(index, 1, updated);
    commit('setAllEffects', effects);
    return Promise.resolve();
  },
  deleteEffect: async ({ commit, state, rootGetters }, effectId) => {
    const skyos = rootGetters['app/skyOSService'];
    await skyos.selfies.deleteEffect(effectId);

    const effects = [...state.allEffects];
    const index = effects.findIndex((effect) => effect.id === effectId);
    effects.splice(index, 1);
    commit('setAllEffects', effects);
    return Promise.resolve();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
