import BaseResource from './base';

export default class AuthResource extends BaseResource {
  async signin(config) {
    try {
      const res = await this.http.post('v2/auth/login/start', config);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.resolve({
        token: undefined,
        user: undefined,
        success: false,
        error: 'Something went wrong. Your email or password are incorrect.',
      });
    }
  }

  async signinBypass(config) {
    try {
      const res = await this.http.post('v1/auth', config);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.resolve({
        token: undefined,
        user: undefined,
        success: false,
        error: 'Something went wrong. Your email or password are incorrect.',
      });
    }
  }

  async verify(payload) {
    try {
      const res = await this.http.post('v2/auth/login/complete', payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async mimic(userId) {
    return this.http.get(`v1/users/${userId}/mimic`);
  }

  async resetPasswordEmailRequest(payload) {
    try {
      const res = await this.http.post('v1/auth/reset-password/email-request', payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getUserPasswordRequirements(payload) {
    try {
      const res = await this.http.post('v1/auth/password-requirements', payload);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updatePassword(data) {
    try {
      const res = await this.http.post('v1/auth/reset-password/update-password', data);
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async register(data) {
    try {
      const res = await this.http.post('v1/auth/register', data);

      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
