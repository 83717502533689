import _ from 'lodash';

const state = {
  allTemplates: [],
  allLegacyTemplates: [],
  customizations: [],
  isFetchingTemplateVariables: false,
  templateVariables: [],
  templateVariableValues: [],
};

const getters = {
  all: (state) => state.allTemplates,
  legacy: (state) => state.allLegacyTemplates,
  customizations: (state) => state.customizations,
  isFetchingTemplateVariables: (state) => state.isFetchingTemplateVariables,
  templateVariables: (state) => state.templateVariables,
  templateVariableValues: (state) => state.templateVariableValues,
};

const mutations = {
  setAll: (state, templates) => {
    state.allTemplates = templates;
  },

  setAllLegacy: (state, templates) => {
    state.allLegacyTemplates = templates;
  },

  setCustomizations: (state, customizations) => {
    state.customizations = customizations;
  },

  setIsFetchingTemplateVariables: (state, isFetching) => {
    state.isFetchingTemplateVariables = isFetching;
  },

  setTemplateVariables: (state, templateVariables) => {
    state.templateVariables = templateVariables;
  },

  setTemplateVariableValues: (state, values) => {
    state.templateVariableValues = values;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const templates = await skyos.templates.list();

    const venue = rootGetters['content/venues/active'];

    if (venue.settings.templateId) {
      templates.forEach((template) => {
        // eslint-disable-next-line no-param-reassign
        template.selected = (venue.template_id === template.id);
      });
    }

    const filteredTemplates = templates.filter((template) => template.id > 5);

    const sortedTemplates = _.orderBy(filteredTemplates, ['id']);

    commit('setAll', sortedTemplates);
  },

  fetchAllLegacy: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const templates = await skyos.templates.list();

    const venue = rootGetters['content/venues/active'];

    if (venue.settings.templateId) {
      templates.forEach((template) => {
        // eslint-disable-next-line no-param-reassign
        template.selected = (venue.template_id === template.id);
      });
    }

    const filteredTemplates = templates.filter((template) => template.id <= 4);

    const sortedTemplates = _.orderBy(filteredTemplates, ['id']);

    commit('setAllLegacy', sortedTemplates);
  },

  fetchTemplateVariables: async ({ commit, rootGetters }, templateId) => {
    commit('setIsFetchingTemplateVariables', true);
    const skyos = rootGetters['app/skyOSService'];
    const templateVariables = await skyos.templateVariables.fetchByTemplateId(templateId);

    const venue = rootGetters['content/venues/active'];

    commit('setTemplateVariables', templateVariables);

    const customizations = await skyos.customizations.fetchByVenueId(venue.id);

    commit('setCustomizations', customizations);

    const variableValues = {};

    templateVariables.forEach((templateVariable) => {
      const found = customizations.find(
        (customization) => customization.template_variable_id === templateVariable.id,
      );

      if (found) {
        variableValues[templateVariable.id] = found.value;
      } else {
        variableValues[templateVariable.id] = templateVariable.default;
      }
    });

    commit('setTemplateVariableValues', variableValues);

    commit('setIsFetchingTemplateVariables', false);
  },

  saveCustomizations: async ({ dispatch, rootGetters }, opts) => {
    const skyos = rootGetters['app/skyOSService'];
    const keys = Object.keys(opts.values);
    const { currentValues } = opts;

    const promises = keys.map((key) => {
      const templateVariableId = parseInt(key, 10);
      const customization = currentValues.find(
        (c) => (c.template_variable_id === templateVariableId),
      );

      if (customization) {
        customization.value = opts.values[key];

        return skyos.customizations.update(customization);
      }
      const newCustomization = {
        value: opts.values[key],
        template_variable_id: templateVariableId,
        venue_id: opts.venueId,
      };

      return skyos.customizations.create(newCustomization);
    });

    await Promise.all(promises);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve();
  },

  createTemplate: async ({ rootGetters }, newTemplate) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.templates.create(newTemplate);

    return Promise.resolve(response);
  },

  updateTemplate: async ({ rootGetters }, updateTemplate) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.templates.update(updateTemplate);

    return Promise.resolve(response);
  },

  createTemplateVariable: async ({ rootGetters }, newTemplate) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.templateVariables.create(newTemplate);

    return Promise.resolve(response);
  },

  updateTemplateVariable: async ({ rootGetters }, updateTemplate) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.templateVariables.update(updateTemplate);

    return Promise.resolve(response);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
