import BaseResource from './base';

export default class DiagnosticsResource extends BaseResource {
  async getBlackScreenScans(config) {
    const res = await this.http.get('v1/diagnostics/screen-scans', {
      params: {
        screen_id: config.screenId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async getReports(config) {
    const res = await this.http.get('v1/diagnostics/reports', {
      params: {
        screen_id: config.screenId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async getReportById(reportId) {
    const endpoint = `v1/diagnostics/reports/${reportId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async getDigest() {
    const endpoint = 'v1/diagnostics/digest';
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async getTriggeredRules(config) {
    const endpoint = 'v2/diagnostics/triggered' +
      'Rules';
    const res = await this.http.get(endpoint, {
      params: {
        page: config.page,
        limit: config.limit
      }
    });

    return Promise.resolve(res.data.data);
  }

  async getTriggeredRulesCount() {
    const endpoint = 'v2/diagnostics/triggeredRulesCount';
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async getBlackScreens(config) {
    const endpoint = 'v2/diagnostics/blackScreens';
    const res = await this.http.get(endpoint, {
      params: {
        page: config.page,
        limit: config.limit
      }
    });
    return Promise.resolve(res.data.data);
  }

  async getBlackScreensCount() {
    const endpoint = 'v2/diagnostics/blackScreensCount';
    const res = await this.http.get(endpoint);
    return Promise.resolve(res.data.data);
  }

}
