import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class OverlaysResource extends BaseResource {
  async create(newOverlay) {
    const res = await this.http.post('v1/overlays', newOverlay);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/overlays/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/overlays/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/overlays');

    return Promise.resolve(res.data.data);
  }

  async update(updatedOverlay) {
    if (!updatedOverlay || !updatedOverlay.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update overlay payload'));
    }
    const endpoint = `v1/overlays/${updatedOverlay.id}`;
    const res = await this.http.put(endpoint, updatedOverlay);

    return Promise.resolve(res.data.data);
  }
}
