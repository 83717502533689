
<div
  v-shortkey="['ctrl', '/']"
  class="d-flex flex-grow-1 default-layout"
  @shortkey="onKeyup"
>
  <v-overlay
    :value="applicationIsLoading"
    opacity="1.0"
    color="#06121d"
    :style="{ 'z-index': 10 }"
  >
    <div class="text-center">
      <v-img
        class="w-full"
        max-height="50"
        contain
        src="/images/logos/roveiq-smartlink-transition.png"
      />
      <h4 class="mt-1" style="font-family: futura-pt-bold, sans-serif !important;">
        Application loading...
      </h4>
      <v-progress-circular
        class="mt-1"
        indeterminate
        size="64"
      />
    </div>
  </v-overlay>
  <ad-blocker-dialog />
  <delete-dialog />
  <div v-if="!applicationIsLoading" class="fill-width">
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1 dark-bg drawer"
      style="z-index: 17"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2">
          <v-img
            class="w-full"
            fill
            max-height="120"
            src="/images/logos/roveiq-smartlink-transition.png"
          />
        </div>
      </template>

      <main-menu :menu="menu" />

      <!-- Admin menu -->
      <v-divider v-if="userIsAdmin" />
      <main-menu
        v-if="userIsAdmin"
        :menu="admin"
      />

      <!-- Navigation menu footer -->
      <template #append>
        <!-- Footer navigation links -->
        <div class="pa-1">
          <v-icon>mdi-antenna</v-icon>
          <changelog />
        </div>

        <v-dialog
          v-model="supportDialog"
          max-width="50-vw"
          width="30vw"
        >
          <template #activator="{ on, attrs }">
            <div class="pa-1">
              <v-icon>mdi-help-circle-outline</v-icon>
              <v-btn
                v-bind="attrs"
                text
                plain
                x-large
                :input-value="true"
                v-on="on"
              >
                Support
              </v-btn>
            </div>
          </template>
          <v-card>
            <v-card-title class="white--text blue darken-2 align-center">
              Support
              <v-spacer />
              <v-btn
                icon
                color="white"
                outlined
                @click="close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />

            <v-card-text>
              <div class="text-h5 pa-3 text-center">
                Support has moved.
                You can now request support at our new support email:
                <a href="mailto:support@roveiq.com">support@roveiq.com</a>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in footer"
            :key="index"
            :href="item.href"
            small
            :target="item.target"
            text
          >
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <v-autocomplete
            v-if="showSearch"
            v-model="searchItem"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            solo
            flat
            autofocus
            :search-input.sync="search"
            :items="searchItems"
            hide-no-data
            item-text="display"
            item-value="link"
            no-filter
            @click:append="showSearch = false"
          />

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <v-select
              v-if="shouldShowVenueSelector"
              v-model="selectedVenue"
              :items="venuesAlpha"
              item-text="name"
              item-value="id"
              label="Select a venue"
              hide-details
              outlined
              dense
              rounded
              class="mx-1"
              @change="changeSelectedVenue"
            />

            <v-spacer class="d-none d-lg-block" />

            <!-- search input desktop -->
            <v-autocomplete
              ref="search"
              v-model="searchItem"
              class="mx-1 hidden-xs-only"
              :placeholder="$t('menu.search')"
              prepend-inner-icon="mdi-magnify"
              hide-details
              filled
              rounded
              dense
              :items="searchItems"
              :search-input.sync="search"
              hide-no-data
              item-text="display"
              item-value="link"
              no-filter
            />

            <v-spacer class="d-block d-sm-none" />

            <v-btn
              class="d-block d-sm-none"
              icon
              @click="showSearch = true"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height" :fluid="!isContentBoxed">
        <v-layout>
          <v-overlay
            :value="isChangingVenues"
            color="#06121d"
          >
            <div class="text-center">
              <v-img
                class="w-full"
                max-height="50"
                contain
                src="/images/logos/roveiq-smartlink--transition.png"
              />
              <h4 class="mt-1">
                Changing venues...
              </h4>
              <v-progress-circular
                class="mt-1"
                indeterminate
                size="64"
              />
            </div>
          </v-overlay>
          <transition
            enter-active-class="animate__animated animate__fadeInRight"
            leave-active-class="animate__animated animate__fadeOutRight"
          >
            <previewer v-if="showPreviewer" />
          </transition>
          <slot v-if="ready" />
        </v-layout>
      </v-container>

      <v-footer app inset>
        <v-spacer />
        <div class="overline">
          RoveIQ Portal Version {{ versionNumber }} -
          Copyright © 2018-{{ currentYear }} Smartlink Ventures dba RoveIQ, Inc. All rights Reserved.
          <router-link to="/tos">
            Terms of Use
          </router-link>
          |
          <router-link to="/privacy">
            Privacy Policy
          </router-link>
        </div>
      </v-footer>

      <v-snackbar
        v-if="mimicEnabled"
        v-model="showMimic"
        :timeout="-1"
      >
        Mimic Enabled for: {{ user.email }}. <br>Logged in as {{ mimicUser.email }}.

        <template #action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="endMimic"
          >
            Stop Mimic
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </div>
</div>
