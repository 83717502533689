import Vue from 'vue';
import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROD_TOKEN = 'd406cedfb7025dd7604f21128ac19956';
const MIXPANEL_DEV_TOKEN = '34f0aff2a7410daeaccd71db924a6288';
const MIXPANEL_TOKEN = import.meta.env.NODE_ENV === 'production' ? MIXPANEL_PROD_TOKEN : MIXPANEL_DEV_TOKEN;

class AnalyticsPlugin {
  constructor() {
    this.mixpanel = mixpanel;
    this.mixpanel.init(MIXPANEL_TOKEN, {
      debug: import.meta.env.NODE_ENV !== 'production',
      ignore_dnt: true,
    });
    this.user = null;
  }

  setUser(user) {
    this.user = user;
    this.mixpanel.people.set(user);
    this.mixpanel.identify(user.id);
    if (user.admin) {
      this.mixpanel.add_group('user_type', 'admin');
    } else {
      this.mixpanel.add_group('user_type', 'non-admin');
    }
  }

  track(event, metaData) {
    const properties = { ...metaData, ...this.user };
    let cleanProperties;
    try {
      cleanProperties = JSON.parse(JSON.stringify(properties));
      return this.mixpanel.track(event, cleanProperties);
    } catch (e) {
      console.error('error sending data to mixpanel: ', e);
      return false;
    }
  }
}

const install = (Vue) => {
  Vue.prototype.$analytics = new AnalyticsPlugin();
};

Vue.use(install);
