import BaseResource from './base';

export default class APIKeysResource extends BaseResource {
  async create(newAPIKey) {
    const res = await this.http.post('v1/keys', newAPIKey);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/keys/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/keys');

    return Promise.resolve(res.data.data);
  }
}
