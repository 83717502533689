const state = {
  allOverlays: [],
  activeOverlays: [],
};

const getters = {
  all: (state) => state.allOverlays,
  active: (state) => state.activeOverlays,
};

const mutations = {
  setAll: (state, overlays) => {
    state.allOverlays = overlays;
  },

  setActive: (state, activeOverlays) => {
    state.activeOverlays = activeOverlays;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const alerts = await skyos.overlays.list();

    commit('setAll', alerts);
  },

  updateActive: ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    const filtered = state.allOverlays.filter((overlay) => overlay.venue_id === venueId);

    commit('setActive', filtered);
  },

  create: async ({ dispatch, rootGetters }, newOverlay) => {
    const skyos = rootGetters['app/skyOSService'];

    const result = await skyos.overlays.create(newOverlay);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return result;
  },

  edit: async ({ dispatch, rootGetters }, editedOverlay) => {
    const skyos = rootGetters['app/skyOSService'];

    const result = await skyos.overlays.update(editedOverlay);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return result;
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.overlays.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
