import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class ScreensResource extends BaseResource {
  /**
   * Fetch the available ad slot inventory for a given screen and date range
   *
   * @param {Object} opts
   * @param {number} opts.screenId - The id of the screen to fetch inventory for
   * @param {string} opts.start - Date string for start of date range
   * @param {string} opts.end - Date string for end of date range
   * @memberof ScreensResource
   * @returns {Promise}
   */
  async availableInventory(opts) {
    const endpoint = `v1/screens/${opts.screenId}/inventory`;
    const res = await this.http.get(endpoint, {
      params: {
        start: opts.start,
        end: opts.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async create(newScreen) {
    if ('created_at' in newScreen) {
      delete newScreen.created_at;
    }
    if ('updated_at' in newScreen) {
      delete newScreen.updated_at;
    }
    const res = await this.http.post('v1/screens', newScreen);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/screens/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/screens/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async healthsV1() {
    const res = await this.http.get('v1/screens/healths');

    return Promise.resolve(res.data.data);
  }

  async healthsV2() {
    const res = await this.http.get('v2/screens/healths');

    return Promise.resolve(res.data.data);
  }

  async healthsCv() {
    const res = await this.http.get('v1/cvHealths');

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/screens');

    return Promise.resolve(res.data.data);
  }

  async update(updatedScreen) {
    if (!updatedScreen || !updatedScreen.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update screen payload'));
    }
    const endpoint = `v1/screens/${updatedScreen.id}`;
    const res = await this.http.put(endpoint, updatedScreen);

    return Promise.resolve(res.data.data);
  }

  async screenHealth(id) {
    const res = await this.http.get(`v1/screens/${id}/health`);

    return Promise.resolve(res.data.data);
  }

  async dataUsage(params) {
    const res = await this.http.get(`v1/screens/${params.screen_id}/usage`, {
      params: {
        start: params.start,
        end: params.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async categories(data) {
    if (!data.screen || !data.screen.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update screen categories'));
    }
    const endpoint = `v2/screens/${data.screen.id}/categories`;
    const res = await this.http.post(endpoint, {
      category_ids: data.selectedCategoryIds,
    });

    return Promise.resolve(res.data.data);
  }
}
