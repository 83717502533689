import BaseResource from './base';

export default class CommandsResource extends BaseResource {
  async create(newCommand) {
    const res = await this.http.post('v1/commands', newCommand);

    return Promise.resolve(res.data.data);
  }

  async fetchByScreenId(screenId) {
    const res = await this.http.get('v1/commands', {
      params: {
        screen_id: screenId,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/commands/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }
}
