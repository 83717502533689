<template>
  <v-app :class="{printView: printview}">
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </component>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
      :color="toast.color"
      bottom
    >
      {{ toast.message }}
      <v-btn
        v-if="toast.timeout === 0"
        color="white"
        text
        @click="toast.show = false"
      >
        {{ $t('common.close') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

import config from './configs';

// Layouts
import defaultLayout from './layouts/DefaultLayout.vue';
import simpleLayout from './layouts/SimpleLayout.vue';
import authLayout from './layouts/AuthLayout.vue';
import errorLayout from './layouts/ErrorLayout.vue';

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    simpleLayout,
    authLayout,
    errorLayout,
  },

  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({
        rel: 'stylesheet',
        href
      })),
    ],
  },
  data: () => ({
    printview: false
  }),
  computed: {
    ...mapState('app', ['toast']),
    isRouterLoaded() {
      return this.$route.name !== null;
    },
    currentLayout() {
      const layout = this.$route.meta.layout || 'default';

      return `${layout}Layout`;
    },
  },

  mounted() {
    window.addEventListener('unhandledrejection', (event) => {
      this.handleRejection(event);
    });
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('printview')) {
      this.printview = true;
    }
  },

  methods: {
    handleRejection(event) {
      event.preventDefault(); // This stops the error from being printed in the console
      this.$log.error(event);
    },
  },

};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
