<template>
  <div class="previewer-container" :class="containerClassObj">
    <v-system-bar
      color="blue darken-4"
      dark
      style="border-radius: 5px 5px 0 0;"
    >
      <v-icon @click="refresh">
        mdi-refresh
      </v-icon>
      <v-tooltip
        v-if="labelShortened"
        top
      >
        <template #activator="{ on, attrs }">
          <span
            class="preview-label"
            :class="previewLabelClassObj"
            v-bind="attrs"
            v-on="on"
          >
            {{ labelText }}
          </span>
        </template>
        <span>{{ previewLabel }}</span>
      </v-tooltip>
      <span
        v-else
        class="preview-label"
        :class="previewLabelClassObj"
      >
        {{ labelText }}
      </span>
      <v-spacer />
      <v-icon @click="toggleLarge">
        {{ growShrinkIcon }}
      </v-icon>
      <v-icon @click="close">
        mdi-close
      </v-icon>
    </v-system-bar>

    <div class="kiosk" :class="classObj" :style="transformSize">
      <iframe
        ref="frame"
        :width="screenWidth"
        :height="screenHeight"
        sandbox="allow-same-origin allow-scripts allow-forms"
        :src="url"
        style="border: none; margin-top: 2%; margin-left: 1.3%;"
        allow="camera"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  computed: {
    ...mapGetters({
      large: 'app/previewer/large',
      needsRefreshed: 'app/previewer/needsRefreshed',
      previewLabel: 'app/previewer/label',
      url: 'app/previewer/url',
      screenId: 'app/previewer/screenId',
      screens: 'content/screens/active'
    }),

    screen() {
      return this.screens.find((s) => s.id === this.screenId);
    },

    screenWidth() {
      return _.get(this.screen, 'settings.size.width', 1080);
    },

    screenHeight() {
      return _.get(this.screen, 'settings.size.height', 1920);
    },

    transformSize() {
      const factor = this.large ? 0.37 : 0.19;
      return {
        transform: `scale(${(1080 / this.screenWidth * factor)})`,
        width: `${(this.screenWidth / 1080) * 30 + this.screenWidth}px`,
        height: `${(this.screenHeight / 1920) * 70 + this.screenHeight}px`,
      }
    },

    classObj() {
      return {
        'kiosk-lg': this.large,
      };
    },

    containerClassObj() {
      return {
        'container-lg': this.large,
      };
    },

    labelText() {
      const maxCharsLg = 60;
      const maxCharsSm = 36;

      if (this.large) {
        if (this.previewLabel.length > maxCharsLg) {
          const shortened = this.previewLabel.substr(0, maxCharsLg - 3);

          return `${shortened}...`;
        }

        return this.previewLabel;
      }
      if (this.previewLabel.length > maxCharsSm) {
        const shortened = this.previewLabel.substr(0, maxCharsSm - 3);

        return `${shortened}...`;
      }

      return this.previewLabel;
    },

    labelShortened() {
      if (this.large) {
        return this.previewLabel.length > 60;
      }

      return this.previewLabel.length > 36;
    },

    previewLabelClassObj() {
      return {
        'preview-label-lg': this.large,
      };
    },

    growShrinkIcon() {
      return this.large ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
    },
  },

  watch: {
    needsRefreshed(newVal) {
      if (newVal) {
        this.refresh();
        this.$store.dispatch('app/previewer/doneRefreshing');
      }
    },
  },

  methods: {
    close() {
      this.$store.dispatch('app/previewer/stopPreviewer');
      this.$analytics.track('Close previewer');
    },

    refresh() {
      // eslint-disable-next-line no-self-assign
      this.$refs.frame.src = this.$refs.frame.src;
    },

    toggleLarge() {
      this.$store.dispatch('app/previewer/updateLargePreviewer', !this.large);
      this.$analytics.track('Toggle previewer size', { large: this.large });
    },
  },
};
</script>

<style scoped>
.previewer-container {
  position: fixed;
  z-index: 10;
  bottom: 30px;
  right: 10px;
  margin: auto;
  max-width: 211px;
  max-height: 402px;
}

.preview-label {
  font-size: 65%;
}

.preview-label-lg {
  font-size: 75%;
}

.container-lg {
  max-width: 410px;
  max-height: 760px;
}

.kiosk {
  background: #181a19;
  position: relative;
  margin: 0;
  border-radius: 0 0 10px 10px;
  transition: margin-right 1s;
  width: 1110px;
  height: 1990px;
  transform: scale(0.19);
  transform-origin: top left;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.kiosk-lg {
  transform: scale(0.37);
}

#action-bar {
  margin: 0 5%;
  color: #fff;
  font-size: .7rem;
}
</style>
