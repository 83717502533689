
<v-app :class="{printView: printview}">
  <!-- Layout component -->
  <component :is="currentLayout" v-if="isRouterLoaded">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </component>

  <v-snackbar
    v-model="toast.show"
    :timeout="toast.timeout"
    :color="toast.color"
    bottom
  >
    {{ toast.message }}
    <v-btn
      v-if="toast.timeout === 0"
      color="white"
      text
      @click="toast.show = false"
    >
      {{ $t('common.close') }}
    </v-btn>
  </v-snackbar>
</v-app>
