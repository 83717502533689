export default [
  {
    icon: 'mdi-shield-account-variant-outline',
    key: '',
    text: 'Admin',
    regex: /^\/admin/,
    items: [
      {
        icon: 'mdi-account-multiple-outline',
        key: '',
        text: 'Users',
        link: '/admin/users',
      },
      {
        icon: 'mdi-view-grid-plus',
        key: '',
        text: 'Ad Slots',
        link: '/admin/adslots',
      },
      {
        icon: 'mdi-translate',
        key: '',
        text: 'Languages',
        link: '/admin/languages',
      },
      {
        icon: 'mdi-history',
        key: '',
        text: 'History',
        link: '/admin/history',
      },
      {
        icon: 'mdi-finance',
        key: '',
        text: 'Admin Venue Report',
        link: '/admin/venue-report',
      },
      {
        icon: 'mdi-home-city-outline',
        key: '',
        text: 'Create a Venue',
        link: '/admin/create-venue',
      },
      {
        icon: 'mdi-wrench',
        key: '',
        text: 'Diagnostics',
        link: '/admin/diagnostics',
      },
      {
        icon: 'mdi-view-quilt',
        key: '',
        text: 'Templates',
        link: '/admin/templates',
      },
      {
        icon: 'mdi-clipboard-check',
        key: '',
        text: 'Plans',
        link: '/admin/plans',
      },
      {
        icon: 'mdi-clipboard-check',
        key: '',
        text: 'Command Proxy Servers',
        link: '/admin/proxy',
      },
    ],
  },
];
