import BaseResource from './base';

export default class ComputerVisionResource extends BaseResource {
  async venueCvReport(config) {
    try {
      const res = await this.http.get('v1/cvDetections/venueCvReport', {
        params: {
          venue_id: config.venueId,
          start: config.start,
          end: config.end
        },
      });
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
