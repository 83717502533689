import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export const convertDateRangePickerDates = (start, end, timezone) => {
  const pickerStart = new Date(start);
  const pickerEnd = new Date(end);
  const formatStr = 'yyyy-MM-dd';

  return {
    start: format(utcToZonedTime(pickerStart, 'UTC'), formatStr, { timeZone: 'UTC' }),
    end: format(utcToZonedTime(pickerEnd, 'UTC'), formatStr, { timeZone: 'UTC' }),
  };
};

export default class MetricsResource extends BaseResource {
  constructor(token) {
    super(token);
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  async fetchStatsByKiosk(opts) {
    const res = await this.http.get('v1/metrics/stats/kiosk', {
      params: {
        idsite: opts.idSites,
        period: opts.period,
        date: opts.date,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async fetchInteractionsByVenueIds(venueIds) {
    const res = await this.http.get('v1/metrics/interactions/summary', {
      params: {
        venue_ids: venueIds.join(','),
      },
    });

    return Promise.resolve(res.data.data);
  }

  // eslint-disable-next-line camelcase
  async fetchAdplaysByVenueAndDate(venue_id, date_start, date_end) {
    const res = await this.http.get('v1/metrics/adplays', {
      params: {
        group_by: 'venue_time',
        venue_id,
        date_start,
        date_end,
      },
      timeout: 0,
    });

    return Promise.resolve(res.data.data);
  }

  async fetchAdplaysCount() {
    const res = await this.http.get('v1/metrics/adplays', {
      params: {
        group_by: 'advertisements',
      },
    });

    return Promise.resolve(res.data.data);
  }

  async fetchProgrammaticSummary(start, end, venue_id) {
    const res = await this.http.get('v1/metrics/adplays/programmaticSummary', {
      params: {
        start,
        end,
        venue_id,
      },
      timeout: 0,
    });

    return Promise.resolve(res.data.data);
  }

  async fetchAdplays(start, end) {
    const res = await this.http.get('v1/metrics/ads/plays', {
      params: {
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async interactionsSummary(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v2/metrics/dashboard/interactions/summary', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async fetchKioskInteractionsByKioskAndDate(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/interactions/summaryVenueKiosks', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async interactionsCount(config) {
    const res = await this.http.get('v1/metrics/interactions/count', {
      params: {
        venue_id: config.venueId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async screenviewsCount(config) {
    const res = await this.http.get('v2/metrics/screenviews/count', {
      params: {
        venue_id: config.venueId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async sessionsCount(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/reports/sessions', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async getCampaignsValueByVenue(venueId) {
    if (!venueId) {
      return Promise.reject(new ValidationError('missing required parameter venueId'));
    }
    const endpoint = `v1/metrics/campaignsvalue/${venueId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async topApps(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/applications', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async locationsRouted(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/locationRoutes', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async interactionsByHour(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/interactionsByHour', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async screenviewsByScreen(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/screenviewsByScreen', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async contentWidgets(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/dashboard/content', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async contentSessions(config) {
    // const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/dashboard/content/sessions', {
      params: {
        venue_id: config.venueId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async contentSearches(config) {
    const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/dashboard/content/searches', {
      params: {
        venue_id: config.venueId,
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async cvDashboardData(config) {
    // const { start, end } = convertDateRangePickerDates(config.start, config.end, this.timeZone);
    const res = await this.http.get('v1/metrics/dashboard/cv/cvData', {
      params: {
        venue_id: config.venueId,
        start: config.start,
        end: config.end,
      },
    });

    return Promise.resolve(res.data.data);
  }
}
