import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import campaigns from './modules/campaigns';
import schedules from './modules/schedules';

const state = {
  ads: [],
  activeAdSlots: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    campaigns,
    schedules,
  },
};
