export default [
  {
    path: '/content/languages',
    name: 'content-languages',
    component: () => import('@/pages/content/Languages.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/locations/:id?/:mode?',
    name: 'content-locations',
    component: () => import('@/pages/content/Locations.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/amenities/:id?/:mode?',
    name: 'content-amenities',
    component: () => import('@/pages/content/Amenities.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/events/:id?/:mode?',
    name: 'content-events',
    component: () => import('@/pages/content/Events.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/alerts/:id?/:mode?',
    name: 'content-alerts',
    component: () => import('@/pages/content/Alerts.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/apps',
    name: 'content-apps',
    component: () => import('@/pages/content/Apps.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/promotions/:id?',
    name: 'content-promotions',
    component: () => import('@/pages/content/Promotions.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/synonyms/:id?',
    name: 'content-synonyms',
    component: () => import('@/pages/content/Synonyms.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/overlays',
    name: 'content-overlays',
    component: () => import('@/pages/content/Overlays.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/templates',
    name: 'content-templates',
    component: () => import('@/pages/content/Templates.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/settings/legacyui',
    name: 'settings-legacyui',
    component: () => import('@/pages/settings/LegacyUserInterface.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/selfie/ardemo',
    name: 'selfie-ardemo',
    component: () => import('@/pages/content/ARDemo.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/feeds/:id?/:mode?',
    name: 'content-feeds',
    component: () => import('@/pages/content/Feeds.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/content/media',
    name: 'media-manager',
    component: () => import('@/pages/content/Media.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
