export default [
  {
    path: '/ads/center',
    name: 'ads-center',
    component: () => import( '@/pages/ads/AdCenter.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/ads/campaign-wizard',
    name: 'ads-campaign-wizard',
    component: () => import( '@/pages/ads/CampaignWizard.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/ads/campaigns/:id/schedule',
    name: 'ads-campaigns-schedule',
    component: () => import( '@/pages/ads/CampaignSchedule.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/ads/campaigns/:campaignId/permissions',
    name: 'ads-campaign-permissions',
    component: () => import( '@/pages/ads/CampaignUsers.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/ads/campaigns/:id?/:mode?',
    name: 'ads-campaigns',
    component: () => import( '@/pages/ads/Campaigns.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/ads/calendar',
    name: 'ads-calendar',
    component: () => import( '@/pages/ads/Calendar.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
];
