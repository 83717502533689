import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class StylesResource extends BaseResource {
  async create(newStyle) {
    const res = await this.http.post(`v1/venues/${newStyle.venue_id}/customStyles`, newStyle);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v1/venues/${venueId}/customStyles/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id, venueId) {
    const endpoint = `v1/venues/${venueId}/customStyles/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v1/venues/${venueId}/customStyles`);
    return Promise.resolve(res.data.data);
  }

  async update(updateStyle) {
    if (!updateStyle || !updateStyle.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update style payload'));
    }
    const endpoint = `v1/venues/${updateStyle.venue_id}/customStyles/${updateStyle.id}`;
    const res = await this.http.put(endpoint, updateStyle);

    return Promise.resolve(res.data.data);
  }
}
