import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class LocationsResource extends BaseResource {
  async create(newLocation) {
    const res = await this.http.post('v1/locations', newLocation);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/locations/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/locations/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/locations');

    return Promise.resolve(res.data.data);
  }

  async listVenue(venueId) {
    const res = await this.http.get('v1/locations', {
      params: {
        venue_id: venueId,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async fetchLocationCount(params) {
    const res = await this.http.get('v2/locations/count', {
      params: {
        venue_id: params.venueId,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async listVenueV2(venueId, options) {
    const res = await this.http.get('v2/locations', {
      params: {
        venue_id: venueId,
        page: options.page,
        limit: options.limit
      },
    });

    return Promise.resolve(res.data.data);
  }

  async update(updatedLocation) {
    if (!updatedLocation || !updatedLocation.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update location payload'));
    }
    const endpoint = `v1/locations/${updatedLocation.id}`;
    const res = await this.http.put(endpoint, updatedLocation);

    return Promise.resolve(res.data.data);
  }

  async upload(formData) {
    return this.http.post('v1/uploads', formData);
  }
}
