import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class TemplateVariablesResource extends BaseResource {
  async create(newTemplateVariable) {
    const res = await this.http.post('v1/template-variables', newTemplateVariable);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/template-variables/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/template-variables/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/template-variables');

    return Promise.resolve(res.data.data);
  }

  async fetchByTemplateId(templateId) {
    const all = await this.list();

    return all.filter((templateVariable) => templateVariable.template_id === templateId);
  }

  async update(updatedTemplateVariable) {
    if (!updatedTemplateVariable || !updatedTemplateVariable.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update template variable payload'));
    }
    const endpoint = `v1/template-variables/${updatedTemplateVariable.id}`;
    const res = await this.http.put(endpoint, updatedTemplateVariable);

    return Promise.resolve(res.data.data);
  }
}
