const fetchActiveCampaignsValue = async ({
  commit,
  rootGetters
}) => {
  const venueId = rootGetters['content/venues/activeVenueId'];

  const skyos = rootGetters['app/skyOSService'];

  const campaignValue = await skyos.metrics.getCampaignsValueByVenue(venueId);

  commit('setActiveCampaignsValue', campaignValue);
};

export default {
  fetchActiveCampaignsValue,
};
