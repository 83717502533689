import BaseResource from './base';

export default class HistoryResource extends BaseResource {
  async list(params) {
    const res = await this.http.get('v1/histories', {
      params,
    });

    return Promise.resolve(res.data.data);
  }
}
