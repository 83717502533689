import Vue from 'vue';
import store from '../store';

/**
 * Helper method to format a number given a few configurations such as the separation
 * between thousands and decimals
 *
 * @param number
 * @param decimals
 * @param decPoint
 * @param thousandsSep
 * @returns {*}
 */
export function numberFormat(number, decimals, decPoint, thousandsSep) {
  if (Number.isNaN(number)) {
    return number;
  }

  const negative = number < 0;

  // eslint-disable-next-line no-param-reassign
  if (negative) number *= -1;

  const str = number.toFixed(decimals || 0).toString().split('.');
  const parts = [];

  for (let i = str[0].length; i > 0; i -= 3) {
    parts.unshift(str[0].substring(Math.max(0, i - 3), i));
  }

  str[0] = parts.join(thousandsSep || ',');

  return (negative ? '-' : '') + str.join(decPoint || '.');
}

/**
 * Returns the price passed formatted according to the system configurations for the currency
 *
 * @param price
 * @returns {*}
 */
export function formatPrice(price, currency) {
  try {
    const numberFormatted = numberFormat(
      price,
      currency.decimalDigits,
      currency.decimalSeparator,
      currency.thousandsSeparator,
    );

    if (currency.currencySymbol) {
      const priceSeparator = currency.currencySymbolNumberOfSpaces > 0
        ? ' '.repeat(currency.currencySymbolNumberOfSpaces)
        : '';
      let priceParts = [numberFormatted, priceSeparator, currency.currencySymbol];

      if (currency.currencySymbolPosition === 'left') {
        priceParts = priceParts.reverse();
      }

      return priceParts.join('');
    }
    return numberFormatted;
  } catch (e) {
    return price;
  }
}

/**
 * Returns the currency formatted accordingly
 */
export function formatCurrency(value, currency) {
  // eslint-disable-next-line no-param-reassign
  currency = currency || store.state.app.currency;

  return formatPrice(value, currency);
}

Vue.filter('formatCurrency', formatCurrency);

export default {
  formatPrice,
  numberFormat,
};
