import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class CustomizationsResource extends BaseResource {
  async create(newCustomization) {
    const res = await this.http.post('v1/customizations', newCustomization);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/customizations/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/customizations/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchByVenueId(venueId) {
    const all = await this.list();

    return all.filter((customization) => customization.venue_id === venueId);
  }

  async list() {
    const res = await this.http.get('v1/customizations');

    return Promise.resolve(res.data.data);
  }

  async update(updatedCustomization) {
    if (!updatedCustomization || !updatedCustomization.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update customization payload'));
    }
    const endpoint = `v1/customizations/${updatedCustomization.id}`;
    const res = await this.http.put(endpoint, updatedCustomization);

    return Promise.resolve(res.data.data);
  }
}
