import BaseResource from './base';

export default class RoutesResource extends BaseResource {
  async startRouteRecalculationByKioskId(kioskId) {
    const res = await this.http.get('v1/routes/start', {
      params: {
        kiosk_id: kioskId,
      },
    });

    return Promise.resolve(res.data.data);
  }

  async recalculateWithGraphByKioskId(kioskId) {
    const res = await this.http.get(`v2/kiosks/${kioskId}/routes`);
    if (res.status === 201) {
      return Promise.resolve(res.data?.data);
    }
    return Promise.reject(new Error(res.data?.error || 'unable to recalculate routes'));
  }
}
