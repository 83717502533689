import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class AdSlotsResource extends BaseResource {
  async create(newAdSlot) {
    const res = await this.http.post('v1/adslots', newAdSlot);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/adslots/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/adslots/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/adslots');

    return Promise.resolve(res.data.data);
  }

  async update(updatedAdSlot) {
    if (!updatedAdSlot || !updatedAdSlot.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update adslot payload'));
    }
    const endpoint = `v1/adslots/${updatedAdSlot.id}`;
    const res = await this.http.put(endpoint);

    return Promise.resolve(res.data.data);
  }
}
