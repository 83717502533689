import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class SuitesResource extends BaseResource {
  async create(newSuite, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/map_suites`, newSuite);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/map_suites/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(suiteId, venueId) {
    const endpoint = `v2/venues/${venueId}/map_suites/${suiteId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/map_suites`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedSuite, venueId) {
    if (!updatedSuite || !updatedSuite.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update suite payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/map_suites/${updatedSuite.id}`;
    const res = await this.http.put(endpoint, updatedSuite);

    return Promise.resolve(res.data.data);
  }
}
