import BaseResource from '@/services/skyos/base';

export default class MediaResource extends BaseResource {
  async fetch() {
    const res = await this.http.get('v2/media/assets');
    return Promise.resolve(res.data.data);
  }

  async create(data) {
    const res = await this.http.post('v2/media/assets', data);
    return Promise.resolve(res.data.data);
  }

  async update(data) {
    const res = await this.http.put(`v2/media/assets/${data.id}`, data);
    return Promise.resolve(res.data.data);
  }

  async download(data) {
    if (!data.id) {
      data.id = 0;
    }
    const res = await this.http.get(`v2/media/assets/${data.id}?url=${data.asset_url}`);
    window.open(res.data.data);
    return Promise.resolve(res.data.data);
  }

  async getPermissions(data) {
    const res = await this.http.get(`v2/media/${data.type}/${data.id}/permissions`);
    return Promise.resolve(res.data.data);
  }

  async createDirectory(data) {
    const res = await this.http.post('v2/media/directories', data);
    return Promise.resolve(res.data.data);
  }

  async updateDirectory(data) {
    const res = await this.http.put(`v2/media/directories/${data.id}`, data);
    return Promise.resolve(res.data.data);
  }

  async createPermission(data) {
    const res = await this.http.post('v2/media/permissions', data);
    return Promise.resolve(res.data.data);
  }
}
