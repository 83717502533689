import AdsResource from './ads';
import AdSlotsResource from './adSlots';
import AlertsResource from './alerts';
import AmenitiesResource from './amenities';
import AmenityTypesResource from './amenityTypes';
import APIKeysResource from './apiKeys';
import AuthResource from './auth';
import CampaignsResource from './campaigns';
import CategoriesResource from './categories';
import CommandsResource from './commands';
import PromotionsResource from './promotions';
import CustomizationsResource from './customizations';
import DsThemesResource from './dsThemes';
import EventsResource from './events';
import FeaturesResource from './features';
import HistoriesResource from './histories';
import KiosksResource from './kiosks';
import LanguagesResource from './languages';
import LocationsResource from './locations';
import LocationTypesResource from './locationTypes';
import MembersResource from './members';
import MetricsResource from './metrics';
import OverlaysResource from './overlays';
import OrganizationsResource from './organizations';
import PermissionsResource from './permissions';
import QueuesResource from './queues';
import ReportsResource from './reports';
import RoutesResource from './routes';
import SchedulesResource from './schedules';
import ScreensResource from './screens';
import TemplatesResource from './templates';
import TemplateVariablesResource from './templateVariables';
import UsersResource from './users';
import VenuesResource from './venues';
import HistoryResource from './history';
import SearchResource from './search';
import DiagnosticsResource from './diagnostics';
import SelfiesResource from './selfies';
import FeedsResource from './feeds';
import StyleResource from './styles';
import ObjectResource from './objects';
import DatasetResource from './datasets';
import ComputerVisionResource from './computerVision';
import FloorsResource from './floors';
import MediaResource from './media';
import StairsResource from './stairs';
import LightsResource from './lights';
import BackgroundsResource from './backgrounds';
import SuitesResource from './suites';
import ProxyServersResource from './proxy_servers';
import ContactsResource from './contacts';
import SynonymsResource from './synonyms';

export default class SkyOSService {
  constructor(token) {
    this.ads = new AdsResource(token);
    this.adSlots = new AdSlotsResource(token);
    this.alerts = new AlertsResource(token);
    this.amenities = new AmenitiesResource(token);
    this.amenityTypes = new AmenityTypesResource(token);
    this.apiKeys = new APIKeysResource(token);
    this.auth = new AuthResource(token);
    this.campaigns = new CampaignsResource(token);
    this.categories = new CategoriesResource(token);
    this.commands = new CommandsResource(token);
    this.computerVision = new ComputerVisionResource(token);
    this.promotions = new PromotionsResource(token);
    this.customizations = new CustomizationsResource(token);
    this.dsThemes = new DsThemesResource(token);
    this.events = new EventsResource(token);
    this.features = new FeaturesResource(token);
    this.histories = new HistoriesResource(token);
    this.kiosks = new KiosksResource(token);
    this.locations = new LocationsResource(token);
    this.locationTypes = new LocationTypesResource(token);
    this.members = new MembersResource(token);
    this.metrics = new MetricsResource(token);
    this.overlays = new OverlaysResource(token);
    this.organizations = new OrganizationsResource(token);
    this.permissions = new PermissionsResource(token);
    this.queues = new QueuesResource(token);
    this.reports = new ReportsResource(token);
    this.routes = new RoutesResource(token);
    this.schedules = new SchedulesResource(token);
    this.screens = new ScreensResource(token);
    this.templates = new TemplatesResource(token);
    this.templateVariables = new TemplateVariablesResource(token);
    this.users = new UsersResource(token);
    this.venues = new VenuesResource(token);
    this.history = new HistoryResource(token);
    this.search = new SearchResource(token);
    this.languages = new LanguagesResource(token);
    this.diagnostics = new DiagnosticsResource(token);
    this.selfies = new SelfiesResource(token);
    this.feeds = new FeedsResource(token);
    this.styles = new StyleResource(token);
    this.objects = new ObjectResource(token);
    this.datasets = new DatasetResource(token);
    this.media = new MediaResource(token);
    this.floors = new FloorsResource(token);
    this.stairs = new StairsResource(token);
    this.lights = new LightsResource(token);
    this.backgrounds = new BackgroundsResource(token);
    this.suites = new SuitesResource(token);
    this.proxy_servers = new ProxyServersResource(token);
    this.contacts = new ContactsResource(token);
    this.synonyms = new SynonymsResource(token);
  }
}
