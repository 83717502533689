const state = {
  allKiosks: [],
  activeKiosks: [],
};

const getters = {
  all: (state) => state.allKiosks,
  active: (state) => state.activeKiosks,
  activeKioskIds: (state) => state.activeKiosks.map((kiosk) => kiosk.id),
};

const mutations = {
  setAllKiosks: (state, allKiosks) => {
    state.allKiosks = allKiosks;
  },
  setActiveKiosks: (state, activeKiosks) => {
    state.activeKiosks = activeKiosks;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const kiosks = await skyos.kiosks.list();

    commit('setAllKiosks', kiosks);
  },

  updateActive: async ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    if (!state.allKiosks) {
      commit('setActiveKiosks', []);

      return;
    }

    const filtered = state.allKiosks.filter((kiosk) => kiosk.venue_id === venueId);

    commit('setActiveKiosks', filtered);
  },

  categories: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    await skyos.kiosks.categories(data);
  },

  create: async ({ dispatch, rootGetters }, newKiosk) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.kiosks.create(newKiosk);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve(response);
  },

  edit: async ({ dispatch, rootGetters }, editedKiosk) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.kiosks.update(editedKiosk);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.kiosks.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  recalculateRoutesLegacy: async ({ dispatch, rootGetters }, kioskId) => {
    const skyos = rootGetters['app/skyOSService'];

    const res = await skyos.routes.startRouteRecalculationByKioskId(kioskId);

    await dispatch(
      'app/showSuccess',
      'Route recalculation job submitted!',
      { root: true },
    );

    return Promise.resolve(res);
  },

  recalculateRoutesGraph: async ({ dispatch, rootGetters }, kioskId) => {
    const skyos = rootGetters['app/skyOSService'];

    const res = await skyos.routes.recalculateWithGraphByKioskId(kioskId);

    await dispatch(
      'app/showSuccess',
      'Route recalculation job submitted!',
      { root: true },
    );

    return Promise.resolve(res);
  },

  fetchInvalidRoutes: async ({ rootGetters, state, commit }) => {
    const skyos = rootGetters['app/skyOSService'];

    const promises = state.activeKiosks.map((kiosk) => skyos.kiosks.invalidRoutes(kiosk.id));
    const results = await Promise.all(promises);

    const updated = state.activeKiosks.map((kiosk) => {
      kiosk.invalidRoutes = results.find((result) => result.kiosk.id === kiosk.id);
      return kiosk;
    });

    commit('setActiveKiosks', updated);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
