const state = {
  objects: [],
};

const getters = {
  objects: (state) => state.objects,
};

const mutations = {
  setObjects: (state, objects) => {
    state.objects = objects;
  },
};

const actions = {
  fetchObjects: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const objects = await skyos.objects.list(venueId);

    commit('setObjects', objects);
  },

  create: async ({ rootGetters }, newObject) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.objects.create(newObject);
  },

  edit: async ({ rootGetters }, editedObject) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.objects.update(editedObject, venueId);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.objects.delete(id, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
