const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchAdplaysByVenueAndDate: async ({ rootGetters }, params) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const results = await skyos.metrics.fetchAdplaysByVenueAndDate(
        params.venue_id,
        params.date_start,
        params.date_end,
      );

      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchAdplaysReport: async ({ rootGetters }, payload) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const results = await skyos.reports.adplays({
        start: payload.start,
        end: payload.end,
        venue_id: payload.venue_id,
      });

      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchProgrammaticAdplaysReport: async ({ rootGetters }, payload) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const results = await skyos.reports.programmatic({
        start: payload.start,
        end: payload.end,
        venue_id: payload.venue_id,
      });

      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchProgrammaticData: async ({ rootGetters }, params) => {
    const skyos = rootGetters['app/skyOSService'];
    const venue_id = rootGetters['content/venues/activeVenueId'];
    const results = await skyos.metrics.fetchProgrammaticSummary(params.start, params.end, venue_id);

    return Promise.resolve(results);
  },

  fetchKioskInteractionsReport: async ({ rootGetters }, payload) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const results = await skyos.reports.kioskInteractions({
        start: payload.start,
        end: payload.end,
        venue_id: payload.venue_id,
      });

      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
