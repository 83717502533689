const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  fetchAdHistory: async ({ rootGetters }) => {
    try {
      const skyos = rootGetters['app/skyOSService'];
      const results = await skyos.histories.adHistory({});

      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
