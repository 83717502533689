import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

function regexReplacePii(string) {
  let newString = '';
  newString = string.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/gi, '[EMAIL]');
  // eslint-disable-next-line
  newString = newString.replace(/(\d[\s-]?)?[\(\[\s-]{0,2}?\d{3}[\)\]\s-]{0,2}?\d{3}[\s-]?\d{4}/ig, '[PHONE]');
  return newString;
}

function dataScrub(object) {
  Object.keys(object)
    .forEach((key) => {
      if (typeof object[key] === 'object' && object[key] !== null) {
        object[key] = dataScrub(object[key]);
      } else if (typeof object[key] === 'string') {
        object[key] = regexReplacePii(object[key]);
      }
    });
  return object;
}

export default (router) => {
  if (!import.meta.env.VITE_USE_DISABLE_SENTRY) {
    Sentry.init({
      dsn: 'https://3deed4463a2540638ea670f779c1be7b@o133726.ingest.sentry.io/295440',
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'portal.roveiq.com', /^\//],
        }),
      ],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      beforeSend(event) {
        const newEvent = dataScrub(event);
        return newEvent;
      },
      beforeBreadcrumb(breadcrumb) {
        return breadcrumb;
      },
    });
  } else {
    // console.log('sentry disabled');
  }
};
