
<div>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    persistent
  >
    <v-card>
      <v-toolbar
        color="red darken-1"
        class="text-h6"
        dark
      >
        Warning: This is permanent!
      </v-toolbar>
      <v-card-text>
        <div class="text-h5 pa-3 text-center">
          {{ prompt }}
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions
        class="justify-space-around align-content-center mx-12"
      >
        <v-btn
          color="accent"
          outlined
          @click="cancelCallback"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          color="red darken-1"
          outlined
          @click="confirmCallback"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
