const state = {
  backgrounds: [],
};

const getters = {
  backgrounds: (state) => state.backgrounds,
};

const mutations = {
  setBackgrounds(state, backgrounds) {
    state.backgrounds = backgrounds;
  },
};

const actions = {
  fetchBackgrounds: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const backgrounds = await skyos.backgrounds.list(venueId);

    commit('setBackgrounds', backgrounds);
  },

  create: async ({ rootGetters }, newBackground) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.backgrounds.create(newBackground, venueId);
  },

  edit: async ({ rootGetters }, editedBackground) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.backgrounds.update(editedBackground, venueId);
  },

  delete: async ({ rootGetters }, backgroundId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.backgrounds.delete(backgroundId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
