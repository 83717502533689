export default [
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/pages/admin/Users.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/admin/adslots',
    name: 'admin-adslots',
    component: () => import('@/pages/admin/AdSlots.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/history',
    name: 'admin-history',
    component: () => import('@/pages/admin/History.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/admin/venue-report',
    name: 'admin-venue-report',
    component: () => import('@/pages/admin/VenueReport.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/admin/create-venue',
    name: 'admin-create-venue',
    component: () => import('@/pages/admin/CreateVenue.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/admin/languages',
    name: 'admin-languages',
    component: () => import('@/pages/admin/Languages.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/admin/diagnostics',
    name: 'admin-diagnostics',
    component: () => import('@/pages/admin/Diagnostics.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/diagnostics/:id',
    name: 'admin-diagnostic-report',
    component: () => import('@/pages/admin/DiagnosticReport.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/templates',
    name: 'admin-templates',
    component: () => import('@/pages/admin/Templates.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/templates/:id',
    name: 'admin-template-report',
    component: () => import('@/pages/admin/TemplateForm.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/plans',
    name: 'admin-plans',
    component: () => import('@/pages/admin/Plans.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/admin/proxy',
    name: 'admin-proxy',
    component: () => import('@/pages/admin/ProxyServer.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
