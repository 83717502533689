import _ from 'lodash';
import defaultCategory from '../../../utils/defaults/category';

const state = {
  locationCategories: [],
  amenityCategories: [],
  screenCategories: [],
  kioskCategories: [],
};

const getters = {
  locationCategories: (state) => state.locationCategories,
  amenityCategories: (state) => state.amenityCategories,
  screenCategories: (state) => state.screenCategories,
  screenCategoryById: (state) => (screenCategoryId) => state.screenCategories.find(
    (screenCategory) => screenCategory.id === screenCategoryId,
  ),
  kioskCategories: (state) => state.kioskCategories,
  kioskCategoryById: (state) => (kioskCategoryId) => state.kioskCategories.find(
    (kioskCategory) => kioskCategory.id === kioskCategoryId,
  ),
  getScreensByScreenCategoryId: (state, getters) => (screenCategoryId) => {
    const screenCategory = getters.screenCategoryById(screenCategoryId);
    if (!screenCategory) {
      return [];
    }
    return screenCategory.screens;
  },
  getScreensByKioskCategoryId: (state, getters) => (kioskCategoryId) => {
    const kioskCategory = getters.kioskCategoryById(kioskCategoryId);
    if (!kioskCategory) {
      return [];
    }
    const screens = [];
    kioskCategory.kiosks.forEach((kiosk) => {
      if (kiosk.Screens?.length > 0) {
        screens.push(...kiosk.Screens);
      }
    });
    return screens;
  },
};

const mutations = {
  setLocationCategories: (state, locationCategories) => {
    state.locationCategories = locationCategories;
  },
  setAmenityCategories: (state, amenityCategories) => {
    state.amenityCategories = amenityCategories;
  },
  setScreenCategories: (state, screenCategories) => {
    state.screenCategories = screenCategories;
  },
  setKioskCategories: (state, kioskCategories) => {
    state.kioskCategories = kioskCategories;
  },
};

const actions = {
  fetchLocationCategories: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const categories = await skyos.categories.list({
      venue_id: venueId,
      type: 'location',
    });

    const safeCategories = categories.map((category) => {
      if (!category.settings) {
        category.settings = _.clone(defaultCategory.settings);
      }
      if (!category.settings.bgcolor) {
        category.settings.bgcolor = '';
      }
      if (!category.settings.fontcolor) {
        category.settings.fontcolor = '';
      }
      if (!category.settings.showOnDirectory) {
        category.settings.showOnDirectory = false;
      }
      return category;
    });

    commit('setLocationCategories', safeCategories);
  },

  fetchAmenityCategories: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const categories = await skyos.categories.list({
      venue_id: venueId,
      type: 'amenity',
    });
    commit('setAmenityCategories', categories);
  },

  fetchScreenCategories: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const categories = await skyos.categories.list({
      venue_id: venueId,
      type: 'screen',
    });
    commit('setScreenCategories', categories);
  },

  fetchKioskCategories: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const categories = await skyos.categories.list({
      venue_id: venueId,
      type: 'kiosk',
    });
    commit('setKioskCategories', categories);
  },

  create: async ({ rootGetters }, newCategory) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.categories.create(newCategory);
  },

  edit: async ({ rootGetters }, editedCategory) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.categories.update(editedCategory);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.categories.delete(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
