export default {
  setIsAuthenticated: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  },

  setToken: (state, token) => {
    state.token = token;
  },

  setUser: (state, user) => {
    state.user = user;
  },

  setMimicEnabled: (state, enabled) => {
    state.mimicEnabled = enabled;
  },

  setMimicToken: (state, token) => {
    state.mimicToken = token;
  },

  setMimicUser: (state, user) => {
    state.mimicUser = user;
  },

  setMfaEmail: (state, email) => {
    state.mfaEmail = email;
  },

  setClientId: (state, clientId) => {
    state.clientId = clientId;
  },

  setRememberDays: (state, rememberDays) => {
    state.rememberDays = rememberDays;
  },

  setUserPermissions: (state, userPermissions) => {
    state.userPermissions = userPermissions;
  },
};
