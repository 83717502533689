import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class FloorsResource extends BaseResource {
  async create(newFloor, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/floors`, newFloor);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/floors/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(floorId, venueId) {
    const endpoint = `v2/venues/${venueId}/floors/${floorId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/floors`);

    return Promise.resolve(res.data.data);
  }

  async update(updatedFloor, venueId) {
    if (!updatedFloor || !updatedFloor.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update floor payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/floors/${updatedFloor.id}`;
    const res = await this.http.put(endpoint, updatedFloor);

    return Promise.resolve(res.data.data);
  }
}
