export default [
  {
    path: '/displays/kiosks/:id?/:mode?',
    name: 'displays-kiosks',
    component: () => import( '@/pages/displays/Kiosks.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/displays/screens/:id/apps',
    name: 'displays-screens-apps',
    component: () => import(/* webpackChunkName: "displays-screens-apps" */ '@/pages/content/Apps.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/displays/screens/:id?/:mode?',
    name: 'displays-screens',
    component: () => import( '@/pages/displays/Screens.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
