import adminRoutes from './admin.routes';
import adsRoutes from './ads.routes';
import authRoutes from './auth.routes';
import contentRoutes from './content.routes';
import dashboardsRoutes from './dashboards.routes';
import displaysRoutes from './displays.routes';
import errorRoutes from './error.routes';
import mapsRoutes from './maps.routes';
import reportsRoutes from './reports.routes';
import settingsRoutes from './settings.routes';
import utilityRoutes from './utility.routes';

export default [
  {
    path: '/tos',
    name: 'terms-of-service',
    component: () => import( '@/pages/Tos.vue'),
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import( '@/pages/Privacy.vue'),
    meta: {
      layout: 'auth',
      guest: true,
    },
  },

  ...adminRoutes,
  ...adsRoutes,
  ...authRoutes,
  ...contentRoutes,
  ...dashboardsRoutes,
  ...displaysRoutes,
  ...errorRoutes,
  ...mapsRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
  ...utilityRoutes,
];
