export default {
  email: (value) => {
    if (!value) return true;
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(value) || 'Invalid e-mail.';
  },

  zip: (value) => {
    if (!value) return true;
    const pattern = /^\d{5}$/;

    return pattern.test(value) || 'Invalid zip code.';
  },

  latitude: (value) => {
    if (!value) return true;
    let valueToTest;

    if (typeof value === 'string') {
      valueToTest = parseFloat(value);
    } else {
      valueToTest = value;
    }

    const isValid = Number.isFinite(valueToTest) && Math.abs(valueToTest) <= 90;

    return isValid || 'Invalid latitude.';
  },

  longitude: (value) => {
    if (!value) return true;
    let valueToTest;

    if (typeof value === 'string') {
      valueToTest = parseFloat(value);
    } else {
      valueToTest = value;
    }

    const isValid = Number.isFinite(valueToTest) && Math.abs(valueToTest) <= 180;

    return isValid || 'Invalid longitude.';
  },

  phone: (value) => {
    if (!value) return true;
    const pattern = /^(\([0-9]{3}\) ?|[0-9]{3}(-|.)?)[0-9]{3}(-|.)?[0-9]{4}$/;

    return pattern.test(value) || 'Invalid phone number.';
  },

  number: (value) => {
    if (!value) return true;
    const pattern = /^-?\d*\.?\d*$/;

    return pattern.test(value) || 'Invalid Number.';
  },

  positiveInteger: (value) => {
    if (!value) return true;
    if (typeof value === 'number') {
      return value >= 0;
    }
    const parsed = parseInt(value, 10);

    return parsed >= 0;
  },

  required: (value) => !!value || 'Required.',

  password: (value) => {
    if (!value) return true;
    if (value.length < 8) {
      return 'Password must be more than 8 characters.';
    }

    return true;
  },

  sms: (value) => {
    if (!value) return true;
    if (value.length > 110) {
      return 'Cannot be over 110 characters.';
    }
    return true;
  },

  url: (value) => {
    if (!value) return true;
    const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    return pattern.test(value) || 'Invalid url. Example: http://www.example.com';
  },

  time24: (value) => {
    if (!value) return true;
    const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    return pattern.test(value) || 'Invalid 24 hour time';
  },

  zoom: (value) => {
    if (!value) return true;

    return (value >= 0 && value <= 24) || 'Zoom must be a number between 0 and 24.';
  },

  roveZoom: (value) => {
    if (!value) return true;

    return (value >= 1 && value <= 100) || 'Zoom must be a number between 1 and 100.';
  },

  mfaCode: (value) => {
    if (!value) return true;
    const pattern = /^[0-9]{6}$/;

    return pattern.test(value) || 'Invalid code.';
  },

  accuracy: (value) => {
    if (!value) return true;
    return (value >= 50 && value <= 200) || 'Accuracy must be between 50 and 200';
  },

  esBoost: (value) => {
    if (!value) return true;
    return (value >= 0.1 && value <= 10) || 'Boost must be between 0.1 and 10';
  },

  esScoreFloor: (value) => {
    if (!value) return true;
    return (value >= 0.5 && value <= 5) || 'Boost must be between 0.5 and 5';
  },
};
