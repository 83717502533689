import Vue from 'vue';

function install(Vue) {
// eslint-disable-next-line func-names
  Vue.prototype.$deleteConfirm = function (deleteConfirmConfig) {
    return this.$store.dispatch('content/openDeleteConfirmDialog', deleteConfirmConfig);
  };
}

Vue.use(install);
