<template>
  <v-dialog
    v-model="adBlockerDialog"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        <v-icon color="warning" class="mr-1">
          mdi-alert
        </v-icon>
        Ad Blocker Detected
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            We've detected that you're using an ad blocker. We get it, we love ad blockers too. However, most ad blockers out there aren't very careful about how they operate and block anything with the word "ad" or "advertisement" in it.  Since we allow you to create and manage ads, most ad blockers really mess with our portal.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            So for the best experience, please whitelist our site or temporarily disable your ad blocker. Otherwise, some core functions just won't work at all.
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="handleOK"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint max-len: 0  */
export default {
  data: () => ({
    localStorageKey: 'ad-block-terms-accepted',
    adBlockerDialog: false,
  }),

  async beforeMount() {
    const userAccepted = this.getUserAcceptance();
    if (!userAccepted) {
      const res = await this.detectAdBlock();
      if (res) {
        this.triggerDialog();
      }
    }
  },

  methods: {
    async detectAdBlock() {
      let adBlockEnabled = false;
      const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/show_ads.js';
      try {
        await fetch(new Request(googleAdUrl), {
          method: 'HEAD',
          mode: 'no-cors',
        });
      } catch (e) {
        adBlockEnabled = true;
      }
      return adBlockEnabled;
    },

    getUserAcceptance() {
      const value = localStorage.getItem(this.localStorageKey);
      return value === 'true';
    },

    setUserAcceptance() {
      localStorage.setItem(this.localStorageKey, true);
    },

    triggerDialog() {
      this.adBlockerDialog = true;
    },

    handleOK() {
      this.setUserAcceptance();
      this.adBlockerDialog = false;
    },
  },
};
</script>

<style scoped>

</style>
