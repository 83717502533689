import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class PermissionsResource extends BaseResource {
  async create(newPermission) {
    const res = await this.http.post('v1/permissions', newPermission);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/permissions/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/permissions/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchByUserId(userId) {
    const endpoint = `v1/users/${userId}/permissions`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(opts) {
    if (!opts || !opts.resourceType || !opts.resourceId) {
      return Promise.reject(new ValidationError('missing required field in permissions list call'));
    }
    const endpoint = `v1/permissions/${opts.resourceType}/${opts.resourceId}`;

    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async update(updatedPermission) {
    if (!updatedPermission || !updatedPermission.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update permission payload'));
    }
    const endpoint = `v1/permissions/${updatedPermission.id}`;
    const res = await this.http.put(endpoint, updatedPermission);

    return Promise.resolve(res.data.data);
  }
}
