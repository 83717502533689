export default class S3Service {
  constructor(http) {
    this.http = http;
  }

  async presignedUrl(bucketName, filename, filetype) {
    const res = await this.http.get('auth/s3-signed-url', {
      params: {
        bucketName,
        filename,
        filetype,
      },
    });

    if (res && res.data && res.data.data && res.data.data.signedUrl) {
      return Promise.resolve(res.data.data.signedUrl);
    }

    return Promise.reject(new Error('no signedUrl returned'));
  }

  uploadFile(file, bucketname) {
    return new Promise(async (resolve, reject) => { // eslint-disable-line
      const fileNamePieces = file.name.split('.');
      const extension = fileNamePieces.pop();

      if ((extension === 'gltf' || extension === 'glb') && file.type === '') {
        const reader = new FileReader();

        reader.readAsText(file, 'UTF-8');

        reader.onload = async (evt) => {
          const myblob = new Blob([evt.target.result], {
            type: 'text/plain',
          });
          const newFile = new File([myblob], file.name, {
            type: 'text/plain',
          });
          const url = await this.presignedUrl(bucketname, newFile.name, newFile.type);

          const headers = {
            'Content-Type': newFile.type,
            'x-amz-acl': 'public-read',
          };

          await this.http.put(url, newFile, { headers });

          const pieces = url.split('?');

          resolve(pieces[0]);
        };
      } else {
        const url = await this.presignedUrl(bucketname, file.name, file.type);

        const headers = {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read',
        };

        await this.http.put(url, file, {
          headers,
          progress(e) {
            this.handleProgress(e);
          },
        });

        const pieces = url.split('?');

        resolve(pieces[0]);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleProgress(e) {
    const progress = parseInt(((e.loaded / e.total) * 100), 10);

    const event = new CustomEvent('uploadProgress', { detail: progress });

    document.dispatchEvent(event);
  }
}
