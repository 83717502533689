export default [
  {
    icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard/analytics',
  },
  {
    icon: 'mdi-monitor-multiple',
    key: '',
    text: 'Displays',
    regex: /^\/displays/,
    items: [
      {
        icon: 'mdi-cellphone', key: '', text: 'Kiosks', link: '/displays/kiosks', venueLevel: true,
      },
      {
        icon: 'mdi-monitor', key: '', text: 'Screens', link: '/displays/screens', venueLevel: true,
      },
    ],
  },
  {
    icon: 'mdi-image-multiple-outline',
    key: '',
    text: 'Ads',
    regex: /^\/ads/,
    items: [
      {
        icon: 'mdi-assistant', key: '', text: 'Ad Center', link: '/ads/center',
      },
      {
        icon: 'mdi-select-group', key: '', text: 'Campaigns', link: '/ads/campaigns',
      },
      {
        icon: 'mdi-calendar', key: '', text: 'Ad Calendar', link: '/ads/calendar',
      },
    ],
  },
  {
    icon: 'mdi-database',
    key: '',
    text: 'Content',
    regex: /^\/content/,
    items: [
      {
        icon: 'mdi-view-quilt', key: '', text: 'Templates', link: '/content/templates', venueLevel: true,
      },
      {
        icon: 'mdi-map-marker-outline', key: '', text: 'Locations', link: '/content/locations', venueLevel: true,
      },
      {
        icon: 'mdi-human-male-female', key: '', text: 'Amenities', link: '/content/amenities', venueLevel: true,
      },
      {
        icon: 'mdi-calendar', key: '', text: 'Events', link: '/content/events', venueLevel: true,
      },
      {
        icon: 'mdi-folder', key: '', text: 'Media Files', link: '/content/media', venueLevel: true,
      },
      {
        icon: 'mdi-alert', key: '', text: 'Alerts', link: '/content/alerts', venueLevel: true,
      },
      {
        icon: 'mdi-apps', key: '', text: 'Apps', link: '/content/apps', venueLevel: true,
      },
      {
        icon: 'mdi-account-box', key: '', text: 'Selfie Overlays', link: '/content/overlays', venueLevel: true,
      },
      {
        icon: 'mdi-cog', key: '', text: 'Languages', link: '/content/languages', venueLevel: true,
      },
      {
        icon: 'mdi-rss', key: '', text: 'Feeds', link: '/content/feeds', venueLevel: true,
      },
      {
        icon: 'mdi-ticket-percent', key: '', text: 'Promotions', link: '/content/promotions', venueLevel: true,
      },
      {
        icon: 'mdi-magnify', key: '', text: 'Search Synonyms', link: '/content/synonyms', venueLevel: true,
      },
    ],
  },
  {
    icon: 'mdi-map',
    key: '',
    text: 'Maps',
    regex: /^\/maps/,
    items: [
      {
        icon: 'mdi-pencil', key: '', text: 'Editor', link: '/maps/editor', venueLevel: true, roveMap: true,
      },
      {
        icon: 'mdi-map', key: '', text: 'Map Config', link: '/maps/config',
      },
      {
        icon: 'mdi-layers-outline', key: '', text: 'Custom Styles', link: '/maps/custom-styles',
      },
      {
        icon: 'mdi-cog', key: '', text: 'Custom 3D Objects', link: '/maps/custom-objects',
      },
      {
        icon: 'mdi-layers-outline', key: '', text: 'GeoJSON Datasets', link: '/maps/geojson-datasets', roveMap: true, isAdmin: true,
      },
      {
        icon: 'mdi-floor-plan', key: '', text: 'Floors', link: '/maps/floors', venueLevel: true, roveMap: true,
      },
      {
        icon: 'mdi-stairs', key: '', text: 'Floor Transitions', link: '/maps/stairs', venueLevel: true, roveMap: true, isAdmin: true,
      },
      {
        icon: 'mdi-lightbulb-outline', key: '', text: 'Lighting', link: '/maps/lighting', venueLevel: true, roveMap: true,
      },
      {
        icon: 'mdi-image-multiple-outline', key: '', text: 'Backgrounds', link: '/maps/backgrounds', venueLevel: true, roveMap: true, isAdmin: true,
      },
    ],
  },
  {
    icon: 'mdi-finance',
    key: '',
    text: 'Reports',
    regex: /^\/reports/,
    items: [
      {
        icon: 'mdi-chart-timeline-variant', key: '', text: 'Interactions', link: '/reports/interactions',
      },
      {
        icon: 'mdi-eye-outline', key: '', text: 'Screenviews', link: '/reports/screenviews',
      },
      {
        icon: 'mdi-chart-box-outline', key: '', text: 'Ad Plays', link: '/reports/plays',
      },
      {
        icon: 'mdi-chart-box-outline', key: '', text: 'Contacts', link: '/reports/contacts',
      },
    ],
  },
  {
    icon: 'mdi-cog',
    key: '',
    text: 'Settings',
    regex: /^\/settings/,
    items: [
      {
        icon: 'mdi-account-group-outline', key: '', text: 'Organizations', link: '/settings/organizations',
      },
      {
        icon: 'mdi-city', key: '', text: 'Venue', link: '/settings/venue', venueLevel: true,
      },
      {
        icon: 'mdi-connection', key: '', text: 'Integrations', link: '/settings/integrations',
      },
      {
        icon: 'mdi-cellphone', key: '', text: 'Mobile', link: '/settings/mobile',
      },
      {
        icon: 'mdi-web', key: '', text: 'Web Embed', link: '/settings/web-embed',
      },
    ],
  },
];
