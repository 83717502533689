export default {
  setApplicationIsLoading: (state, isLoading) => {
    state.applicationIsLoading = isLoading;
  },
  setDeleteConfirmConfig: (state, deleteConfirmConfig) => {
    Object.keys(deleteConfirmConfig)
      .forEach((key) => {
        state[key] = deleteConfirmConfig[key];
      });
  },
};
