export default {
  common: {
    add: 'Ajouter',
    cancel: 'Annuler',
    description: 'Description',
    delete: 'Supprimer',
    title: 'Titre',
    save: 'Enregistrer',
    faq: 'FAQ',
    contact: 'Contactez-nous',
    tos: 'Conditions d\'utilisation',
    policy: 'Politique de confidentialité',
  },
  board: {
    titlePlaceholder: 'Entrez un titre pour cette carte',
    deleteDescription: 'Voulez-vous vraiment supprimer cette carte?',
    editCard: 'Modifier la carte',
    deleteCard: 'Supprimer la carte',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTS',
      DONE: 'FAIT',
    },
  },
  chat: {
    online: 'Utilisateurs en ligne ({count})',
    addChannel: 'Ajouter une chaîne',
    channel: 'Channel | Canaux',
    message: 'Message',
  },
  email: {
    compose: 'Rédiger un e-mail',
    send: 'Envoyer',
    subject: 'Sujet',
    labels: 'Étiquettes',
    emptyList: 'Liste de diffusion vide',
    inbox: 'Boîte de réception',
    sent: 'Envoyé',
    drafts: 'Brouillons',
    starred: 'Favoris',
    trash: 'Corbeille',
    work: 'Travail',
    invoice: 'Facture',
  },
  todo: {
    addTask: 'Ajouter une tâche',
    tasks: 'Tâches',
    completed: 'Terminé',
    labels: 'Étiquettes',
  },
  dashboard: {
    activity: 'Activité',
    weeklySales: 'Ventes hebdomadaires',
    sales: 'Ventes',
    recentOrders: 'Commandes récentes',
    sources: 'Sources de trafic',
    lastweek: 'vs la semaine dernière',
    orders: 'Commandes',
    customers: 'Clients',
    tickets: 'Billets de soutien',
    viewReport: 'Voir le rapport',
  },
  usermenu: {
    profile: 'Profil',
    signin: 'Connexion',
    dashboard: 'Tableau de bord',
    signout: 'Déconnexion',
  },
  error: {
    notfound: 'Page introuvable',
    other: 'Une erreur s\'est produite',
  },
  check: {
    title: 'Définir un nouveau mot de passe',
    backtosign: 'Retour à la connexion',
    newpassword: 'Nouveau mot de passe',
    button: 'Définissez un nouveau mot de passe et connectez-vous',
    error: 'Le lien d\'action n\'est pas valide',
    verifylink: 'Vérification du lien ...',
    verifyemail: 'Vérification de l\'adresse e-mail ...',
    emailverified: 'Email verifié! Redirection ...',
  },
  forgot: {
    title: 'Mot de passe oublié?',
    subtitle: 'Entrez l\'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
    email: 'Courriel',
    button: 'Demander la réinitialisation du mot de passe',
    backtosign: 'Retour à la connexion',
  },
  login: {
    title: 'Connexion',
    email: 'Courriel',
    password: 'Mot de passe',
    button: 'Connexion',
    orsign: 'Ou connectez-vous avec',
    forgot: 'mot de passe oublié?',
    noaccount: 'Vous n\'avez pas de compte?',
    create: 'Créez-en un ici',
    error: 'La combinaison email / mot de passe n\'est pas valide',
  },
  register: {
    title: 'Créer un compte',
    name: 'Nom complet',
    email: 'Courriel',
    password: 'Mot de passe',
    button: 'Créer un compte',
    orsign: 'Ou inscrivez-vous avec',
    agree: 'En vous inscrivant, vous acceptez le',
    account: 'Vous avez déjà un compte?',
    signin: 'Connexion',
  },
  utility: {
    maintenance: 'En maintenance',
  },
  faq: {
    call: 'Vous avez d\'autres questions? Veuillez contacter',
  },
  menu: {
    search: 'Recherche (appuyez sur \'ctrl + /\' pour faire la mise au point)',
    dashboard: 'Tableau de bord',
    logout: 'Déconnexion',
    profile: 'Profil',
    blank: 'Page vierge',
    pages: 'Pages',
    others: 'Autres',
    email: 'Courriel',
    chat: 'Chat',
    todo: 'Todo',
    board: 'Comité de travail',
    users: 'Utilisateurs',
    usersList: 'Liste',
    usersEdit: 'Modifier',
    auth: 'Pages d\'authentification',
    authLogin: 'Connexion / Connexion',
    authRegister: 'Inscription / Inscription',
    authVerify: 'Vérifier l\'e-mail',
    authForgot: 'Mot de passe oublié',
    authReset: 'Réinitialiser le mot de passe',
    errorPages: 'Pages d\'erreur',
    errorNotFound: 'Non trouvé / 404',
    errorUnexpected: 'Inattendu / 500',
    utilityPages: 'Pages utilitaires',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Bientôt',
    utilityHelp: 'FAQ / Aide',
    levels: 'Niveaux de menu',
    disabled: 'Menu désactivé',
    docs: 'Docs',
    feedback: 'Commentaires',
    support: 'Soutien',
  },
  $vuetify: {
    badge: 'Badge',
    close: 'Fermer',
    dataIterator: {
      noResultsText: 'Aucun enregistrement correspondant trouvé',
      loadingText: 'Chargement des éléments ...',
    },
    dataTable: {
      itemsPerPageText: 'Lignes par page:',
      ariaLabel: {
        sortDescending: 'Tri par ordre décroissant.',
        sortAscending: 'Tri par ordre croissant.',
        sortNone: 'Pas trié.',
        activateNone: 'Activez pour supprimer le tri.',
        activateDescending: 'Activez pour trier par ordre décroissant.',
        activateAscending: 'Activez pour trier par ordre croissant.',
      },
      sortBy: 'Trier par',
    },
    dataFooter: {
      itemsPerPageText: 'Articles par page:',
      itemsPerPageAll: 'Tout',
      nextPage: 'Page suivante',
      prevPage: 'Page précédente',
      firstPage: 'Première page',
      lastPage: 'Dernière page',
      pageText: '{0} - {1} sur {2}',
    },
    datePicker: {
      itemsSelected: '{0} sélectionné',
      nextMonthAriaLabel: 'Le mois prochain',
      nextYearAriaLabel: 'L\'année prochaine',
      prevMonthAriaLabel: 'Mois précédent',
      prevYearAriaLabel: 'Année précédente',
    },
    noDataText: 'Aucune donnée disponible',
    carousel: {
      prev: 'Visuel précédent',
      next: 'Visuel suivant',
      ariaLabel: {
        delimiter: 'Diapositive de carrousel {0} sur {1}',
      },
    },
    calendar: {
      moreEvents: '{0} plus',
    },
    fileInput: {
      counter: '{0} fichiers',
      counterSize: '{0} fichiers ({1} au total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Navigation par pagination',
        next: 'Page suivante',
        previous: 'Page précédente',
        page: 'Aller à la page {0}',
        currentPage: 'Page actuelle, page {0}',
      },
    },
  },
};
