
<div class="previewer-container" :class="containerClassObj">
  <v-system-bar
    color="blue darken-4"
    dark
    style="border-radius: 5px 5px 0 0;"
  >
    <v-icon @click="refresh">
      mdi-refresh
    </v-icon>
    <v-tooltip
      v-if="labelShortened"
      top
    >
      <template #activator="{ on, attrs }">
        <span
          class="preview-label"
          :class="previewLabelClassObj"
          v-bind="attrs"
          v-on="on"
        >
          {{ labelText }}
        </span>
      </template>
      <span>{{ previewLabel }}</span>
    </v-tooltip>
    <span
      v-else
      class="preview-label"
      :class="previewLabelClassObj"
    >
      {{ labelText }}
    </span>
    <v-spacer />
    <v-icon @click="toggleLarge">
      {{ growShrinkIcon }}
    </v-icon>
    <v-icon @click="close">
      mdi-close
    </v-icon>
  </v-system-bar>

  <div class="kiosk" :class="classObj" :style="transformSize">
    <iframe
      ref="frame"
      :width="screenWidth"
      :height="screenHeight"
      sandbox="allow-same-origin allow-scripts allow-forms"
      :src="url"
      style="border: none; margin-top: 2%; margin-left: 1.3%;"
      allow="camera"
    />
  </div>
</div>
