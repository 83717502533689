const state = {
  floors: [],
};

const getters = {
  floors: (state) => state.floors,
};

const mutations = {
  setFloors(state, floors) {
    state.floors = floors;
  },
};

const actions = {
  fetchFloors: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const floors = await skyos.floors.list(venueId);

    commit('setFloors', floors);
  },

  create: async ({ rootGetters }, newFloor) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.floors.create(newFloor, venueId);
  },

  edit: async ({ rootGetters }, editedFloor) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.floors.update(editedFloor, venueId);
  },

  delete: async ({ rootGetters }, floorId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.floors.delete(floorId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
