import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class AdsResource extends BaseResource {
  async fetchByCampaignId(campaignId) {
    const endpoint = `v1/campaigns/${campaignId}/advertisements`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(campaignId, adId) {
    const endpoint = `v1/campaigns/${campaignId}/advertisements/${adId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchStats(campaignId, adId) {
    const endpoint = `v1/campaigns/${campaignId}/advertisements/${adId}/stats`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async create(campaignId, newAd) {
    const endpoint = `v1/campaigns/${campaignId}/advertisements`;
    const res = await this.http.post(endpoint, newAd);

    return Promise.resolve(res.data.data);
  }

  async update(campaignId, updatedAd) {
    if (!updatedAd || !updatedAd.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update ad payload'));
    }
    const endpoint = `v1/campaigns/${campaignId}/advertisements/${updatedAd.id}`;
    const res = await this.http.put(endpoint, updatedAd);

    return Promise.resolve(res.data.data);
  }

  async delete(campaignId, adId) {
    const endpoint = `v1/campaigns/${campaignId}/advertisements/${adId}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async inventory(screenId, start, end) {
    const endpoint = `v2/screens/${screenId}/inventory`;
    const res = await this.http.get(endpoint, {
      params: {
        start,
        end,
      },
    });

    return Promise.resolve(res.data.data);
  }
}
