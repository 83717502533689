export default [
  {
    path: '/reports/interactions',
    name: 'reports-interactions',
    component: () => import( '@/pages/reports/Interactions.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/reports/screenviews',
    name: 'reports-screenviews',
    component: () => import(/* webpackChunkName: "reports-interactions" */ '@/pages/reports/Screenviews.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/reports/plays',
    name: 'reports-plays',
    component: () => import( '@/pages/reports/Plays.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/reports/contacts',
    name: 'reports-contacts',
    component: () => import( '@/pages/reports/Contacts.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
