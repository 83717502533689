const state = {
  venue: [],
  campaign: [],
};

const getters = {
  allVenue: (state) => state.venue,
  allCampaign: (state) => state.campaign,
};

const mutations = {
  setVenue: (state, venuePermissions) => {
    state.venue = venuePermissions;
  },

  setCampaign: (state, campaignPermissions) => {
    state.campaign = campaignPermissions;
  },
};

const actions = {
  fetchVenuePermissions: async ({ commit, rootGetters }, venueId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venuePermissions = await skyos.permissions.list({
      resourceType: 'venues',
      resourceId: venueId,
    });

    commit('setVenue', venuePermissions);

    return Promise.resolve(venuePermissions);
  },

  fetchCampaignPermissions: async ({ commit, rootGetters }, campaignId) => {
    const skyos = rootGetters['app/skyOSService'];
    const campaignPermissions = await skyos.permissions.list({
      resourceType: 'campaigns',
      resourceId: campaignId,
    });

    commit('setCampaign', campaignPermissions);

    return Promise.resolve(campaignPermissions);
  },

  create: async ({ rootGetters }, newPermission) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.permissions.create(newPermission);
  },

  update: async ({ rootGetters }, newPermission) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.permissions.update(newPermission);
  },

  delete: async ({ rootGetters }, permissionId) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.permissions.delete(permissionId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
