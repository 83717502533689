import axios from 'axios';
import S3Service from './s3';

export default class AWSService {
  constructor(token) {
    this.baseURL = 'https://api.roveiq.com/v1';

    if (import.meta.env.VITE_USE_API) {
      this.baseURL = `${import.meta.env.VITE_USE_API}/v1`;
    }

    this.http = axios.create({
      baseURL: this.baseURL,
      timeout: 60000,
      headers: {
        auth_token: token,
        'X-Rove-Request': Math.floor(Math.random() * 10000),
      },
    });
    this.apiToken = token;
    this.s3 = new S3Service(this.http);
  }
}
