export default [
  {
    path: '/settings/integrations',
    name: 'settings-integrations',
    component: () => import( '@/pages/settings/Integrations.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/settings/organizations/:id?',
    name: 'settings-organizations',
    component: () => import(/* webpackChunkName: "settings-organization" */ '@/pages/settings/Organizations.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/settings/members/:id?',
    name: 'settings-members',
    component: () => import(/* webpackChunkName: "settings-members" */ '@/pages/settings/Members.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: false,
    },
  },
  {
    path: '/settings/venue/:tab?/:subTab?',
    name: 'settings-venue',
    component: () => import( '@/pages/settings/Venue.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/settings/permissions/:id?/:mode?',
    name: 'settings-venue-permissions',
    component: () => import( '@/pages/settings/VenueUsers.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/settings/mobile',
    name: 'mobile-settings',
    component: () => import( '@/pages/settings/Mobile.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/settings/web-embed',
    name: 'web-embed',
    component: () => import( '@/pages/settings/WebEmbed.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
