export default {
  id: 0,
  venue_id: 0,
  name: '',
  type: '',
  highlight: false,
  highlight_rank: 0,
  settings: {
    bgcolor: '',
    fontcolor: '',
    showOnDirectory: false,
  },
};
