import BaseResource from './base';

export default class HistoriesResource extends BaseResource {
  async list(params) {
    const res = await this.http.get('v1/histories', { params });

    return Promise.resolve(res.data.data);
  }

  async adHistory(params) {
    const res = await this.http.get('v1/histories/adcenter', { params });

    return Promise.resolve(res.data.data);
  }
}
