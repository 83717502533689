import AWSService from '@/services/aws';

const state = {
  uploadedFile: '',
  isUploadingFile: false,
};

const getters = {
  uploadedFile: (state) => state.uploadedFile,
  isUploadingFile: (state) => state.isUploadingFile,
};

const mutations = {
  setUploadedFile: (state, uploadedFile) => {
    state.uploadedFile = uploadedFile;
  },

  setIsUploadingFile: (state, isUploadingFile) => {
    state.isUploadingFile = isUploadingFile;
  },
};

const actions = {
  uploadFile: async ({ commit, rootGetters }, payload) => {
    commit('setIsUploadingFile', true);
    const token = rootGetters['auth/token'];

    const aws = new AWSService(token.jwt());
    const uploadedFile = await aws.s3.uploadFile(payload.file, payload.bucketname);

    commit('setUploadedFile', uploadedFile);
    commit('setIsUploadingFile', false);
  },
  uploadFileReturn: async ({ rootGetters }, payload) => {
    const token = rootGetters['auth/token'];
    const aws = new AWSService(token.jwt());

    return aws.s3.uploadFile(payload.file, payload.bucketname);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
