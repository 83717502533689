const sessionUsage = async ({ rootGetters }, config) => {
  const skyos = rootGetters['app/skyOSService'];
  const response = await skyos.metrics.sessionsCount(config);

  return Promise.resolve(response);
};

export default {
  sessionUsage,
};
