/* eslint-disable no-restricted-syntax,no-await-in-loop */
import _ from "lodash";
import { extractIds } from "@/utils/helpers";

const compareFeatures = (feature1, feature2) =>
  feature1.feature === feature2.feature &&
  feature1.id === feature2.id &&
  feature1.order_rank === feature2.order_rank &&
  feature1.parent_id === feature2.parent_id &&
  _.isEqual(feature1.settings, feature2.settings);

const state = {
  allFeatures: [],
  screenFeatures: [],
};

const getters = {
  all: (state) => state.allFeatures,
  screen: (state) => state.screenFeatures,
};

const mutations = {
  setAllFeatures: (state, allFeatures) => {
    state.allFeatures = allFeatures;
  },

  setScreenFeatures: (state, screenFeatures) => {
    state.screenFeatures = screenFeatures;
  },
};

const actions = {
  create: async ({ dispatch, rootGetters }, newFeature) => {
    const skyos = rootGetters["app/skyOSService"];
    const activeVenueId = rootGetters["content/venues/activeVenueId"];

    const response = await skyos.features.create(activeVenueId, newFeature);

    await dispatch("app/previewer/refreshContent", null, { root: true });

    return Promise.resolve(response);
  },

  delete: async ({ dispatch, rootGetters }, feature) => {
    const skyos = rootGetters["app/skyOSService"];

    await skyos.features.delete(feature);
    await dispatch("app/previewer/refreshContent", null, { root: true });
  },

  update: async ({ dispatch }) => {
    // TODO update this
    await dispatch("app/previewer/refreshContent", null, { root: true });
  },

  batchCreate: async ({ rootGetters }, features) => {
    const skyos = rootGetters["app/skyOSService"];

    // Create root level features
    // eslint-disable-next-line no-async-promise-executor
    const rootFeaturePromises = features.map(
      (feature) =>
        new Promise(async (resolve) => {
          let parentId = 0;
          if (feature.parent_id) {
            parentId = feature.parent_id;
          }
          // eslint-disable-next-line prefer-object-spread
          const payload = _.merge({}, feature, { parent_id: parentId });
          const createdFeature = await skyos.features.create(payload);

          createdFeature.tempId = feature.tempId;

          return resolve(createdFeature);
        })
    );

    const rootFeatures = await Promise.all(rootFeaturePromises);

    return Promise.resolve(rootFeatures);
  },

  batchDelete: async ({ dispatch }, features) => {
    const promises = features.map((feature) => dispatch("delete", feature));

    return Promise.all(promises);
  },

  batchUpdate: async ({ rootGetters }, features) => {
    const skyos = rootGetters["app/skyOSService"];

    // eslint-disable-next-line no-async-promise-executor
    const promises = features.map(
      (feature) =>
        new Promise(async (resolve) => {
          const createdFeature = await skyos.features.update(feature);

          createdFeature.tempId = feature.tempId;

          return resolve(createdFeature);
        })
    );

    const results = await Promise.all(promises);

    return Promise.resolve(results);
  },

  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters["app/skyOSService"];
    const activeVenueId = rootGetters["content/venues/activeVenueId"];

    const features = await skyos.features.fetchByVenueId(activeVenueId);

    commit("setAllFeatures", features);
  },

  fetchByScreenId: async ({ commit, rootGetters }, screenId) => {
    const skyos = rootGetters["app/skyOSService"];

    const features = await skyos.features.fetchByScreenId(screenId);

    commit("setScreenFeatures", features);
  },

  saveState: async ({ dispatch, rootGetters }, data) => {
    const skyos = rootGetters["app/skyOSService"];
    let originalState = [];
    if (data.mode === "screen") {
      let screenId = 0;
      if (data.newFeaturesState.length === 0) {
        screenId = data.screen_id;
      } else {
        screenId = data.newFeaturesState[0].screen_id;
      }
      originalState = await skyos.features.fetchByScreenId(screenId);
    } else {
      let venueId = 0;
      if (data.newFeaturesState.length === 0) {
        venueId = this.$store.getters["content/venues/activeVenueId"];
      } else {
        venueId = data.newFeaturesState[0].venue_id;
      }
      originalState = await skyos.features.fetchByVenueId(venueId);
    }

    const existingFeatureIds = extractIds(originalState);
    const newFeaturesStateIds = extractIds(data.newFeaturesState);

    const existingFeatures = data.newFeaturesState.filter((feature) =>
      existingFeatureIds.includes(feature.id)
    );
    const featuresToCreate = data.newFeaturesState.filter(
      (feature) => !existingFeatureIds.includes(feature.id)
    );
    const featuresToDelete = originalState.filter(
      (feature) => !newFeaturesStateIds.includes(feature.id)
    );

    const featuresToUpdate = [];

    existingFeatures.forEach((existingFeature) => {
      const matchingStoreFeature = originalState.find(
        (feature) => feature.id === existingFeature.id
      );

      if (!compareFeatures(existingFeature, matchingStoreFeature)) {
        featuresToUpdate.push(existingFeature);
      }
    });

    const promises = [];
    if (featuresToUpdate && featuresToUpdate.length > 0) {
      promises.push(dispatch("batchUpdate", featuresToUpdate));
    }
    if (featuresToCreate && featuresToCreate.length > 0) {
      promises.push(dispatch("batchCreate", featuresToCreate));
    }
    if (featuresToDelete && featuresToDelete.length > 0) {
      promises.push(dispatch("batchDelete", featuresToDelete));
    }

    await Promise.all(promises);

    await dispatch("fetchAll");
    await dispatch("app/previewer/refreshContent", null, { root: true });
    return Promise.resolve();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
