import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class EventsResource extends BaseResource {
  async create(newEvent) {
    const res = await this.http.post('v1/events', newEvent);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/events/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/events/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/events');

    return Promise.resolve(res.data.data);
  }

  async update(updatedEvent) {
    if (!updatedEvent || !updatedEvent.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update event payload'));
    }
    const endpoint = `v1/events/${updatedEvent.id}`;
    const res = await this.http.put(endpoint, updatedEvent);

    return Promise.resolve(res.data.data);
  }

  async upload(formData) {
    return this.http.post('v1/uploads', formData);
  }
}
