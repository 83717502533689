import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class CategoriesResource extends BaseResource {
  async create(newCategory) {
    const res = await this.http.post('v1/categories', newCategory);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/categories/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/categories/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(params) {
    const res = await this.http.get('v1/categories', {
      params,
    });
    return Promise.resolve(res.data.data);
  }

  async update(updatedCategory) {
    if (!updatedCategory || !updatedCategory.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update category payload'));
    }
    const endpoint = `v1/categories/${updatedCategory.id}`;
    const res = await this.http.put(endpoint, updatedCategory);

    return Promise.resolve(res.data.data);
  }
}
