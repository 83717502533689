import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import alertsModule from './modules/alerts';
import amenitiesModule from './modules/amenities';
import categoriesModule from './modules/categories';
import eventsModule from './modules/events';
import featuresModule from './modules/features';
import filesModule from './modules/files';
import kiosksModule from './modules/kiosks';
import locationsModule from './modules/locations';
import overlaysModule from './modules/overlays';
import screensModule from './modules/screens';
import templatesModule from './modules/templates';
import venuesModule from './modules/venues';
import uploadsModule from './modules/uploads';
import permissionsModule from './modules/permissions';
import languagesModule from './modules/languages';
import promotionsModule from './modules/promotions';
import selfiesModule from './modules/selfies';
import feedsModule from './modules/feeds';
import mediaModules from './modules/media';
import synonymModule from './modules/synonyms';

const state = {
  applicationIsLoading: true,
  show: false,
  maxWidth: '40vw',
  prompt: 'Are you sure you want to delete this item?',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  cancelCallback: () => {},
  confirmCallback: () => {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    alerts: alertsModule,
    amenities: amenitiesModule,
    categories: categoriesModule,
    events: eventsModule,
    features: featuresModule,
    files: filesModule,
    kiosks: kiosksModule,
    locations: locationsModule,
    overlays: overlaysModule,
    screens: screensModule,
    templates: templatesModule,
    venues: venuesModule,
    uploads: uploadsModule,
    permissions: permissionsModule,
    languages: languagesModule,
    promotions: promotionsModule,
    selfies: selfiesModule,
    feeds: feedsModule,
    media: mediaModules,
    synonyms: synonymModule,
  },
};
