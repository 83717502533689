import Vue from 'vue';
import setupSentry from './utils/sentry';
import App from './App.vue';

import store from './store';
import router from './router';

// PLUGINS
import vuetify from './plugins/vuetify';
// eslint-disable-next-line import/no-named-as-default
import i18n from './plugins/vue-i18n';
import './plugins/vue-shortkey';
import './plugins/vue-head';
import './plugins/vue-gtag';
import './plugins/apexcharts';
import './plugins/echarts';
import './plugins/animate';
import './plugins/clipboard';
import './plugins/moment';
import './plugins/vue-logger';
import './plugins/vue-nestable';
import './plugins/rules';
import './plugins/analytics';
import './plugins/deleteConfirm';

// FILTERS
import './filters/capitalize';
import './filters/lowercase';
import './filters/uppercase';
import './filters/formatCurrency';
import './filters/formatDate';

// import 'vuetify/dist/vuetify.min.css'
// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss';

// Animation library - https://animate.style/
import 'animate.css/animate.min.css';

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

// Set up sentry and pii data scrubbing
// setupSentry(router);

if (import.meta.env.NODE_ENV === 'production') {
  setupSentry(router);
}

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
