import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class CampaignsResource extends BaseResource {
  async create(newCampaign) {
    const res = await this.http.post('v1/campaigns', newCampaign);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/campaigns/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/campaigns/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/campaigns');

    return Promise.resolve(res.data.data);
  }

  async update(updatedCampaign) {
    if (typeof updatedCampaign.order_rank === 'string') {
      updatedCampaign.order_rank = parseInt(updatedCampaign.order_rank, 10);
    }
    if (!updatedCampaign || !updatedCampaign.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update campaign payload'));
    }
    const endpoint = `v1/campaigns/${updatedCampaign.id}`;
    const res = await this.http.put(endpoint, updatedCampaign);

    return Promise.resolve(res.data.data);
  }

  async updateAdRanks(campaignId, adRanks) {
    const endpoint = `v2/campaigns/${campaignId}/adOrderRank`;
    const res = await this.http.post(endpoint, adRanks);
    return Promise.resolve(res.data.data);
  }
}
