const updateApplicationIsLoading = ({ commit }, isLoading) => {
  commit('setApplicationIsLoading', isLoading);

  return Promise.resolve();
};

const fetchAllUserContent = async ({ dispatch }) => {
  const promises = [
    dispatch('content/venues/fetchAll', undefined, { root: true }),
    dispatch('content/kiosks/fetchAll', undefined, { root: true }),
    dispatch('content/screens/fetchAll', undefined, { root: true }),
  ];

  await Promise.all(promises);

  return Promise.resolve();
};

const updateActiveContent = async ({
  dispatch,
  rootGetters
}) => {
  const venueId = rootGetters['content/venues/activeVenueId'];

  const promises = [
    dispatch('content/kiosks/updateActive', undefined, { root: true }),
    dispatch('content/screens/updateActive', undefined, { root: true }),
    dispatch('content/locations/fetchVenue', venueId, { root: true }),
  ];

  await Promise.all(promises);

  return Promise.resolve();
};

const openDeleteConfirmDialog = async ({ commit }, deleteConfirmConfig) => {
  commit('setDeleteConfirmConfig', {
    show: true,
    ...deleteConfirmConfig,
  });
};

const closeDeleteConfirmDialog = async ({ commit }) => {
  commit('setDeleteConfirmConfig', {
    show: false,
  });
};

export default {
  updateApplicationIsLoading,
  fetchAllUserContent,
  updateActiveContent,
  openDeleteConfirmDialog,
  closeDeleteConfirmDialog,
};
