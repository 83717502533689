export default {
  // global theme for the app
  globalTheme: 'light', // light | dark

  // side menu theme, use global theme or custom
  menuTheme: 'dark', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: 'global', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    background: '#112236',
    surface: '#111b27',
    primary: '#00AEEF',
    secondary: '#F7D851',
    accent: '#036C95',
    error: '#F02A45',
    info: '#2196F3',
    success: '#1FE074',
    warning: '#FFC107',
  },

  // light theme colors
  light: {
    background: '#ffffff',
    surface: '#FAF8F2',
    primary: '#00AEEF',
    secondary: '#F7D851',
    accent: '#036C95',
    error: '#F02A45',
    info: '#2196F3',
    success: '#1FE074',
    warning: '#ffd166',
  },

  // dark theme colors
  oldDark: {
    background: '#112236',
    surface: '#111b27',
    primary: '#3376db',
    secondary: '#829099',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },

  // light theme colors
  oldLight: {
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#3376db',
    secondary: '#a0b9c8',
    accent: '#048ba8',
    error: '#ef476f',
    info: '#2196F3',
    success: '#06d6a0',
    warning: '#ffd166',
  }
};
