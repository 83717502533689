export default [
  {
    path: '/utility/maintenance',
    name: 'utility-maintenance',
    component: () => import( '@/pages/utility/MaintenancePage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/utility/coming-soon',
    name: 'utility-soon',
    component: () => import( '@/pages/utility/SoonPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/utility/help',
    name: 'utility-help',
    component: () => import( '@/pages/utility/HelpPage.vue'),
  },
];
