import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class LanguagesResource extends BaseResource {
  async create(newLanguage) {
    const res = await this.http.post('v1/languages', newLanguage);

    return Promise.resolve(res.data.data);
  }

  async delete(languageId) {
    await this.http.delete(`v1/languages/${languageId}`);

    return Promise.resolve();
  }

  async fetchById(id) {
    const endpoint = `v1/languages/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/languages');

    return Promise.resolve(res.data.data);
  }

  async getTranslations(searchCriteria) {
    const res = await this.http.post('v1/translations/search', searchCriteria);

    return Promise.resolve(res.data.data);
  }

  async putTranslation(translation) {
    if (!translation || !translation.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update translation payload'));
    }
    const endpoint = `v1/translations/${translation.id}`;
    const res = await this.http.put(endpoint, translation);

    return Promise.resolve(res.data.data);
  }

  async createTranslation(translation) {
    if (!translation) {
      return Promise.reject(new ValidationError('missing required field "translation" in create translation payload'));
    }
    const endpoint = 'v1/translations';
    const res = await this.http.post(endpoint, translation);

    return Promise.resolve(res.data.data);
  }
}
