import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  isAuthenticated: false,
  token: undefined,
  user: undefined,
  mimicEnabled: false,
  mimicToken: undefined,
  mimicUser: undefined,
  mfaEmail: '',
  clientId: '',
  rememberDays: 30,
  userPermissions: undefined,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
