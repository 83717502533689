import BaseResource from './base';
import {NotFoundError, ValidationError} from '../../utils/errors';

export default class BackgroundsResource extends BaseResource {
  async create(newBackground, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/map_backgrounds`, newBackground);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/map_backgrounds/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(backgroundId, venueId) {
    const endpoint = `v2/venues/${venueId}/map_backgrounds/${backgroundId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/map_backgrounds`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedBackground, venueId) {
    if (!updatedBackground || !updatedBackground.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update background payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/map_backgrounds/${updatedBackground.id}`;
    const res = await this.http.put(endpoint, updatedBackground);

    return Promise.resolve(res.data.data);
  }
}
