<template>
  <v-menu
    offset-y
    left
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        class="elevation-2"
        v-on="on"
      >
        <v-badge
          :content="notifications"
          :value="notifications"
          color="success"
          dot
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-avatar color="primary" size="40">
            <v-img v-if="userProfileImage" :src="userProfileImage" />
            <span v-else-if="userInitials" class="white--text text-h6">{{ userInitials }}</span>
            <v-icon v-else dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="item.link"
        :exact="item.exact"
        :disabled="item.disabled"
        link
      >
        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.key ? $t(item.key) : item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item @click.prevent="logout">
        <v-list-item-icon>
          <v-icon small>
            mdi-logout-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../configs';

export default {
  data() {
    return {
      menu: config.toolbar.user,
      // Placeholder for notification badge
      notifications: 0,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    userProfileImage() {
      return this.user.profile_img;
    },

    userInitials() {
      const firstInitial = this.user.first_name.charAt(0).toUpperCase();
      const lastInitial = this.user.last_name.charAt(0).toUpperCase();

      return `${firstInitial}${lastInitial}`;
    },
  },

  methods: {
    logout() {
      localStorage.clear();
      // this will cause state to clear
      this.$router.go();
    },
  },
};
</script>
