import BaseResource from './base';

export default class FeaturesResource extends BaseResource {
  async create(newFeature) {
    let endpoint = '';
    if (newFeature && newFeature.screen_id > 0) {
      endpoint = `v1/screens/${newFeature.screen_id}/features`;
    } else {
      endpoint = `v1/venues/${newFeature.venue_id}/features`;
    }
    const res = await this.http.post(endpoint, newFeature);

    return Promise.resolve(res.data.data);
  }

  async delete(feature) {
    let endpoint = '';
    if (feature.screen_id && feature.screen_id > 0) {
      endpoint = `v1/screens/${feature.screen_id}/features/${feature.id}`;
    } else {
      endpoint = `v1/venues/${feature.venue_id}/features/${feature.id}`;
    }
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchByVenueId(venueId) {
    const endpoint = `v1/venues/${venueId}/features`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchByScreenId(screenId) {
    const endpoint = `v1/screens/${screenId}/features`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async update(updatedFeature) {
    if (!updatedFeature.id) {
      return Promise.reject(new Error('missing required feature id'));
    }
    let endpoint = '';
    if (updatedFeature && updatedFeature.screen_id > 0) {
      endpoint = `v1/screens/${updatedFeature.screen_id}/features/${updatedFeature.id}`;
    } else {
      endpoint = `v1/venues/${updatedFeature.venue_id}/features/${updatedFeature.id}`;
    }
    const res = await this.http.put(endpoint, updatedFeature);

    return Promise.resolve(res.data.data);
  }
}
