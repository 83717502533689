import SkyOSService from '../../services/skyos';

const fetchUsers = async ({ commit, rootGetters }) => {
  const token = rootGetters['auth/token'];

  const skyos = new SkyOSService(token);
  const users = await skyos.users.list();

  commit('setUsers', users);
};

const inviteUser = async ({ dispatch, rootGetters }, payload) => {
  const token = rootGetters['auth/token'];

  const skyos = new SkyOSService(token);

  payload.return_url = `${window.location.origin}/auth/register`;
  try {
    await skyos.users.invite(payload);

    // Re-fetch users since we added a new one
    dispatch('app/showSuccess', 'Invite sent!', { root: true });
    await dispatch('fetchUsers');
    return Promise.resolve(true);
  } catch (e) {
    console.error(e);
    dispatch('app/showError', {
      message: 'Invite Failed.',
      error: e,
    }, { root: true });
    return Promise.resolve(false);
  }
};

const fetchHistory = async ({ rootGetters }, payload) => {
  try {
    const skyos = rootGetters['app/skyOSService'];
    const results = await skyos.history.list(payload);

    return Promise.resolve(results);
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteUser = async ({ rootGetters }, userId) => {
  try {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.users.delete(userId);

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateUser = async ({ rootGetters }, user) => {
  try {
    const skyos = rootGetters['app/skyOSService'];
    await skyos.users.update(user);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export default {
  fetchUsers,
  inviteUser,
  fetchHistory,
  deleteUser,
  updateUser,
};
