import BaseResource from './base';

export default class QueuesResource extends BaseResource {
  async fetchById(id) {
    const endpoint = `v1/queues/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }
}
