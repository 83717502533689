const state = {
  synonyms: [],
};

const getters = {
  synonyms: (state) => state.synonyms,
};

const mutations = {
  setSynonyms: (state, synonyms) => {
    state.synonyms = synonyms;
  },
};

const actions = {
  fetchSynonyms: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const synonyms = await skyos.synonyms.list(venueId);

    commit('setSynonyms', synonyms);
  },

  create: async ({ rootGetters }, newSynonym) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.synonyms.create(newSynonym);
  },

  edit: async ({ rootGetters }, editedSynonym) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.synonyms.update(editedSynonym, venueId);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.synonyms.delete(id, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
