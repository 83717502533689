import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import adSlots from './modules/adslots';
import proxyServer from './modules/proxyServer';

const state = {
  users: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    adSlots,
    proxyServer,
  },
};
