const fetchApiKeys = async ({ commit, rootGetters }) => {
  try {
    const skyos = rootGetters['app/skyOSService'];
    const apiKeys = await skyos.apiKeys.list();

    commit('setApiKeys', apiKeys);

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
const createApiKey = async ({ rootGetters }) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.apiKeys.create();
};
const deleteApiKey = async ({ rootGetters }, payload) => {
  const skyos = rootGetters['app/skyOSService'];

  return skyos.apiKeys.delete(payload);
};
const fetchOrganizations = async ({ commit, rootGetters }) => {
  const skyos = rootGetters['app/skyOSService'];
  const organizations = await skyos.organizations.list();

  commit('setOrganizations', organizations);
};
const updateActiveOrganization = async ({ commit, state }, organizationId) => {
  const activeOrganizationId = parseInt(organizationId, 10);
  const found = state.organizations.find((org) => org.id === activeOrganizationId);
  if (found) {
    commit('setActiveOrganization', found);
  } else {
    return Promise.reject(new Error('unable to update active organization'));
  }
  return null;
};
const createOrganization = async ({ rootGetters }, newOrganization) => {
  const skyos = rootGetters['app/skyOSService'];

  await skyos.organizations.create(newOrganization);
};

const editOrganization = async ({ rootGetters }, editedOrganization) => {
  const skyos = rootGetters['app/skyOSService'];
  await skyos.organizations.update(editedOrganization);
};

const deleteOrganization = async ({ rootGetters }, id) => {
  const skyos = rootGetters['app/skyOSService'];
  await skyos.organizations.delete(id);
};

const createMember = async ({ rootGetters }, newMember) => {
  const skyos = rootGetters['app/skyOSService'];

  await skyos.members.create(newMember);
};

const fetchMembers = async ({ commit, rootGetters }, organizationId) => {
  const skyos = rootGetters['app/skyOSService'];
  const members = await skyos.members.list(organizationId);

  commit('setMembers', members);
};

const editMember = async ({ rootGetters }, editedMember) => {
  const skyos = rootGetters['app/skyOSService'];
  await skyos.members.update(editedMember.organization_id, editedMember);
};

const deleteMember = async ({ rootGetters }, member) => {
  const skyos = rootGetters['app/skyOSService'];
  await skyos.members.delete(member.organization_id, member.id);
};

export default {
  fetchApiKeys,
  createApiKey,
  deleteApiKey,
  fetchOrganizations,
  updateActiveOrganization,
  createOrganization,
  editOrganization,
  deleteOrganization,
  createMember,
  fetchMembers,
  editMember,
  deleteMember,
};
