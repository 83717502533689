export default [
  {
    path: '/auth/signin',
    name: 'auth-signin',
    component: () => import( '@/pages/auth/SigninPage.vue'),
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/auth/verify-email',
    name: 'auth-verify-email',
    component: () => import( '@/pages/auth/VerifyEmailPage.vue'),
    meta: {
      layout: 'auth',
      requiresAuth: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot',
    component: () => import( '@/pages/auth/ForgotPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset',
    component: () => import( '@/pages/auth/ResetPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/update-password',
    name: 'auth-update',
    component: () => import(/* webpackChunkName: "auth-update" */ '@/pages/auth/PasswordUpdate'),
    meta: {
      layout: 'auth',
      requiresAuth: true,
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import( '@/pages/auth/SignupPage.vue'),
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/auth/mfa',
    name: 'auth-mfa',
    component: () => import( '@/pages/auth/Mfa.vue'),
    meta: {
      layout: 'auth',
    },
  },
];
