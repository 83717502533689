
<div class="d-flex text-center flex-column flex-md-row flex-grow-1">
  <v-sheet
    id="auth-side-panel"
    class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2 ml-0"
  >
    <div class="mt-3 mt-md-10 pa-2">
      <div class="display-2 font-weight-bold primary--text">
        <img class="logo" src="/images/logos/roveiq-transparent-white-tag.svg">
      </div>
      <div class="title my-2">

      </div>
      <v-btn to="/" class="my-4"
             id="auth-takemeback-btn"
      >
        Take me back
      </v-btn>
    </div>
  </v-sheet>

  <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
    <div
      class="ma-auto w-full"
      :class="{'layout-content': $route.path !== '/tos' && $route.path !== '/privacy'}"
    >
      <slot/>
    </div>
    <div class="overline mt-4">
      {{ product.name }} - {{ product.version }} |
      <router-link to="/tos">
        Terms of Use
      </router-link>
      |
      <router-link to="/privacy">
        Privacy Policy
      </router-link>
    </div>
  </div>
</div>
