const state = {
  suites: [],
};

const getters = {
  suites: (state) => state.suites,
};

const mutations = {
  setSuites(state, suites) {
    state.suites = suites;
  },
};

const actions = {
  fetchSuites: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const suites = await skyos.suites.list(venueId);

    commit('setSuites', suites);
  },

  create: async ({ rootGetters }, newSuite) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.suites.create(newSuite, venueId);
  },

  edit: async ({ rootGetters }, editedSuite) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.suites.update(editedSuite, venueId);
  },

  delete: async ({ rootGetters }, suiteId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.suites.delete(suiteId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
