const state = {
  lights: [],
};

const getters = {
  lights: (state) => state.lights,
};

const mutations = {
  setLights(state, lights) {
    state.lights = lights;
  },
};

const actions = {
  fetchLights: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const lights = await skyos.lights.list(venueId);

    commit('setLights', lights);
  },

  create: async ({ rootGetters }, newLight) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.lights.create(newLight, venueId);
  },

  edit: async ({ rootGetters }, editedLight) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.lights.update(editedLight, venueId);
  },

  delete: async ({ rootGetters }, lightId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.lights.delete(lightId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
