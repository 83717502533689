const state = {
  allLanguages: [],
  venueLanguages: [],
  venueLanguagesId: 0,
};

const getters = {
  all: (state) => state.allLanguages,
  venueLanguages: (state) => state.venueLanguages,
  venueId: (state) => state.venueLanguagesId,
};

const mutations = {
  setAll: (state, languages) => {
    state.allLanguages = languages;
  },
  setVenue: (state, languages) => {
    state.venueLanguages = languages;
  },
  setVenueId: (state, id) => {
    state.venueLanguagesId = id;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const languages = await skyos.languages.list();

    commit('setAll', languages);
  },

  create: async ({ rootGetters }, newLanguage) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.languages.create(newLanguage);
  },

  updateVenueLanguages: async ({
    rootGetters, commit, getters, dispatch,
  }) => {
    const skyos = rootGetters['app/skyOSService'];
    const languages = getters.venueLanguages;
    const currentVenueId = getters.venueId;
    const venueId = rootGetters['content/venues/activeVenueId'];

    if (venueId === currentVenueId) {
      return getters.venue;
    }

    if (!languages) {
      await dispatch('fetchAll');
    }

    const venueLangs = await skyos.venues.getLanguages(venueId);

    commit('setVenue', venueLangs);
    commit('setVenueId', venueId);

    return venueLangs;
  },

  getTranslations: async ({ rootGetters }, modelInfo) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const searchCriteria = {
      model_table: modelInfo.table,
      model_attribute: modelInfo.attribute,
      model_id: modelInfo.id,
      venue_id: venueId,
    };

    return skyos.languages.getTranslations(searchCriteria);
  },

  updateTranslation: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const { id, translation } = data;

    translation.model_id = id;
    translation.venue_id = venueId;
    delete translation.language;

    if (translation.modified) {
      if (translation.id > 0) {
        return skyos.languages.putTranslation(translation);
      }

      return skyos.languages.createTranslation(translation);
    }
    return Promise.resolve();
  },

  delete: async ({ rootGetters }, languageId) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.languages.delete(languageId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
