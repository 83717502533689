import BaseResource from './base';
import { ValidationError } from '../../utils/errors';

export default class SchedulesResource extends BaseResource {
  async available(config) {
    const endpoint = 'v2/schedules/available';
    const res = await this.http.post(endpoint, config);

    return Promise.resolve(res.data.data);
  }

  async create(payload) {
    const endpoint = 'v2/schedules';
    const res = await this.http.post(endpoint, payload);

    return Promise.resolve(res.data.data);
  }

  async delete(id) {
    const endpoint = `v1/schedules/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(id) {
    const endpoint = `v1/schedules/${id}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list() {
    const res = await this.http.get('v1/schedules');

    return Promise.resolve(res.data.data);
  }

  async active(incomingParams) {
    const params = incomingParams || {}
    const res = await this.http.get('v1/schedules/active', {
      params
    });

    return Promise.resolve(res.data.data);
  }

  async update(updatedSchedule) {
    if (!updatedSchedule || !updatedSchedule.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update schedule payload'));
    }
    const endpoint = `v1/schedules/${updatedSchedule.id}`;
    const res = await this.http.put(endpoint, updatedSchedule);

    return Promise.resolve(res.data.data);
  }
}
