import Vue from 'vue';
import Router from 'vue-router';
import AuthToken from '../services/skyos/token';
import store from '../store';

// Routes
import PagesRoutes from './pages.routes';
import UsersRoutes from './users.routes';

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }

    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(Router);

export const routes = [{
  path: '/',
  redirect: '/dashboard/analytics',
}, {
  path: '/dashboard/analytics',
  name: 'dashboard-analytics',
  component: () => import( '@/pages/dashboard/DashboardPage.vue'),
  meta: {
    requiresAuth: true,
    showVenueSelector: true,
  },
},
...PagesRoutes,
...UsersRoutes,
{
  path: '/blank',
  name: 'blank',
  component: () => import( '@/pages/BlankPage.vue'),
},
{
  path: '*',
  name: 'error',
  component: () => import( '@/pages/error/NotFoundPage.vue'),
  meta: {
    layout: 'error',
  },
}];

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    if (to.path.includes(from.path) || from.path.includes(to.path)) {
      return false; 
    }

    return { x: 0, y: 0 };
  },
  routes,
});

/**
 * Before each route update
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // This route requires auth, check localstorage for token
    const jwtToken = localStorage.getItem('token');
    const token = new AuthToken(jwtToken);
    if (!token || token.isExpired() || !token.getUserId()) {
      await store.dispatch('content/venues/updateIsChangingVenues', false);
      await store.dispatch('auth/logout');
      next({
        path: '/auth/signin',
        query: { nextUrl: to.fullPath },
      });
    } if (to.matched.some((record) => record.meta.guest)) {
      // This is a guest only route
      if (!localStorage.getItem('token')) {
        next();
      }

      next({ name: 'auth-signin' });
    }
  }
  next();
});

export default router;
