import _, { isString } from 'lodash';

export const extractIds = (arr, idKey = 'id') => arr.map((item) => item[idKey]);

export const flattenNestedObjectArray = (arr, keyContainingChildren) => {
  const flattened = [];

  arr.forEach((item) => {
    flattened.push(item);
    if (item[keyContainingChildren] && item[keyContainingChildren].length > 0) {
      item[keyContainingChildren].forEach((childItem) => {
        flattened.push(childItem);
      });
    }
  });

  return flattened;
};

export const getURLQueryParameterByName = (name, url) => {
  // eslint-disable-next-line no-param-reassign
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&') // eslint-disable-line
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function deepDiff(fromObject, toObject) {
  const changes = {};

  const buildPath = (path, obj, key) => (_.isUndefined(path) ? key : `${path}.${key}`);

  const walk = (fromObject, toObject, path) => {
    for (const key of _.keys(fromObject)) {
      const currentPath = buildPath(path, fromObject, key);
      if (!_.has(toObject, key)) {
        changes[currentPath] = { from: _.get(fromObject, key) };
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_.has(fromObject, key)) {
        changes[currentPath] = { to };
      } else {
        const from = _.get(fromObject, key);
        if (!_.isEqual(from, to)) {
          if (_.isObjectLike(to) && _.isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes[currentPath] = { from, to };
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return changes;
}

export const cleanPins = (customPins) => {
  const cleanCustomPins = {};
  Object.keys(customPins).forEach((key) => {
    const file = customPins[key];
    if (file && isString(file) && file.length > 0) {
      cleanCustomPins[`${key}`] = `${file}`;
    }
  });
  return _.cloneDeep(cleanCustomPins);
};
