import jwtDecode from 'jwt-decode';

export default class AuthToken {
  constructor(jwtToken) {
    this.jwtToken = jwtToken;
    if (jwtToken) {
      this.decodedToken = jwtDecode(jwtToken);
    } else {
      this.decodedToken = null;
    }
  }

  jwt() {
    return this.jwtToken;
  }

  isExpired() {
    if (this.decodedToken) {
      let isExpired = true;

      if (this.decodedToken && this.decodedToken.exp) {
        if (this.decodedToken.exp && Date.now() < this.decodedToken.exp * 1000) {
          isExpired = false;
        }

        return isExpired;
      }
    }

    return true;
  }

  getUserId() {
    if (this.decodedToken) {
      return this.decodedToken.user_id;
    }

    return undefined;
  }
}
