<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      id="auth-side-panel"
      class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2 ml-0"
    >
      <div class="mt-3 mt-md-10 pa-2">
        <div class="display-2 font-weight-bold primary--text">
          <img class="logo" src="/images/logos/roveiq-transparent-white-tag.svg">
        </div>
        <div class="title my-2">

        </div>
        <v-btn to="/" class="my-4"
               id="auth-takemeback-btn"
        >
          Take me back
        </v-btn>
      </div>
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div
        class="ma-auto w-full"
        :class="{'layout-content': $route.path !== '/tos' && $route.path !== '/privacy'}"
      >
        <slot/>
      </div>
      <div class="overline mt-4">
        {{ product.name }} - {{ product.version }} |
        <router-link to="/tos">
          Terms of Use
        </router-link>
        |
        <router-link to="/privacy">
          Privacy Policy
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AuthToken from '../services/skyos/token';

export default {
  computed: {
    ...mapState('app', ['product']),
  },
  async created() {
    const jwtToken = localStorage.getItem('token');
    const token = new AuthToken(jwtToken);
    if (token.isExpired() || !token.getUserId()) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    if (token && token.getUserId()) {
      const skyos = this.$store.getters['app/skyOSService'];
      const user = await skyos.users.getById(token.getUserId());
      if (user && !user.password_reset_required) {
        this.$router.push('/dashboard/analytics');
      }
    }
  },
};
</script>

<style scoped>
#auth-takemeback-btn {
  background-color: var(--v-secondary-base) !important;
  color: var(--v-accent-base) !important
}

#auth-side-panel {
  margin-left: -1px !important;
  background: url('/images/backgrounds/auth-layout-side-panel.png');
  background-size: cover;
}

.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}

.logo {
  max-width: 300px;
}
</style>
