const state = {
  styles: [],
};

const getters = {
  styles: (state) => state.styles,
};

const mutations = {
  setStyles: (state, styles) => {
    state.styles = styles;
  },
};

const actions = {
  fetchStyles: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const styles = await skyos.styles.list(venueId);

    commit('setStyles', styles);
  },

  create: async ({ rootGetters }, newStyle) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.styles.create(newStyle);
  },

  edit: async ({ rootGetters }, editedStyle) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.styles.update(editedStyle, venueId);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.styles.delete(id, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
