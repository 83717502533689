import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import * as directives from 'vuetify/lib/directives';
// eslint-disable-next-line import/no-named-as-default
import i18n from './vue-i18n';
import config from '../configs';

Vue.use(Vuetify, {
  directives,
});

export default new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light,
    },
  },
  lang: {
    current: config.locales.locale,
    // To use Vuetify own translations without Vue-i18n comment the next line
    t: (key, ...params) => i18n.t(key, params),
  },
});
