const state = {
  allAlerts: [],
  activeAlerts: [],
};

const getters = {
  all: (state) => state.allAlerts,
  active: (state) => state.activeAlerts,
};

const mutations = {
  setAll: (state, alerts) => {
    state.allAlerts = alerts;
  },

  setActive: (state, activeAlerts) => {
    state.activeAlerts = activeAlerts;
  },
};

const actions = {
  fetchAll: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const alerts = await skyos.alerts.list();

    commit('setAll', alerts);
  },

  updateActive: ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    const filtered = state.allAlerts.filter((alert) => alert.venue_id === venueId);

    commit('setActive', filtered);
  },

  create: async ({ dispatch, rootGetters }, newAlert) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.alerts.create(newAlert);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve(response);
  },

  edit: async ({ dispatch, rootGetters }, editedAlert) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.alerts.update(editedAlert);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.alerts.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
