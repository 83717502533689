const state = {
  allLocations: [],
  activeLocations: [],
  types: [],
};

const getters = {
  all: (state) => state.allLocations,
  active: (state) => state.activeLocations,
  types: (state) => state.types,
};

const mutations = {
  setAll: (state, locations) => {
    state.allLocations = locations;
  },

  setActive: (state, activeLocations) => {
    state.activeLocations = activeLocations;
  },

  setTypes: (state, locationTypes) => {
    state.types = locationTypes;
  },
};

const actions = {
  fetchAll: async ({
    commit,
    dispatch,
    rootGetters
  }) => {
    const skyos = rootGetters['app/skyOSService'];
    const locations = await skyos.locations.list();

    await dispatch('content/categories/fetchLocationCategories', null, { root: true });

    commit('setAll', locations);
  },

  fetchVenue: async ({
    commit,
    dispatch,
    rootGetters
  }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];
    const skyos = rootGetters['app/skyOSService'];
    const locations = await skyos.locations.listVenue(venueId);

    await dispatch('content/categories/fetchLocationCategories', null, { root: true });

    commit('setActive', locations);
  },

  fetch: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.locations.fetchById(id);
  },

  fetchVenueV2: async ({
    commit,
    dispatch,
    rootGetters
  }, options) => {
    const venueId = rootGetters['content/venues/activeVenueId'];
    const skyos = rootGetters['app/skyOSService'];
    const locations = await skyos.locations.listVenueV2(venueId, options);

    await dispatch('content/categories/fetchLocationCategories', null, { root: true });

    commit('setActive', locations);
  },

  updateActive: ({
    commit,
    state,
    rootGetters
  }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    const filtered = state.allLocations.filter((location) => location.venue_id === venueId);

    commit('setActive', filtered);
  },

  create: async ({
    dispatch,
    rootGetters
  }, newLocation) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.locations.create(newLocation);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve(response);
  },

  edit: async ({
    dispatch,
    rootGetters
  }, editedLocation) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.locations.update(editedLocation);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({
    dispatch,
    rootGetters
  }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.locations.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  removeSuiteAssociation: async ({ dispatch, getters }, arg) => {
    const locationId = arg.locationId;
    if (!locationId) {
      return Promise.reject(new Error('missing locationId'));
    }
    const foundLoc = getters.active.find((loc) => loc.id === locationId);
    if (!foundLoc) {
      return Promise.reject(new Error(`invalid location id: ${locationId}`));
    }
    const idx = foundLoc.settings.suite_ids?.findIndex((id) => id === arg.suiteId);
    if (idx > -1) {
      foundLoc.settings.suite_ids.splice(idx, 1);
    }
    return dispatch('edit', foundLoc);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
