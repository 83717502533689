<template>
  <a
    href="#"
    :data-crabtype="widgetType"
    class="changelog-link changebubble"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  props: {
    widgetType: {
      type: String,
      default: 'full',
    },
    text: {
      type: String,
      default: 'What\'s new',
    },
  },

  beforeCreate() {
    const changeCrabScript = document.createElement('script');

    window.changecrab_config = {
      projectid: 'MAOHpY',
      selector: '.changebubble',
      type: 'full',
      emptycolor: 'rgb(208 208 208)',
      activecolor: 'rgb(232 23 71)',
    };

    changeCrabScript.setAttribute('src', 'https://changecrab.com/embed/embed.js');

    document.head.appendChild(changeCrabScript);
  },
};
</script>

<style scoped>
.changelog-link {
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
  color: #edf0f2;
}
</style>
