const state = {
  stairs: [],
};

const getters = {
  stairs: (state) => state.stairs,
};

const mutations = {
  setStairs(state, stairs) {
    state.stairs = stairs;
  },
};

const actions = {
  fetchStairs: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const stairs = await skyos.stairs.list(venueId);

    commit('setStairs', stairs);
  },

  create: async ({ rootGetters }, newStair) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.stairs.create(newStair, venueId);
  },

  edit: async ({ rootGetters }, editedStair) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.stairs.update(editedStair, venueId);
  },

  delete: async ({ rootGetters }, stairId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.stairs.delete(stairId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
