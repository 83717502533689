export default [
  {
    path: '/error/not-found',
    name: 'error-not-found',
    component: () => import( '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
  {
    path: '/error/unexpected',
    name: 'error-unexpected',
    component: () => import( '@/pages/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error',
    },
  },
];
