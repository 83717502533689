import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class StairsResource extends BaseResource {
  async create(newStair, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/floor_transitions`, newStair);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/floor_transitions/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(stairId, venueId) {
    const endpoint = `v2/venues/${venueId}/floor_transitions/${stairId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/floor_transitions`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedStair, venueId) {
    if (!updatedStair || !updatedStair.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update stair payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/floor_transitions/${updatedStair.id}`;
    const res = await this.http.put(endpoint, updatedStair);

    return Promise.resolve(res.data.data);
  }
}
