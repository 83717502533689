export default {
  applicationIsLoading: (state) => state.applicationIsLoading,
  show: (state) => state.show,
  maxWidth: (state) => state.maxWidth,
  prompt: (state) => state.prompt,
  cancelText: (state) => state.cancelText,
  confirmText: (state) => state.confirmText,
  cancelCallback: (state) => state.cancelCallback,
  confirmCallback: (state) => state.confirmCallback,
};
