const state = {
  allAmenities: [],
  activeAmenities: [],
  types: [],
};

const getters = {
  all: (state) => state.allAmenities,
  active: (state) => state.activeAmenities,
  types: (state) => state.types,
};

const mutations = {
  setAll: (state, amenities) => {
    state.allAmenities = amenities;
  },

  setActive: (state, activeAmenities) => {
    state.activeAmenities = activeAmenities;
  },

  setTypes: (state, amenityTypes) => {
    state.types = amenityTypes;
  },
};

const actions = {
  fetchAll: async ({ commit, dispatch, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];

    const amenities = await skyos.amenities.list();

    await dispatch('content/categories/fetchAmenityCategories', null, { root: true });

    commit('setAll', amenities);
  },

  fetch: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    return skyos.amenities.fetchById(id);
  },

  updateActive: ({ commit, state, rootGetters }) => {
    const venueId = rootGetters['content/venues/activeVenueId'];

    const filtered = state.allAmenities.filter((amenity) => amenity.venue_id === venueId);

    commit('setActive', filtered);
  },

  create: async ({ dispatch, rootGetters }, newAmenity) => {
    const skyos = rootGetters['app/skyOSService'];

    const response = await skyos.amenities.create(newAmenity);

    await dispatch('app/previewer/refreshContent', null, { root: true });

    return Promise.resolve(response);
  },

  edit: async ({ dispatch, rootGetters }, editedAmenity) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.amenities.update(editedAmenity);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.amenities.delete(id);
    await dispatch('app/previewer/refreshContent', null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
