const state = {};
const getters = {};
const mutations = {};
const actions = {
  fetch: async ({ rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.fetch();
  },
  create: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.create(data);
  },
  edit: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.update(data);
  },
  download: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.download(data);
  },
  createDirectory: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.createDirectory(data);
  },
  updateDirectory: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.updateDirectory(data);
  },
  getPermissions: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.getPermissions(data);
  },
  postPermission: async ({ rootGetters }, data) => {
    const skyos = rootGetters['app/skyOSService'];
    return skyos.media.createPermission(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
