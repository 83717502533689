import configs from '../../configs';
import SkyOSService from '../../services/skyos';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import previewerModule from './modules/previewer';

const {
  product, time, theme, currencies,
} = configs;

const {
  globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL,
} = theme;
const { currency, availableCurrencies } = currencies;

// state initial values
const state = {
  product,

  time,

  // currency
  currency,
  availableCurrencies,

  // themes and layout configurations
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isRTL,

  // App.vue main toast
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000,
  },

  skyOSService: new SkyOSService(undefined),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    previewer: previewerModule,
  },
};
