const state = {
  promotions: [],
};

const getters = {
  promotions: (state) => state.promotions,
};

const mutations = {
  setPromotions: (state, promotions) => {
    state.promotions = promotions;
  },
};

const actions = {
  fetchPromotions: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const promotions = await skyos.promotions.list(venueId);

    commit('setPromotions', promotions);
  },

  create: async ({ rootGetters }, newPromotion) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.promotions.create(newPromotion);
  },

  edit: async ({ rootGetters }, editedPromotion) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.promotions.update(editedPromotion, venueId);
  },

  delete: async ({ rootGetters }, id) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.promotions.delete(id, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
