import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class ProxyServersResource extends BaseResource {
  async create(newProxyServer, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/proxy_servers`, newProxyServer);
    return Promise.resolve(res.data.data);
  }

  async create_command(command, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/proxy_servers/${command.proxy_server_id}/commands`, command);
    return Promise.resolve(res.data.data);
  }

  async list_commands(proxyServerId, venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/proxy_servers/${proxyServerId}/commands`);
    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/proxy_servers/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(proxyServerId, venueId) {
    const endpoint = `v2/venues/${venueId}/proxy_servers/${proxyServerId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/proxy_servers`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedProxyServer) {
    if (!updatedProxyServer || !updatedProxyServer.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update proxyServer payload'));
    }
    if (!updatedProxyServer || !updatedProxyServer.venue_id) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venue_id" in path'));
    }
    const endpoint = `v2/venues/${updatedProxyServer.venue_id}/proxy_servers/${updatedProxyServer.id}`;
    const res = await this.http.put(endpoint, updatedProxyServer);

    return Promise.resolve(res.data.data);
  }
}
